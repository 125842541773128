import { Component, OnInit } from '@angular/core';
import { UserBasicDetails } from '../../models';
import { faCloseSVG } from '../../../../icons';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Store } from '@ngrx/store';
import { AppState } from '../../../+state/app.state';
import { ReactivateUsers, RecreateUsers } from '../../../+state/account-admin/account-admin.actions';
import { isNonNull } from '../../helpers/rxjs-type-guards';
import { selectSelectedUserManagementAccountId } from 'src/app/+state/account-admin/account-admin.selector';
import { take } from 'rxjs';

@Component({
  selector: 'app-add-deactivated-users-modal',
  templateUrl: './add-deactivated-users-modal.component.html',
  styleUrls: ['./add-deactivated-users-modal.component.scss']
})
export class AddDeactivatedUsersModalComponent implements OnInit {
  selectedAccountId$ = this.store.select(selectSelectedUserManagementAccountId);

  deactivatedUsers: UserBasicDetails[] = [];
  addedUserCount = 0;
  usersNotAdded: UserBasicDetails[] = [];
  unlockDate?: Date;


  filteredDeactivatedUserAks: number[] = [];

  message = '';
  closeIcon = faCloseSVG;

  constructor(private modalRef: BsModalRef, private store: Store<AppState>) {}

  ngOnInit() {
    this.message += `${this.addedUserCount ? "Succesfully added " + this.addedUserCount + " user(s). " : ""}There was ${this.deactivatedUsers.length} deactivated user(s).`;
    this.filteredDeactivatedUserAks = this.deactivatedUsers.map(user => user.ak).filter(isNonNull);
  }

  reactivateUsers() {
    this.selectedAccountId$.pipe(take(1)).subscribe(accountId => {
      if (accountId) {
        this.store.dispatch(
          ReactivateUsers.Request({ userAKs: this.filteredDeactivatedUserAks, accountId })
        );
        this.close();
      }
    });
  }

  recreateUsers() {
    this.selectedAccountId$.pipe(take(1)).subscribe(accountId => {
      if (accountId) {
        this.store.dispatch(
          RecreateUsers.Request({
            model: {
              userAKs: this.filteredDeactivatedUserAks,
              unlockDate: this.unlockDate
            },
            accountId
          })
        );
        this.close();
      }
    });
  }

  close() {
    this.modalRef.hide();
  }
}
