import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { take, takeUntil, withLatestFrom } from 'rxjs/operators';
import {
  AccountAdminResendSpotlightInvites,
  AdminSetSelectedUser,
  UnlockUser,
  UpdateUsersDelegatedInvites,
} from 'src/app/+state/account-admin/account-admin.actions';
import { DeactivateUser } from 'src/app/+state/account/account.actions';
import { AppState } from 'src/app/+state/app.state';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { environment } from 'src/environments/environment';
import { selectAccountSettings, selectMindflickOrganisationSettings, selectSelectedUserManagementAccountId } from '../../../../+state/account-admin/account-admin.selector';
import {
  faAdminSVG,
  faChampionSVG,
  faEditSVG,
  faTickSVG,
} from '../../../../../icons';
import { AccountAdminManagedUsersDTO } from '../../../../shared/models/account-admin-managed-users-DTO';
import { PlatformInviteStatusEnum } from '../../../../shared/models/enums/platform-invite-status.enum';
import { SpotlightInviteStatusEnum } from '../../../../shared/models/enums/spotlight-invite-status.enum';
import { DownloadService } from '../../../../shared/services/download.service';
import { selectCurrentUser } from 'src/app/+state/user/user.selector';
import { DelegateInvitesModalComponent } from 'src/app/admin/delegate-invites-modal/delegate-invites-modal.component';
import { selectCurrentAccountIsMindflickLed } from 'src/app/+state/account/account.selector';
import { User } from '../../../../shared/models';
import { EditUserUnlockDateModalComponent } from 'src/app/admin/edit-user-unlock-date-modal/edit-user-unlock-date-modal.component';
import { AddNoteModalComponent } from 'src/app/admin/add-note-modal/add-note-modal.component';

@Component({
  selector: 'app-user-management-table-row',
  templateUrl: './user-management-table-row.component.html',
  styleUrls: ['./user-management-table-row.component.scss'],
})
export class UserManagementTableRowComponent implements OnInit, OnDestroy {
  @Input() user: AccountAdminManagedUsersDTO =
    {} as AccountAdminManagedUsersDTO;

  @Input()
  isSelected: boolean = false;

  @Input()
  showDropUp: boolean = false;

  @Output()
  userSelected: EventEmitter<number> = new EventEmitter<number>();

  orgSettings$ = this.store.select(selectMindflickOrganisationSettings);
  accountSettings$ = this.store.select(selectAccountSettings);
  currentUser$ = this.store.select(selectCurrentUser);
  currentAccountIsMindflickLed$ = this.store.select(
    selectCurrentAccountIsMindflickLed
  );
  selectedAccountId$ = this.store.select(selectSelectedUserManagementAccountId);
  destroyed$ = new Subject<boolean>();

  spotlightStatusNone = SpotlightInviteStatusEnum.None;
  spotlightStatusInvited = SpotlightInviteStatusEnum.Invited;
  spotlightStatusInProgress = SpotlightInviteStatusEnum.InProgress;
  spotlightStatusCompleted = SpotlightInviteStatusEnum.Complete;

  PlatformInviteStatus = PlatformInviteStatusEnum;

  isIconsVisible: boolean = false;

  tick = faTickSVG;
  edit = faEditSVG;
  ellipsis = faEllipsisVertical;
  champion = faChampionSVG;
  admin = faAdminSVG;

  daysRetention = 90;
  currentUser?: User;

  constructor(
    private store: Store<AppState>,
    private dialogService: DialogService,
    private downloadService: DownloadService,
    @Inject('BASE_URL') private baseUrl: string,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    this.currentUser$.pipe(takeUntil(this.destroyed$)).subscribe((user) => {
      this.currentUser = user;
    });

    this.orgSettings$.pipe(takeUntil(this.destroyed$)).subscribe((x) => {
      this.daysRetention = x?.dataRetentionDurationDays!;
    });
  }

  setIconsVisibility(isVisible: boolean) {
    this.isIconsVisible = isVisible;
  }

  editUser(user: AccountAdminManagedUsersDTO) {
    // this.modalService.show(EditManagedUserDetailsModalComponent, {
    //   class: 'modal-dialog-centered',
    //   initialState: { editUser: this.user },
    // });
    this.store.dispatch(AdminSetSelectedUser({ userAk: user.ak }));
  }

  selectUser() {
    this.userSelected.emit(this.user.ak);
  }

  individualResendSpotlight(userAk: number) {
    this.selectedAccountId$.pipe(take(1)).subscribe(accountId => {
      if (accountId)
        this.store.dispatch(
          AccountAdminResendSpotlightInvites.Request({ userAks: [userAk], accountId })
        );
    })
    
  }

  deactivateSingleUser(userAk: number, userName: string) {
    let title = `Delete ${userName}`;
    var optionalMessage = this.daysRetention > 0 ? ` after ${ this.daysRetention } days` : ''
    let message = `This user will be removed from your account and will be permanently deleted${optionalMessage}. Are you sure you wish to continue?`;
    this.dialogService
      .confirm(message, title, true)
      .pipe(take(1), withLatestFrom(this.selectedAccountId$))
      .subscribe(([result, accountId]) => {
        if (result && accountId) {
          this.store.dispatch(DeactivateUser.Request({ userAK: userAk, accountId }));
        }
      });
  }

  downloadUserProfile(userAK: number, name: string) {
    this.selectedAccountId$.pipe(take(1)).subscribe(accountId => {
      if (accountId) {
        var url = `${this.baseUrl}accountadmin/account/${accountId}/user/${userAK}/profile/pdf`;

        this.downloadService.addToQueue(`spotlight-report-${name}.pdf`, url);
      }
    });
  }

  individualGetSpotlightLink(user: AccountAdminManagedUsersDTO) {
    if (!user.spotlightResponseId || !user.spotlightResponseUniqueRef) return;

    this.dialogService.showLink(
      `${environment.identityServerRoot}/account/register-platform/${user.spotlightResponseId}/${user.spotlightResponseUniqueRef}`,
      `Sign up link for ${user.name}`
    );
  }

  unlockUser(userAk: number) {
    this.dialogService
      .confirm(
        `Are you sure you want to unlock this user?`,
        'Are you sure?',
        true,
        'Yes',
        'No'
      )
      .pipe(take(1), withLatestFrom(this.selectedAccountId$))
      .subscribe(([result, accountId]) => {
        if (result && accountId) this.store.dispatch(UnlockUser.Request({ userAk, accountId }));
      });
  }

  openEditUnlockDateModal(user: AccountAdminManagedUsersDTO) {
    this.modalService.show(EditUserUnlockDateModalComponent, {
      class: 'modal-dialog-centered',
      initialState: {
        userAk: user.ak,
        userName: user.name,
        unlockDate: user.unlockDate,
      },
    });
  }

  openManageDelegatedInvitesModal(user: AccountAdminManagedUsersDTO) {
    this.store.dispatch(AdminSetSelectedUser({ userAk: user.ak }));

    this.modalService.show(DelegateInvitesModalComponent);
  }

  revokeUnusedInvites(userAk: number) {
    this.selectedAccountId$.pipe(take(1)).subscribe(accountId => {
      if (accountId) {
        this.store.dispatch(
          UpdateUsersDelegatedInvites.Request({
            payload: {
              userAk,
              delegatedInvites: 0,
              delegatedInvitesExpiry: undefined,
            },
            accountId
          })
        );
      }
    });
  }

  openNotesModal(user: AccountAdminManagedUsersDTO) {
    this.modalService.show(AddNoteModalComponent, {
      class: 'modal-dialog-centered',
      initialState: { user: user },
    });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }
}
