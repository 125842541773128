import { ContentType, WorkOnType } from './enums';
import { GrowthTag } from './growth.interface';

export interface WorkOn {
  id: number;
  title: string;
  summary: string;
  type: WorkOnType;
  workOnTags?: WorkOnTagsDTO[];
  tagIds: number[];
  sections: WorkOnSection[];

  imageUrl: string;
  portalCurrentImageUrl: string;
  portalUpcomingImageUrl: string;

  inactive: boolean;
  isUserSpecific: boolean;
  nudges: NudgesDTO[];

  //Only supplied for Super-Users
  originalSummary?: string;
  icon?: string;
}

export interface WorkOnTagsDTO {
  tagId: number;
  orderById: number;
}

export interface NudgesDTO {
  id: number;
  FlexCope: string;
  Title: string;
  Paragraph1: string;
  Paragraph2: string;
}

export interface WorkOnSection {
  title: string;
  shortTitle: string;
  orderById: number;
  contents: WorkOnSectionContent[];
  contentHtml: string;

  //Only supplied for Super-Users
  originalContentHtml?: string;
}

export interface WorkOnSectionContent {
  text: string;
  mediaUrl: string;
  type: ContentType;
  orderById: number;
}

export interface WorkOnHistory {
  id: number;
  orderById: number;
  startedDate?: Date;
  completedDate?: Date;
  workOnId: number;
}

export interface MyWorkOns {
  currentWorkOn: WorkOnHistory;
  upcomingWorkOns: WorkOnHistory[];
  completedWorkOns: WorkOnHistory[];
}
