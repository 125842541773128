import { createAction, props } from '@ngrx/store';


export const MarkBotAsHidden = createAction(
    '[Debrief] Mark Bot As Hidden'
);


// COPE

export const MarkCopeAsReadout = createAction(
  '[Debrief] Mark Cope As Readout',
);

export const MarkAsOnCopeIntroPage = createAction(
    '[Debrief] Mark Cope As On Intro Page'
  );

export const MarkAsOnCopePreferencesPage = createAction(
    '[Debrief] Mark Cope As On Preferences Page'
  );

export const MarkCopePreferencePageAsComplete = createAction(
'[Debrief] Mark Cope Preference Page As Complete'
);


//FLEX

export const MarkAsOnFlexPreferencesPage = createAction(
    '[Debrief] Mark As On Flex Preferences Page'
);
  
export const MarkFlexPreferencePageAsComplete = createAction(
    '[Debrief] Mark Flex Preference Page As Complete'
);


//STRENGTHS

export const MarkAsOnStrengthsFirstPage = createAction(
    '[Debrief] Mark As On Strengths First Page',
    props<{ numOfItemsSelected: number }>()
);

export const MarkAsOnStrengthsFirstPageComplete = createAction(
    '[Debrief] Mark As On Strengths First Page Complete'
);

export const MarkSweetSpotAsOnPage = createAction(
    '[Debrief] Mark Sweet Spot As On Page'
)

export const MarkSweetSpotAsOnComplete = createAction(
  '[Debrief] Mark Sweet Spot As On Complete'
)

export const MarkStrengthsOverplayedShow = createAction(
  '[Debrief] Mark Strengths Overplayed Show'
)


export const MarkStrengthsOverPlayedComplete = createAction(
  '[Debrief] Mark Strengths Over Played Complete'
)

export const MarkAsOnWriggleRoomSelectablePage = createAction(
  '[Debrief] Mark As On Wriggle Room Selectable Page'
)