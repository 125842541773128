import { PlatformInviteStatusEnum } from './enums/platform-invite-status.enum';
import { User, UserBasicDetails } from './user.interface';

export interface AccountAdminManagedUsersDTO {
  ak: number;
  firstName: string;
  lastName: string;
  activeSpotlightResponseId: number;
  platformStatus: PlatformInviteStatusEnum.Invited;
  assignedWorkOnIds: []
  name: string;
  flexCope: string;
  createdDate: Date;
  portalStatus: PlatformInviteStatusEnum;
  spotlightCompletion: number;
  userJoinedApp: boolean;
  isUserActive: boolean;
  department: string | null;
  isMindflickChampion: boolean;
  isAccountAdmin: boolean;
  email: string;
  jobTitle: string | null;
  spotlightResponseUniqueRef?: string;
  spotlightResponseId?: number;
  canDownloadPDF: boolean;
  creditPotId?: number;
  isLocked: boolean;
  unlockDate?: Date;
  delegatedInvites: number;
  delegatedInvitesUsed: number;
  delegatedInvitesExpiry?: Date;
  mindflickRoleIds: number[];
  isPractitioner: boolean;
  notes?: string;
}

export interface AccountSettings {
  id : number;
  singleSignOnEnabled: boolean;
  canChampionsViewAnalytics: boolean;
  canUsersDownloadSpotlight: boolean;
  blockPDFDownloads: boolean;
  adminCanAllocateSeats: boolean;
  championCanAllocateSeats: boolean;
}

export interface AccountSummaryData {
  mindflickAccountId : number;
  accountName : string;
  accountAdminId?: number;
  accountAdminName?: string;
  accountAdminEmail?: string;
  dateAdded?: Date;
  renewalDate?: Date;
  totalSeats?: number;
  usedSeats?: number;
}

export interface OrganisationSettings {
  dataRetentionDurationDays: number;
  sessionDurationMins: number;
  mfaRequired: boolean;
  requiresExternalAuthentication: boolean;
}

export interface SeatsInAcc {
  totalSeats: number;
  currentActiveUsersInAcc: number;
  delegatedSeats: number;
  delegatedSeatsUsed: number;
  delegatedSeatsNotUsed: number;
  seatsUsed: number;
  remainingSeats: number;
}

export interface AccountAdminManagedUsersFilter {
  userStatus?: UserStatusEnum;
  preference?:
    | 'FC'
    | 'FO'
    | 'FP'
    | 'FE'
    | 'LC'
    | 'LO'
    | 'LP'
    | 'LE'
    | 'EC'
    | 'EO'
    | 'EP'
    | 'EE'
    | 'XC'
    | 'XO'
    | 'XP'
    | 'XE';
  dateAdded?: Date | 'custom'; 
  toDate?: Date;
  fromDate?: Date;
  department?: string;
  portalStatus?: PlatformInviteStatusEnum;
  appStatus?: boolean;
  nameFilter: string;
  hasUnusedSeats: boolean;
}

export interface AddedUsersResponseModel {
  success: boolean;
  message: string;
  addedUsers: AccountAdminManagedUsersDTO[];
  usersNotAdded: UserBasicDetails[];
  deactivatedUsers: UserBasicDetails[];

  delegatedAddedUser?: User;
}

export interface UpdateUsersDelegatedInvitesModel {
  userAk: number;
  delegatedInvites: number;
  delegatedInvitesExpiry?: Date;
}

export enum UserStatusEnum {
  All,
  User,
  Champion,
  Admin,
}


export interface NewAccountAdminModel {
  userId : number,
  mindflickAccountId : number
}
