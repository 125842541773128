import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, take } from 'rxjs';
import { AppState } from 'src/app/+state/app.state';
import { selectUserGroup } from 'src/app/+state/user/user.selector';
import { StrengthComment, User } from 'src/app/shared/models';
import { faCloseSVG } from '../../../../icons';
import { environment } from '../../../../environments/environment';
import { SayThanksStrengthSpot } from 'src/app/+state/profile/profile.actions';

@Component({
  selector: 'app-strengths-modal',
  templateUrl: './strengths-modal.component.html',
  styleUrls: ['./strengths-modal.component.scss'],
})
export class StrengthsModalComponent implements OnInit {
  recognizingUsers: number[] = [];
  strengthDescription: string = '';
  highlightedUser?: number;

  comments: StrengthComment[] = [];

  close = faCloseSVG;

  userList$: Observable<User[]> | undefined;
  userImages: { [userId: string]: string; } = {};
  userNames: { [userId: string]: string; } = {};

  publicPhotoBasePath = environment.publicStorageUrl + 'photos/thumb/';
  constructor(private store: Store<AppState>, public bsModalRef: BsModalRef) {}

  ngOnInit(): void {
    this.userList$ = this.store.select(selectUserGroup(this.recognizingUsers));
    this.userList$.pipe(take(1)).subscribe(users => {
      users.forEach(user => {
        const { photoLastModified, strId, id, firstName, lastName } = user;

        this.userNames[id] = `${firstName} ${lastName}`;

        this.userImages[id] = photoLastModified && strId
          ? `${this.publicPhotoBasePath}${strId}_${photoLastModified}.jpg`
          : './assets/svg/profile-placeholder.svg';
      });
    });
  }

  sayThanks(strengthComment: StrengthComment) {
    this.store.dispatch(SayThanksStrengthSpot.Request({ strengthComment }))

    let copyComments = [...this.comments];

    let commentIndex = copyComments.findIndex(i => i.id === strengthComment.id);
    if (commentIndex !== -1) {
        // Create a new object with the updated acknowledged value
        copyComments[commentIndex] = {
            ...copyComments[commentIndex],
            acknowledgedDate: new Date(Date.now())
        };
    }

    this.comments = copyComments;
}


  hideModal() {
    this.bsModalRef.hide();
  }

  handleMissingImg(_: Event, commentorAK: number) {
   this.userImages[commentorAK] = './assets/svg/profile-placeholder.svg';
  }
}
