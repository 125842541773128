export interface SpotlightResponse {
  spotlightId?: number;
  creditPurchaseId?: number;
  questionSetName?: string;
  pages: SpotlightResponsePage[];
  answers: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  gender?: string;
  age?: number;
  companyName?: string;
  role?: string;
  department?: string;
  userId?: string;
  sendSignUpEmailImmediately: boolean;
  //These properties are only returned when calling InProgress
  id?: number;
  surveyId?: number;
  uniqueRef?: string;
  hasProfilePic: boolean;
  completedDate: string;
  fleX_COPE: string;
  isActiveSpotlight: boolean;
}

export interface SpotlightResponsePage {
  wordPairs: string[];
}

export interface SpotlightResponseLanguage {
  languageId: number;
  langId: string;
  questions: string[][];
}

export interface SaveAnswersRequest {
  surveyId?: number;
  uniqueRef?: string;
  langId?: string;
  answers?: string;
}

export interface SurveyCreateRequest {
  spotlightId: number;
  creditPurchaseId: number;
  email: string;
  firstName: string;
  lastName: string;
  age?: number;
  companyName: string;
  role?: string;
  gender: string;
}

export interface SurveyCreateResponse {
  surveyId: number;
  uniqueRef: string;
  answers: string;
}

export interface SurveyWordPair {
  index: number;
  wordPair: string;
  selected: boolean;
}

export interface PlatformInviteModel {
  firstName: string;
  lastName: string;
  email: string;
}
