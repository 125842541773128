import { createSelector } from '@ngrx/store';
import {
  Profile,
  TeamMapUser,
  TeamMapUsers,
  User,
} from 'src/app/shared/models';
import { AppState, profileStateKey } from '../app.state';
import { selectTeamMapIsFlex } from '../team/team.selector';
import { selectCurrentUser, selectSelectedUser } from '../user/user.selector';

export const selectProfileState = (state: AppState) => state[profileStateKey];

export const selectLoadedProfiles = (state: AppState) =>
  state[profileStateKey].loadedProfiles;

export const selectCurrentProfile = (state: AppState) =>
  state[profileStateKey].currentProfile;

export const selectProfileLoading = (state: AppState) =>
  state[profileStateKey].profileLoading;

export const selectProfileProcessing = (state: AppState) =>
  state[profileStateKey].profileProcessing;

export const selectMyProfile = (state: AppState) =>
  state[profileStateKey].myProfile;

export const isViewingOwnProfile = (state: AppState) =>
  state[profileStateKey].isViewingOwnProfile;

export const selectProfileTeamMapUsers = createSelector(
  selectCurrentUser,
  selectSelectedUser,
  selectLoadedProfiles,
  selectTeamMapIsFlex,
  (
    user: User | undefined,
    profileUser: User | undefined,
    profiles: Profile[],
    isFlex: boolean
  ): TeamMapUsers => {
    let teamMapUsers: TeamMapUser[] = [];

    let userMap: TeamMapUser = {
      userId: user ? user.id : 0,
      initials: user ? user.initials : '',
      flexCoords: user ? user.flexCoords : { x: 0, y: 0 },
      copeCoords: user ? user.copeCoords : { x: 0, y: 0 },
    };
    let profileMap: TeamMapUser = {
      userId: profileUser ? profileUser.id : 0,
      initials: profileUser ? profileUser.initials : '',
      flexCoords: profileUser ? profileUser.flexCoords : { x: 0, y: 0 },
      copeCoords: profileUser ? profileUser.copeCoords : { x: 0, y: 0 },
    };

    teamMapUsers = profileUser ? [userMap, profileMap] : [userMap];

    let profileProfile = profileUser
      ? profiles.find((x) => x.userAK == profileUser.id)
      : undefined;
    let desc1 = isFlex
      ? profileProfile?.behaviouralStyleTitle
      : profileProfile?.mindsetTitle;
    let desc2 = isFlex
      ? profileProfile?.behaviouralStyleDescription
      : profileProfile?.mindsetDescription;
    let desc = desc1 + '\n\n' + desc2;
    if (profileUser) {
      desc = desc?.replace('{{FIRST_NAME}}', profileUser.firstName);
    }
    let spotlights = '';
    if (profileProfile) {
      if (isFlex) {
        let flexCount = profileProfile.flexScores.filter(
          (x) => x >= 3.5
        ).length;
        if (flexCount > 0)
          spotlights = profileProfile.flex.substring(0, flexCount);
      } else {
        let copeCount = profileProfile.copeScores.filter(
          (x) => x >= 3.5
        ).length;
        if (copeCount > 0)
          spotlights = profileProfile.cope.substring(0, copeCount);
      }
    }

    return {
      members: teamMapUsers,
      firstName: profileUser?.firstName,
      inSpotlight: spotlights,
      description: desc?.split('\n\n'),
    };
  }
);

export const selectProfileStrengths = createSelector(
  selectCurrentProfile,
  (profile?: Profile) => {
    let strengthsSection = profile?.sections.find(
      (section) => section.id == 'strengths'
    );

    let validItems = strengthsSection?.items.filter((item) => item.id != null);

    return validItems?.map((item) => item.description);
  }
);

export const selectTopThreeStrengths = createSelector(
  selectMyProfile,
  (profile?: Profile) => {
    let strengthsSection = profile?.sections.find(
      (section) => section.id == 'strengths'
    );

    let validItems = strengthsSection?.items.filter((item) => item.id != null);

    return validItems
      ?.sort(
        (a, b) => b.strengthSpotterIds.length - a.strengthSpotterIds.length
      )
      .slice(0, 5);
  }
);

export const selectProfilesPreferences = createSelector(
  selectCurrentUser,
  selectSelectedUser,
  (user: User | undefined, selectedUser: User | undefined) => {
    let currentUserPreferences = { flex: '', cope: '' };
    let selectedUserPreferences = { flex: '', cope: '' };
    switch (user?.flex[0]) {
      case 'F':
        currentUserPreferences.flex = 'Forcefully';
        break;
      case 'L':
        currentUserPreferences.flex = 'Logically';
        break;
      case 'E':
        currentUserPreferences.flex = 'Empathically';
        break;
      case 'X':
        currentUserPreferences.flex = 'Expressively';
        break;
    }
    switch (selectedUser?.flex[0]) {
      case 'F':
        selectedUserPreferences.flex = 'Forcefully';
        break;
      case 'L':
        selectedUserPreferences.flex = 'Logically';
        break;
      case 'E':
        selectedUserPreferences.flex = 'Empathically';
        break;
      case 'X':
        selectedUserPreferences.flex = 'Expressively';
        break;
    }
    switch (user?.cope[0]) {
      case 'C':
        currentUserPreferences.cope = 'Contained';
        break;
      case 'O':
        currentUserPreferences.cope = 'Optimistic';
        break;
      case 'P':
        currentUserPreferences.cope = 'Prudent';
        break;
      case 'E':
        currentUserPreferences.cope = 'Engaged';
        break;
    }
    switch (selectedUser?.cope[0]) {
      case 'C':
        selectedUserPreferences.cope = 'Contained';
        break;
      case 'O':
        selectedUserPreferences.cope = 'Optimistic';
        break;
      case 'P':
        selectedUserPreferences.cope = 'Prudent';
        break;
      case 'E':
        selectedUserPreferences.cope = 'Engaged';
        break;
    }
    return { currentUserPreferences, selectedUserPreferences };
  }
);

export const selectPairEmptyChair = createSelector(
  selectCurrentProfile,
  (profile?: Profile) => {
    let emptyChairFlex = '';
    let emptyChairCope = '';

    if (!profile) return '';

    if (!profile.emptyChairFlexCope) return '';

    if (profile.emptyChairFlexCope.length < 2) return '';

    switch (profile?.emptyChairFlexCope[0]) {
      case 'F':
        emptyChairFlex = 'Forcefully';
        break;
      case 'L':
        emptyChairFlex = 'Logically';
        break;
      case 'E':
        emptyChairFlex = 'Empathically';
        break;
      case 'X':
        emptyChairFlex = 'Expressively';
        break;
    }

    switch (profile?.emptyChairFlexCope[1]) {
      case 'C':
        emptyChairCope = 'Contained';
        break;
      case 'O':
        emptyChairCope = 'Optimistic';
        break;
      case 'P':
        emptyChairCope = 'Prudent';
        break;
      case 'E':
        emptyChairCope = 'Engaged';
        break;
    }

    return emptyChairFlex + ' ' + emptyChairCope;
  }
);

export const selectFlexScores = createSelector(
  selectCurrentProfile,
  (profile?: Profile) => {
    return profile?.flexScores;
  }
);

export const selectFlexAndCopeScores = createSelector(
  selectMyProfile,
  (profile?: Profile) => {
    return {
      flex: profile?.flexScores,
      cope: profile?.copeScores,
    };
  }
);

export const selectFlexOrCopeScores = createSelector(
  selectTeamMapIsFlex,
  selectFlexAndCopeScores,
  (isFlex, scores) => {
    return {
      isFlex: isFlex,
      flexScores: scores.flex,
      copeScores: scores.cope,
      selectedScores: isFlex ? scores.flex : scores.cope,
    };
  }
);

export const selectIsNotificationNavigation = (state: AppState) =>
  state[profileStateKey].isNotificationNavigation;
export const selectStrengthIdToView = (state: AppState) =>
  state[profileStateKey].strengthIdToView;

export const selectStrengthNotification = createSelector(
  selectIsNotificationNavigation,
  selectStrengthIdToView,
  (isNotification, strengthId) => {
    return {
      isNotification,
      strengthId,
    };
  }
);

export const selectCurrentUserStrengths = createSelector(
  selectMyProfile,
  (profile?: Profile) => {
    return profile?.sections?.find(
      (section) => section.id == 'strengths'
    )?.items.filter((item) => item.id != null) ?? [];
  }
);

export const selectCurrentUserTakeCares = createSelector(
  selectMyProfile,
  (profile?: Profile) => {
    return profile?.sections?.find(
      (section) => section.id == 'take-care'
    )?.items.filter((item) => item.id != null) ?? [];
  }
);

export const selectCurrentUserWriggleRoom= createSelector(
  selectMyProfile,
  (profile?: Profile) => {
    return profile?.sections?.find(
      (section) => section.id == 'wriggle-room'
    )?.items.filter((item) => item.id != null) ?? [];
  }
);
