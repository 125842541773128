import { createReducer, on } from '@ngrx/store';
import { 
  MarkAsOnCopeIntroPage, 
  MarkAsOnCopePreferencesPage, 
  MarkAsOnFlexPreferencesPage, 
  MarkAsOnStrengthsFirstPage, 
  MarkAsOnStrengthsFirstPageComplete, 
  MarkAsOnWriggleRoomSelectablePage, 
  MarkBotAsHidden, 
  MarkStrengthsOverPlayedComplete, 
  MarkStrengthsOverplayedShow, 
  MarkSweetSpotAsOnComplete, 
  MarkSweetSpotAsOnPage 
} from './debrief.actions';

export interface DebriefState {
  shouldShowBot: boolean;
  cope: Section;
  flex: Section;
  strengths: StrengthsSection;
}

interface Section {
  intro: PageState;
  preferences: PageState;
}

interface PageState {
  onPage: boolean;
  complete: boolean;
}

interface StrengthsSection {
  characterStrengths: CharacterStrengthsState;
  sweetSpot: PageState;
  takeCare: TakeCareState;
  wriggleRoom: PageState;
}

interface CharacterStrengthsState extends PageState {
  numOfStrengthsClicked: number;
}

interface TakeCareState extends PageState {
  displayOverplayed: boolean;
}

export const initialDebreifState: DebriefState = {
  shouldShowBot: false,
  cope: {
    intro: { onPage: false, complete: false },
    preferences: { onPage: false, complete: false }
  },
  flex: {
    intro: { onPage: false, complete: false },
    preferences: { onPage: false, complete: false }
  },
  strengths: {
    characterStrengths: { onPage: false, complete: false, numOfStrengthsClicked: 0 },
    sweetSpot: { onPage: false, complete: false },
    takeCare: { onPage: false, complete: false, displayOverplayed: false },
    wriggleRoom: { onPage: false, complete: false }
  }
};

const updatePageState = (page: PageState, onPage: boolean, complete: boolean): PageState => ({
  ...page,
  onPage,
  complete
});

export const debriefReducer = createReducer(
  initialDebreifState,

  on(MarkBotAsHidden, (state) => ({
    ...state,
    shouldShowBot: false,
  })),

  on(MarkAsOnCopeIntroPage, (state) => ({
    ...state,
    shouldShowBot: true,
    cope: {
      ...state.cope,
      intro: updatePageState(state.cope.intro, true, false)
    }
  })),
  
  on(MarkAsOnCopePreferencesPage, (state) => ({
    ...state,
    shouldShowBot: true,
    cope: {
      ...state.cope,
      intro: updatePageState(state.cope.intro, false, true),
      preferences: updatePageState(state.cope.preferences, true, false)
    }
  })),

  on(MarkAsOnFlexPreferencesPage, (state) => ({
    ...state,
    shouldShowBot: true,
    flex: {
      ...state.flex,
      intro: updatePageState(state.flex.intro, false, true),
      preferences: updatePageState(state.flex.preferences, true, false)
    }
  })),

  on(MarkAsOnStrengthsFirstPage, (state, { numOfItemsSelected }) => ({
    ...state,
    shouldShowBot: true,
    flex: {
      ...state.flex,
      intro: updatePageState(state.flex.intro, false, true),
      preferences: updatePageState(state.flex.preferences, false, true)
    },
    strengths: {
      ...state.strengths,
      characterStrengths: { 
        ...state.strengths.characterStrengths, 
        onPage: true, 
        complete: false, 
        numOfStrengthsClicked: numOfItemsSelected 
      }
    }
  })),

  on(MarkAsOnStrengthsFirstPageComplete, (state) => ({
    ...state,
    shouldShowBot: false,
    strengths: {
      ...state.strengths,
      characterStrengths: { 
        ...state.strengths.characterStrengths, 
        onPage: false, 
        complete: true 
      },
      sweetSpot: updatePageState(state.strengths.sweetSpot, true, false)
    }
  })),

  on(MarkSweetSpotAsOnPage, (state) => ({
    ...state,
    shouldShowBot: true,
    strengths: {
      ...state.strengths,
      characterStrengths: { 
        ...state.strengths.characterStrengths, 
        onPage: false, 
        complete: true 
      },
      sweetSpot: updatePageState(state.strengths.sweetSpot, true, false)
    }
  })),

  on(MarkSweetSpotAsOnComplete, (state) => ({
    ...state,
    shouldShowBot: false,
    strengths: {
      ...state.strengths,
      sweetSpot: updatePageState(state.strengths.sweetSpot, false, true),
      takeCare: { 
        ...state.strengths.takeCare, 
        onPage: true, 
        complete: false, 
        displayOverplayed: true 
      }
    }
  })),

  on(MarkStrengthsOverplayedShow, (state) => ({
    ...state,
    shouldShowBot: true,
    strengths: {
      ...state.strengths,
      takeCare: { 
        ...state.strengths.takeCare, 
        onPage: true, 
        complete: false, 
        displayOverplayed: true 
      }
    }
  })),

  on(MarkStrengthsOverPlayedComplete, (state) => ({
    ...state,
    shouldShowBot: true,
    strengths: {
      ...state.strengths,
      takeCare: { 
        ...state.strengths.takeCare, 
        onPage: false, 
        complete: true, 
        displayOverplayed: true 
      }
    }
  })),

  on(MarkAsOnWriggleRoomSelectablePage, (state) => ({
    ...state,
    shouldShowBot: true,
    strengths: {
      ...state.strengths,
      wriggleRoom: updatePageState(state.strengths.wriggleRoom, true, false)
    }
  }))
);
