import { IconName, IconPrefix } from '@fortawesome/free-solid-svg-icons';
import { NavBarType } from './enums/navbar-types.enum';

export interface NavigationItem {
  id: string;
  title: string;
  type: NavBarType;
  icon?: [IconPrefix, IconName];
  path?: string;
  children: NavigationItem[];
}

export interface MenuLink {
  title: string;
  routerLink?: string[];
  noNav?: string[];
  active?: boolean;
}

export interface AdminMenuLink extends MenuLink {
  permissionRequired: string;
}
