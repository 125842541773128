import { createReducer, on, props } from '@ngrx/store';
import { AlphabeticalSort } from 'src/app/shared/helpers/sorting-helpers';
import {
  ListItem,
  MindflickAccount,
  MindflickAccountFilter,
} from 'src/app/shared/models';
import * as AccountActions from './account.actions';

export interface AccountState {
  allAccounts: MindflickAccount[];
  allAccountsLoading: boolean;
  allAccountsTake: number;
  allAccountsPage: number;
  allAccountsSort:
    | 'name-asc'
    | 'name-desc'
    | 'renewal-asc'
    | 'renewal-desc';
  selectedAccountId?: number;
  userAccounts: MindflickAccount[];
  userAccountsList: ListItem<MindflickAccount>[];
  currentUserAccount?: MindflickAccount;
  userAccountsLoading: boolean;
  mindflickAccountIsSaving: boolean;
  mindflickAccountSaveSuccess: boolean;
  mindflickAccountSaveError: string;
  sendingInvites: boolean;
  sendingInvitesSuccess: boolean;
  sendingInvitesError: string;
  selectedCreditPotId?: number;
  isUserInvitesEnabled: boolean;
  mindflickAccountFilter: MindflickAccountFilter;
  orgTwoFactorRequired: boolean;
}

export const initialAccountState: AccountState = {
  allAccounts: [],
  allAccountsLoading: false,
  allAccountsTake: 15,
  allAccountsPage: 0,
  allAccountsSort: 'name-asc',
  userAccounts: [],
  userAccountsList: [],
  userAccountsLoading: false,
  mindflickAccountIsSaving: false,
  mindflickAccountSaveSuccess: false,
  mindflickAccountSaveError: '',
  sendingInvites: false,
  sendingInvitesSuccess: false,
  sendingInvitesError: '',
  isUserInvitesEnabled: false,
  mindflickAccountFilter: {
    status: null,
    renewalDateStatus: null,
    isMindflickLed: null,
    isDemo: null,
  },
  orgTwoFactorRequired: true,
};

export const accountsReducer = createReducer(
  initialAccountState,

  on(AccountActions.GetAllMindflickAccounts.Request, (state) => ({
    ...state,
    allAccountsLoading: true,
  })),

  on(AccountActions.GetAllMindflickAccounts.Success, (state, props) => ({
    ...state,
    allAccountsLoading: false,
    allAccounts: props.accounts,
  })),

  on(AccountActions.GetAllMindflickAccounts.Fail, (state) => ({
    ...state,
    allAccounts: [],
    allAccountsLoading: false,
  })),

  on(AccountActions.GetMindflickAccountsForCurrentUser.Request, (state) => ({
    ...state,
    userAccountsLoading: true,
  })),

  on(
    AccountActions.GetMindflickAccountsForCurrentUser.Success,
    (state, props) => ({
      ...state,
      userAccounts: props.accounts,
      userAccountsList: props.accounts.map((a) => ({
        id: a.id,
        name: a.name,
        value: a,
      })),
      userAccountsLoading: false,
    })
  ),

  on(AccountActions.GetMindflickAccountsForCurrentUser.Fail, (state) => ({
    ...state,
    userAccounts: [],
    userAccountsList: [],
    userAccountsLoading: false,
  })),
  on(AccountActions.GetUserMindflickAccount.Request, (state) => ({
    ...state,
    userAccountsLoading: true,
  })),

  on(AccountActions.GetUserMindflickAccount.Success, (state, props) => ({
    ...state,
    currentUserAccount: props.account,
    userAccountsLoading: false,
  })),

  on(AccountActions.GetUserMindflickAccount.Fail, (state) => ({
    ...state,
    userAccountsLoading: false,
  })),

  on(AccountActions.AddMindflickAccount.Request, (state) => ({
    ...state,
    mindflickAccountIsSaving: true,
    mindflickAccountSaveSuccess: false,
    mindflickAccountSaveError: '',
  })),

  on(AccountActions.AddMindflickAccount.Success, (state, props) => ({
    ...state,
    allAccounts: [
      ...state.allAccounts.filter((x) => x.id != props.account.id),
      props.account,
    ].sort((a, b) => a.name.localeCompare(b.name)),
    mindflickAccountIsSaving: false,
    mindflickAccountSaveSuccess: true,
  })),

  on(AccountActions.AddMindflickAccount.Fail, (state, props) => ({
    ...state,
    mindflickAccountIsSaving: false,
    mindflickAccountSaveSuccess: false,
    mindflickAccountSaveError: props.error,
  })),

  on(AccountActions.UpdateMindflickAccount.Request, (state) => ({
    ...state,
    mindflickAccountIsSaving: true,
    mindflickAccountSaveSuccess: false,
    mindflickAccountSaveError: '',
  })),

  on(AccountActions.UpdateMindflickAccount.Success, (state, props) => ({
    ...state,
    selectedAccountId: props.account.id,
    allAccounts: state.allAccounts.map((acc) => {
      if (acc.id === props.account.id) {
        return {
          ...props.account,
          usedSeats: acc.usedSeats,
          creditPots: acc.creditPots,
        };
      }

      return {
        ...acc,
      };
    }),
    mindflickAccountIsSaving: false,
    mindflickAccountSaveSuccess: true,
  })),

  on(AccountActions.UpdateMindflickAccount.Fail, (state, props) => ({
    ...state,
    mindflickAccountIsSaving: false,
    mindflickAccountSaveSuccess: false,
    mindflickAccountSaveError: props.error,
  })),

  on(AccountActions.ClearSaveMindflickOutcomes, (state) => ({
    ...state,
    mindflickAccountSaveSuccess: false,
    mindflickAccountSaveError: '',
  })),

  on(AccountActions.SetSelectedAccount, (state, props) => ({
    ...state,
    selectedAccountId: props.accountId,
  })),

  on(AccountActions.ClearSelectedAccount, (state, props) => ({
    ...state,
    selectedAccountId: undefined,
  })),
  on(AccountActions.ResetInviteState, (state) => ({
    ...state,
    sendingInvites: false,
    sendingInvitesSuccess: false,
    sendingInvitesError: '',
  })),
  on(AccountActions.SetAccountManagementPage, (state, props) => ({
    ...state,
    allAccountsPage: props.page,
  })),
  on(AccountActions.SetAccountManagementItemsPerPage, (state, props) => ({
    ...state,
    allAccountsTake: props.itemsPerPage,
  })),
  on(AccountActions.SetSelectedCreditPot, (state, props) => ({
    ...state,
    selectedCreditPotId: props.creditPotId,
  })),
  on(AccountActions.ClearSelectedCreditPot, (state, props) => ({
    ...state,
    selectedCreditPotId: undefined,
  })),
  on(AccountActions.AddCreditPot.Success, (state, props) => ({
    ...state,
    allAccounts: [
      ...state.allAccounts.map((account) => {
        if (account.id === props.creditPot.mindflickAccountId) {
          return {
            ...account,
            creditPots: [...account.creditPots, props.creditPot],
            seats: account.seats + props.creditPot.seats,
          };
        }

        return {
          ...account,
        };
      }),
    ],
  })),
  on(AccountActions.UpdateCreditPot.Success, (state, props) => ({
    ...state,
    allAccounts: [
      ...state.allAccounts.map((account) => {
        if (account.id === props.creditPot.mindflickAccountId) {
          let seatDifference = 0;
          return {
            ...account,
            creditPots: [
              ...account.creditPots.map((creditPot) => {
                if (creditPot.id === props.creditPot.id) {
                  seatDifference = props.creditPot.seats - creditPot.seats;
                  return {
                    ...creditPot,
                    seats: props.creditPot.seats,
                    renewalDate: props.creditPot.renewalDate,
                  };
                }

                return {
                  ...creditPot,
                };
              }),
            ],
            seats: account.seats + seatDifference,
          };
        }

        return {
          ...account,
        };
      }),
    ],
  })),
  on(AccountActions.SetMindflickAccountSort, (state, props) => ({
    ...state,
    allAccountsSort: props.sort,
  })),
  on(AccountActions.GetUserInvitesEnabled.Success, (state, props) => ({
    ...state,
    isUserInvitesEnabled: props.isUserInvitesEnabled,
  })),
  on(AccountActions.SetMindflickAccountFilter, (state, props) => ({
    ...state,
    mindflickAccountFilter: props.filter,
  })),
  on(AccountActions.ClearMindflickAccountFilter, (state, props) => ({
    ...state,
    mindflickAccountFilter: {
      status: null,
      renewalDateStatus: null,
      isMindflickLed: null,
      isDemo: null,
    },
  })),
  on(AccountActions.GetOrgTwoFactorRequired.Success, (state, props) => ({
    ...state,
    orgTwoFactorRequired: props.orgTwoFactorRequired
  }))
);
