import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Platform } from 'ngx-bootstrap/focus-trap/platform';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject, combineLatest, takeUntil } from 'rxjs';
import {
  selectAccountAdminManagedUsers,
  selectAccountManagedUsersState,
  selectSeatsInAccData,
} from 'src/app/+state/account-admin/account-admin.selector';
import { selectSelectedAccountId } from 'src/app/+state/account/account.selector';
import { AppState } from 'src/app/+state/app.state';
import { AccountAdminManagedUsersDTO } from 'src/app/shared/models/account-admin-managed-users-DTO';
import { PlatformInviteStatusEnum } from 'src/app/shared/models/enums/platform-invite-status.enum';
import { SpotlightInviteStatusEnum } from 'src/app/shared/models/enums/spotlight-invite-status.enum';
import { faCloseSVG } from 'src/icons';

@Component({
  selector: 'app-seat-usage-breakdown-modal',
  templateUrl: './seat-usage-breakdown-modal.component.html',
  styleUrls: ['./seat-usage-breakdown-modal.component.scss'],
})
export class SeatUsageBreakdownModalComponent implements OnInit, OnDestroy {
  users$ = this.store.select(selectAccountManagedUsersState);
  seatsInOrg$ = this.store.select(selectSeatsInAccData);
  selectedAccount$ = this.store.select(selectSelectedAccountId);

  destroyed$ = new Subject<boolean>();

  closeIcon = faCloseSVG;

  usedSeats: number = 0;
  totalSeats: number = 0;

  activeUsers: number = 0;
  invitedUsers: number = 0;
  lockedUsers: number = 0;
  unusedTeammateInvites: number = 0;
  usersWithoutAnInvite: number = 0;
  usersInProgress: number = 0;
  constructor(private modalRef: BsModalRef, private store: Store<AppState>) {}

  ngOnInit(): void {
    this.seatsInOrg$.pipe(takeUntil(this.destroyed$)).subscribe((seatsData) => {
      if (seatsData) {
        this.totalSeats = seatsData.totalSeats;
        this.usedSeats = seatsData.seatsUsed;
        this.unusedTeammateInvites = seatsData.delegatedSeatsNotUsed;
      }
    });

    this.users$.pipe(takeUntil(this.destroyed$)).subscribe((users) => {
      let managedUsers: AccountAdminManagedUsersDTO[] = [];
      const accountId = Number(
        localStorage.getItem('selected-mindflick-account')
      );

      if (accountId == 1 || accountId == 8) {
        managedUsers = users;
      } else {
        managedUsers = users.filter(
          (x) => !x.email.toLowerCase().includes('mindflick.co.uk')
        );
      }
      this.activeUsers = managedUsers.filter(
        (x) =>
          x.isUserActive &&
          x.portalStatus === PlatformInviteStatusEnum.Completed
      ).length;

      this.lockedUsers = managedUsers.filter(
        (x) =>
          x.isUserActive && x.portalStatus === PlatformInviteStatusEnum.Locked
      ).length;

      this.invitedUsers = managedUsers.filter(
        (x) =>
          x.isUserActive && x.portalStatus === PlatformInviteStatusEnum.Invited
      ).length;

      this.usersInProgress = managedUsers.filter(
        (x) =>
          x.isUserActive && x.portalStatus === PlatformInviteStatusEnum.SpotlightInProgress
      ).length;

      this.usersWithoutAnInvite = managedUsers.filter(
        (x) =>
          x.isUserActive && x.portalStatus === PlatformInviteStatusEnum.None
      ).length;

    
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }

  close() {
    this.modalRef.hide();
  }
}
