import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class MindflickAccountInterceptor implements HttpInterceptor {
  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const selectedAccountId = localStorage.getItem(
      'selected-mindflick-account'
    );

    const modifiedReq = req.clone({
      headers: req.headers.set(
        'custom-mindflick-account-id',
        selectedAccountId ?? ''
      ),
    });

    return next.handle(modifiedReq);
  }
}
