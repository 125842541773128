import { Component, OnInit } from '@angular/core';
import { faNextSVG } from '../../../icons';
import { Store } from '@ngrx/store';
import { AppState } from '../../+state/app.state';
import { selectCurrentUser } from '../../+state/user/user.selector';
import { filter, take } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import {
  SetDashboardIsLoading,
  setTitleBar,
} from '../../+state/layout/actions/layout.actions';
import { isNonNull } from '../../shared/helpers/rxjs-type-guards';
import { GetUser } from 'src/app/+state/user/user.actions';
import { GetMindflickAccountsForCurrentUser } from '../../+state/account/account.actions';

@Component({
  selector: 'app-product-page',
  templateUrl: './product-page.component.html',
  styleUrls: ['./product-page.component.scss'],
})
export class ProductPageComponent implements OnInit {
  faNext = faNextSVG;

  constructor(private store: Store<AppState>, private router: Router) {}

  ngOnInit() {
    this.store.dispatch(setTitleBar({ visible: false }));
    this.store.dispatch(SetDashboardIsLoading({ isLoading: false }));
  }

  continueUserFlow() {
    sessionStorage.setItem(PRODUCT_SET_STORAGE_KEY, 'true');
    this.store.dispatch(setTitleBar({ visible: true }));
    this.store.dispatch(SetDashboardIsLoading({ isLoading: true }));

    var redirectUrl = localStorage.getItem('redirect-url') ?? ""; 

    if (localStorage.getItem('selected-mindflick-account')) {
      this.store.dispatch(GetUser.Request());

      if (localStorage.getItem("redirect-url")) {
        this.router.navigateByUrl(redirectUrl);
      } else {
        this.router.navigate(['/dashboard']);
      }

    } else {
      this.store.dispatch(GetMindflickAccountsForCurrentUser.Request());
      if (localStorage.getItem("redirect-url")) {
        this.router.navigateByUrl(redirectUrl);
      }
    }
  }

  goToSpotlight() {
    sessionStorage.setItem(PRODUCT_SET_STORAGE_KEY, 'true');
    window.location.href = environment.spotlightUrl;
  }
}

export const PRODUCT_SET_STORAGE_KEY = 'isProductSet';
