import { ConnectionStatus } from './enums/connection-status.enum';
import { DigitalDebriefStageEnum } from './enums/digital-debrief-stage.enum';
import { PlatformInviteStatusEnum } from './enums/platform-invite-status.enum';
import { PlatformStatusEnum } from './enums/platform-status.enum';
import { Team, TeamMapCoordinates } from './team.interface';

export interface User {
  id: number;
  strId: string;
  firstName: string;
  lastName: string;
  initials: string;
  email: string;
  jobTitle: string;
  // Age and Gender should only be returned for the current user
  age?: number;
  gender?: string;
  flex: string;
  cope: string;
  flexCoords: TeamMapCoordinates;
  copeCoords: TeamMapCoordinates;
  connectionStatus: ConnectionStatus;
  teams: string[];
  joinedDate: string;
  platformIntroViewed: string;
  platformFirstLogin?: Date;
  isSuperAdmin: boolean;
  accountId: number;
  roles: MindflickRole[];
  accountRoles: { [id: number]: MindflickRole[] };
  twoFactorEnabled?: boolean;
  photoLastModified: string;
  platformSettings: { [id: string]: string };
  canDownloadSpotlight: boolean;
  championCreditPotId?: number;
  isLocked: boolean;
  requiresExternalAuthentication: boolean;
  unlockDate?: Date;
  delegatedInvites: number;
  invitedUserAks: number[];
  isPulseSurveyDue: boolean;
  userJoinedApp: boolean;
  mutualConnectionsCount: number;
  isSpotlightComplete: boolean;
  spotlightUniqueRef: string;
  spotlightId: number;
  isOnPlatformAndPractitioner: boolean;
  hasAcceptedChampionCharter: boolean;
  isMindflickCoach: boolean;
  isDebriefRequired: boolean;
  isDebriefProcessed: boolean;
  latestDebriefStage?: DigitalDebriefStageEnum;

}

export interface TeamMapUsers {
  members: TeamMapUser[];
  inSpotlight?: string;
  description?: string[];
  firstName?: string;
}

export interface TeamMapUser {
  userId: number;
  initials: string;
  flexCoords: TeamMapCoordinates;
  copeCoords: TeamMapCoordinates;
  nestedUsers?: TeamMapUser[];
}

export interface EditUserDetailsModel {
  firstName: string;
  lastName: string;
  gender: string;
  jobTitle: string;
}

export interface NotificationSettings {
  description: string;
  pushNotificationKey: string;
  emailNotificationKey: string;
}

export interface UserManagementUser {
  ak: number;
  firstName: string;
  lastName: string;
  email: string;
  activeSpotlightResponseId: number;
  flexCope?: string;
  createdDate?: Date;
  spotlightInviteSent?: Date;
  spotlightCompletedDate?: Date;
  signUpInviteSent?: Date;
  platformJoinedDate?: Date;
  platformRemovedDate?: Date;
  platformStatus: PlatformInviteStatusEnum;
  assignedWorkOnIds: number[];
  referredByAk?: number;
  referredBy?: UserBasicDetails;
  isLocked: boolean;
  spotlightResponseUniqueRef?: string;
  unlockDate?: Date;
}

export interface UserBasicDetails {
  ak?: number;
  mindflickAccountId?: number;
  firstName: string;
  lastName: string;
  email: string;
}

export interface UpdateUserModel {
  ak: number;
  firstName: string;
  lastName: string;
  email: string;
  referredByAk?: number;
}

export interface MindflickRole {
  id: number;
  name: string;
  longName: string;
  permissions: string[];
  permissionDescriptions: string[];
  isSubscriberRoleRequired: boolean;
}
