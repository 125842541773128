import { ProfileItemType } from './enums/profile-item-type.enum';
import { ConnectionDTO } from './team.interface';
export interface ProfileItem {
  id: string; // this is JSON report Id not database id
  title: string;
  description: string;
  type: ProfileItemType;
  isSelected: boolean;
  isSpotted: boolean;
  timesSpotted: number;
  strengthSpotterIds: number[];
  comments: StrengthComment[];
}

export interface ProfileSection {
  id: string;
  title: string;
  items: ProfileItem[];
}

export interface Profile {
  userId: string;
  userAK: number;
  firstName: string;
  lastName: string;
  mindsetTitle: string;
  mindsetDescription: string;
  mindsetImage: string;
  behaviouralStyleTitle: string;
  behaviouralStyleDescription: string;
  behaviouralStyleImage: string;
  sections: ProfileSection[];
  tipsToConnect: ConnectionDTO[];
  topTipToConnect: TopTipToConnect;
  emptyChairs: ConnectionDTO[];
  flexScores: number[];
  copeScores: number[];
  flex: string;
  cope: string;
  emptyChairFlexCope: string;
  error?: string;
}

export interface ConnectionDetails {
  tipsToConnect: ConnectionDTO[];
  emptyChairs: ConnectionDTO[];
}

export interface TopTipToConnect {
  userAk: Number;
  viewedUserAk: Number;
  tipsToConnectId: Number;
}

export interface SaveProfileSelectionModel {
  userId: string;
  reportSection: string;
  isSelected: boolean;
}

export interface PlayingStrengths {
  strengths?: ProfileItem[];
  takeCares?: ProfileItem[];
  wriggleRoom?: ProfileItem[];
}

export interface StrengthComment {
  id: number;
  strengthId: string;
  createdDate: Date;
  comment: string;
  commentorAK: number;
}

export interface SaveStrengthCommentDTO {
  comment: string;
  strengthOwnerId: number;
  strengthId: string;
  createdDate: Date;
}
