<div class="modal-header justify-content-end">
  <fa-icon
    class="cursor-pointer fa-lg"
    [icon]="close"
    (click)="hideModal()"
  ></fa-icon>
</div>
<div class="modal-body overflow-hidden pt-0 d-flex flex-column">
  <h2 class="mb-1">Spotted strength</h2>
  <h3 class="pb-4 pt-3">{{ strengthDescription }}.</h3>
  <hr />
  <div class="overflow-auto">
    <div *ngFor="let comment of comments" class="comment-box border-1 bg-gray mb-2">
      <div class="d-flex">
        <img [src]="userImages[comment.commentorAK]" (error)="handleMissingImg($event, comment.commentorAK)" width="50" height="50" class="me-2 rounded-1" />
        <div class="d-flex flex-column">
          <h3 class="mb-1"> {{ userNames[comment.commentorAK]}} </h3>
          <p class="small-text opacity-50 m-0 lh-sm">Spotted {{ comment.createdDate | date: 'ccc d, MMMM'}}</p>
          <p class="small-text opacity-50 m-0 lh-sm">{{ comment.createdDate | date: 'h:mm aa'}}</p>
        </div>
      </div>
      <p class="body-copy mb-0 mt-15">{{ comment.comment }}</p>
      <button *ngIf="!comment.acknowledgedDate" (click)="sayThanks(comment)" class="thanks-button">Say thanks 🙌</button>
      <button *ngIf="comment.acknowledgedDate" class="reacted-button">You reacted 🙌</button>
    </div>
  </div>
</div>
<div class="modal-bottom-border"></div>
