import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { take } from 'rxjs';
import { UpdateUserUnlockDate } from 'src/app/+state/account-admin/account-admin.actions';
import { selectSelectedUserManagementAccountId } from 'src/app/+state/account-admin/account-admin.selector';
import { AppState } from 'src/app/+state/app.state';
import { faCloseSVG } from 'src/icons';

@Component({
  selector: 'app-edit-user-unlock-date-modal',
  templateUrl: './edit-user-unlock-date-modal.component.html',
  styleUrls: ['./edit-user-unlock-date-modal.component.scss'],
})
export class EditUserUnlockDateModalComponent implements OnInit {
  selectedAccountId$ = this.store.select(selectSelectedUserManagementAccountId);

  userName: string = '';
  userAk: number = 0;

  unlockDate?: Date;

  closeIcon = faCloseSVG;

  tomorrow: Date = new Date();

  showErrors: boolean = false;

  constructor(private modalRef: BsModalRef, private store: Store<AppState>) {}

  ngOnInit(): void {
    this.tomorrow.setDate(this.tomorrow.getDate() + 1);
  }

  close() {
    this.modalRef.hide();
  }

  saveChanges() {
    this.selectedAccountId$.pipe(take(1)).subscribe(accountId => {
      if (accountId) {
        if (this.unlockDate)
          this.store.dispatch(
            UpdateUserUnlockDate.Request({
              userAk: this.userAk,
              unlockDate: this.unlockDate,
              accountId
            })
          );
        else this.showErrors = true;
      }
    });
    
  }
}
