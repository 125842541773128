<div class="large-popout-container">
    <div class="large-popout-shadow"></div>
    <div class="large-popout-content d-flex flex-column">
        <div class="d-flex pt-5 pb-4 align-items-center popout-close">
            <app-btn-action
              class="legal-close fa-lg top"
              [icon]="close"
              [iconDimensions]="35"
              (click)="closePopout()"
            ></app-btn-action>
        </div>
        <div class="flex-fill d-flex flex-column justify-content-between pt-5 pb-4">
            <div *ngIf="mindflickOrganisation$ | async as organisation" class="d-flex flex-column">
                <h3 class="mt-2">{{ organisation.name }}</h3>
                <div class="row gx-0 mt-5 pb-4 border-bottom-grey">
                    <div class="col-6 d-flex pe-5">
                        <div class="flex-fill">
                            <h2>Organisation Admin</h2>
                            <h3 class="mt-2">{{ organisation.adminName }}</h3>
                        </div>
                        <app-btn-tertiary [icon]="editIcon" (click)="openEditOrganisationAdminModal()">Edit</app-btn-tertiary>
                    </div>
                    <div class="col-6 d-flex ps-5">
                        <div class="flex-fill">
                            <h2>Organisation Lead</h2>
                            <h3 class="mt-2">{{ organisation.organisationLeadName }}</h3>
                        </div>
                        <app-btn-tertiary [icon]="editIcon" (click)="openEditOrganisationLeadModal()">Edit</app-btn-tertiary>
                    </div>
                </div>

                <div *ngIf="!loading" class="mt-4 d-flex flex-column" [class.pb-4]="selectedAccount" [class.border-bottom-dark-grey]="selectedAccount">
                    <h2>Organisation Accounts</h2>
                    <ngx-datatable class="bootstrap mt-4"
                        [rows]="organisation.mindflickAccounts"
                        [loadingIndicator]="loading"
                        [columnMode]="ColumnMode.force"
                        [headerHeight]="40"
                        [summaryRow]="false"
                        [footerHeight]="40"
                        [limit]="6"
                        [selected]="selected"
                        [selectionType]="SelectionType.single"
                        rowHeight="auto"
                        (select)="accountSelected()">
                        <ngx-datatable-column name="Account" prop="name" [sortable]="true" />
                        <ngx-datatable-column name="Admin" [sortable]="true" />
                        <ngx-datatable-column name="Date added" [sortable]="true">
                            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                                {{ row.createdDate | date : "dd/MM/yyyy"}}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Renewal date" [sortable]="true">
                            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                                {{ row.renewalDate | date : "dd/MM/yyyy"}}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Remaining Seats" [sortable]="true">
                            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                                {{ row.seats - row.usedSeats }}
                            </ng-template>
                        </ngx-datatable-column>
                    </ngx-datatable>
                        
                </div>

                <div *ngIf="selectedAccount" class="mt-4">
                    <div class="d-flex justify-content-between align-items-end">
                        <div class="d-flex align-items-end">
                            <h3>{{ selectedAccount.name }}</h3>
                            <ng-container [ngSwitch]="selectedAccount.status">
                                <div *ngSwitchCase="0" class="body-copy ms-2 text-primary">Active</div>
                                <div *ngSwitchCase="1" class="body-copy ms-2 text-warning">On-Hold</div>
                                <div *ngSwitchCase="2" class="body-copy ms-2 text-error">Closed</div>
                            </ng-container>
                        </div>
                        <app-btn-tertiary [icon]="editIcon" (click)="openEditAccountModal(selectedAccount.id)">Edit account</app-btn-tertiary>
                    </div>

                    <h2 class="mt-4">Seat Details</h2>
                    <div class="row mt-4 pb-4 border-bottom-grey">
                        <div class="col-4 border-right-grey">
                            <div class="highlight-numbers">{{ selectedAccount.seats }}</div>
                            <h2>Total Seats</h2>
                        </div>
                        <div class="col-4 border-right-grey">
                            <div class="highlight-numbers">{{ selectedAccount.usedSeats }}</div>
                            <h2>Active Seats</h2>
                        </div>
                        <div class="col-4">
                            <div class="highlight-numbers">{{ selectedAccount.maximumChurnPercent }}%</div>
                            <h2>Max Churn Percent</h2>
                        </div>
                    </div>

                    <h2 class="mt-4">Key Dates</h2>
                    <div class="row mt-4 pb-4 border-bottom-grey">
                        <div class="col-6">
                            <div class="form-label">Date added</div>
                            <div class="body-copy">{{ selectedAccount.createdDate | date : "dd/MM/yyyy"}}</div>
                        </div>
                        <div class="col-6">
                            <div class="form-label">Renewal date</div>
                            <div class="body-copy">{{ selectedAccount.renewalDate | date : "dd/MM/yyyy"}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>