// The tooltip section is the top level and contains a number of tooltips
export interface TooltipSection {
  id: number;
  shortName: string;
  longName: string;
}

// A tooltip contain one or more pages
export interface Tooltip {
  id: number;
  sectionId: number;
  description: string;
  inactive: boolean;
  orderBy: number;
  pages: TooltipPage[];
}

export interface TooltipPage {
  orderBy: number;
  title: string;
  html: string;
}
