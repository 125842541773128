<div class="header page-container">
  <div class="row gx-0">
    <div class="col-md-8">
      <h2 class="mb-4">Admin</h2>
    </div>
    <div class="col-md-4 d-flex align-items-center justify-content-between">
      <div *isGranted="'VIEW_SEAT_BREAKDOWN'; forAccount: (selectedAccountId$ | async) ?? 0">
        <div
          *ngIf="seatsInOrg$ | async as seatsInOrg"
          class="d-flex justify-content-between h-100"
        >
          <div class="d-flex align-items-end pe-4">
            <div>
              <div class="d-flex">
                <h1 class="text-white highlight-numbers">
                  {{ seatsInOrg.seatsUsed }}
                </h1>
                <fa-icon
                  class="ps-2 cursor-pointer used-seats-info"
                  [icon]="questionIcon"
                  tooltip="Show seat usage breakdown"
                  (click)="openSeatUsageBreakdown()"
                ></fa-icon>
              </div>
              <p class="text-white m-0 text-no-wrap">Used seats</p>
            </div>
          </div>
          <div class="borderRight h-100"></div>
          <div class="d-flex align-items-end ps-4">
            <div>
              <h1 class="text-white highlight-numbers">
                {{ seatsInOrg.totalSeats }}
              </h1>
              <p class="text-white m-0 text-no-wrap">Total seats</p>
            </div>
          </div>
        </div>
      </div>
      <button *isGranted="'ADD_USER'; forAccount: (selectedAccountId$ | async) ?? 0" class="btn btn-orange" (click)="addUser()">ADD USER</button>
    </div>
  </div>
  <div class="row gx-0">
    <div class="col-md-8 d-flex">
      <h1>User Management</h1>
    </div>
    <div class="col-md-4">
      <div class="d-flex flex-row mt-4 position-relative">
        <app-btn-action
          [icon]="filter"
          [iconDimensions]="35"
          [secondary]="true"
          [light]="true"
          [style.width.px]="growthSearch.offsetHeight"
          [style.height.px]="growthSearch.offsetHeight"
          (click)="toggleShowFilter()"
        ></app-btn-action>
        <fa-icon
          *ngIf="!(filterClear$ | async)"
          [icon]="['fas', 'circle']"
          alt="Notifications Pending"
          class="nav-link text-secondary position-absolute circle"
        ></fa-icon>

        <div class="flex-fill ms-4" #growthSearch>
          <app-search-input
            #search
            class="search"
            [light]="true"
            [placeholder]="'Search'"
            (input)="currentSearch(currentSearchTerm)"
            [(ngModel)]="currentSearchTerm"
            [manualValue]="currentSearchTerm"
          ></app-search-input>
        </div>
      </div>
    </div>
  </div>
</div>
