import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { AppState } from 'src/app/+state/app.state';
import * as UserActions from '../../+state/user/user.actions';
import { AccountAdminManagedUsersDTO } from 'src/app/shared/models/account-admin-managed-users-DTO';
import { faCloseSVG } from 'src/icons';
import { AddNoteToAccountUser } from 'src/app/+state/account-admin/account-admin.actions';
import { AddNoteToAccountUserReq } from 'src/app/shared/models/add-note-to-account-user';

@Component({
  selector: 'app-add-note-modal',
  templateUrl: './add-note-modal.component.html',
  styleUrls: ['./add-note-modal.component.scss']
})
export class AddNoteModalComponent implements OnInit, OnDestroy {
  @Input() user?: AccountAdminManagedUsersDTO;

  destroyed$ = new Subject<boolean>();
  closeIcon = faCloseSVG;
  buttonDisabled: boolean = true;

  addNoteForm = new UntypedFormGroup({
    note: new UntypedFormControl(''),
    userAK: new UntypedFormControl(''),
  });

  constructor(private modal: BsModalRef, private store: Store<AppState>) { }


  ngOnInit(): void {
    this.store.dispatch(
      UserActions.SetSelectedUser({ id: this.user?.ak! })
    );

    this.addNoteForm.setValue({
      note: this.user?.notes,
      userAK: this.user?.ak,
    });
  }

  close() {
    this.modal.hide();
  }


  saveChanges() {
    const formValue: AddNoteToAccountUserReq = {
      userAk: this.user?.ak || 0,
      note: this.addNoteForm.controls.note.value,
    };

    this.store.dispatch(
      AddNoteToAccountUser.Request({ note: formValue })
    );
    this.close();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }

}
