import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Subject, combineLatest, interval, takeUntil } from 'rxjs';
import { AppState } from 'src/app/+state/app.state';
import { selectCurrentUser } from 'src/app/+state/user/user.selector';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AccountLockedInfoModalComponent } from '../account-locked-info-modal/account-locked-info-modal.component';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-locked-user-holding',
  templateUrl: './locked-user-holding.component.html',
  styleUrls: ['./locked-user-holding.component.scss'],
})
export class LockedUserHoldingComponent implements OnInit, OnDestroy {
  currentUser$ = this.store.select(selectCurrentUser);

  destroyed$ = new Subject<boolean>();

  timeLeft: number = 0;

  daysLeft: number = 0;
  hoursLeft: number = 0;
  minsLeft: number = 0;
  secsLeft: number = 0;

  readonly dayMillis = 86400000;
  readonly hourMillis = 3600000;
  readonly minMillis = 60000;
  readonly secMillis = 1000;

  circleQuestionIcon = faCircleQuestion;

  constructor(
    private store: Store<AppState>,
    private oidcSecurityService: OidcSecurityService,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    this.currentUser$.pipe(takeUntil(this.destroyed$)).subscribe((user) => {
      if (user && user.unlockDate) {
        const nowTime = Date.now();
        const unlockDateTime = user.unlockDate.getTime();

        this.timeLeft = unlockDateTime - nowTime;

        this.updateIndividualTimeLeft();
      }
    });

    interval(1000)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((_) => {
        if (this.timeLeft) {
          this.timeLeft -= 1000;

          this.updateIndividualTimeLeft();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }

  logout() {
    localStorage.clear();
    this.oidcSecurityService.logoff().subscribe(() => {});
  }

  openModal() {
    this.modalService.show(
      AccountLockedInfoModalComponent, {
        class: 'modal-dialog-centered modal-md',
      }
    );
  }

  private updateIndividualTimeLeft() {
    this.daysLeft = Math.floor(this.timeLeft / this.dayMillis);

    let remainder = this.timeLeft % this.dayMillis;

    this.hoursLeft = Math.floor(remainder / this.hourMillis);

    remainder = remainder % this.hourMillis;

    this.minsLeft = Math.floor(remainder / this.minMillis);

    remainder = remainder % this.minMillis;

    this.secsLeft = Math.floor(remainder / this.secMillis);
  }
}
