import { createAction, props } from '@ngrx/store';
import {
  MyWorkOns,
  GrowthTag,
  WorkOn,
  WorkOnHistory,
  newWorkOnOrder,
  WorkOnType,
  GrowthSortBy,
  NudgesDTO,
} from 'src/app/shared/models';
import {
  Playbook,
  PlaybookExercise,
} from 'src/app/shared/models/playbooks.interface';
import { DashboardNudgeDTO } from '../../shared/models/nudge-dto.interface';
import { WorkOnTheme } from '../../shared/models/work-on-theme.interface';
import { GrowthType } from './growth.reducer';

export namespace GetWorkOns {
  export const Request = createAction('[Growth] Get Work Ons Request');

  export const Success = createAction(
    '[Growth] Get Work Ons Success',
    props<{
      workOns: WorkOn[];
      playbooks: Playbook[];
      tags: GrowthTag[];
      workOnHistory: WorkOnHistory[];
      currentWorkOnId: number;
      suggestedWorkOnIds: number[];
    }>()
  );

  export const Fail = createAction(
    '[Growth] Get Work Ons Fail',
    props<{ error: any }>()
  );
}

export namespace SetWorkOnViewed {
  export const Request = createAction(
    '[Growth] Set Work On Viewed Request',
    props<{ workOnId?: number }>()
  );

  export const Success = createAction('[Growth] Set Work On Viewed Success');

  export const Fail = createAction(
    '[Growth] Set Work On Viewed Fail',
    props<{ error: any }>()
  );
}

export const SetSelectedWorkOn = createAction(
  '[Growth] Set Selected Work On',
  props<{ workOnId: number }>()
);

export const SetSelectedWorkOnSuccess = createAction(
  '[Growth] Set Selected Work On Success'
);

export const SetSelectedWorkOnFail = createAction(
  '[Growth] Set Selected Work On Fail',
  props<{ error: any }>()
);

export const ClearSelectedWorkOn = createAction(
  '[Growth] Clear Selected Work On'
);

export const ToggleGrowthListShowingOn = createAction(
  '[Growth] Toggle Growth List Showing On'
);

export const ToggleGrowthListShowingOff = createAction(
  '[Growth] Toggle Growth List Showing Off'
);

export const SetSelectedGrowth = createAction(
  '[Growth] Set Selected Growth',
  props<{
    growthId?: number;
    growthType: GrowthType;
    playbookId?: number;
    engagementResourceId?: number;
  }>()
);

export const SetSelectedGrowthFail = createAction(
  '[Growth] Set Selected Growth Fail',
  props<{ error: any }>()
);

export const SetSelectedGrowthType = createAction(
  '[Growth] Set Selected Growth Type',
  props<{ growthType: GrowthType }>()
);

export const ClearSelectedGrowth = createAction(
  '[Growth] Clear Selected Growth'
);

export namespace SaveWorkOn {
  export const Request = createAction(
    '[Growth] Save Work On Request',
    props<{ workOn: WorkOn }>()
  );

  export const Success = createAction(
    '[Growth] Save Work On Success',
    props<{ newWorkOn: WorkOn }>()
  );

  export const Fail = createAction(
    '[Growth] Save Work On Fail',
    props<{ error: any }>()
  );

  export const Edit = createAction(
    '[Growth] Edit Work On',
    props<{ workOnId?: number }>()
  );
}

export const ClearSaveWorkOnResult = createAction(
  '[Growth] Clear Save Work On Result'
);

export namespace GetMyWorkOnHistory {
  export const Request = createAction('[Growth] Get work on History ');
  export const Success = createAction(
    '[Growth] Get Work on History Success',
    props<{ MyWorkOns: MyWorkOns }>()
  );
  export const Fail = createAction(
    '[Growth] Get Work on History Fail',
    props<{ error: any }>()
  );
}

export namespace SavePlaybook {
  export const Request = createAction(
    '[Growth] Save Playbook Request',
    props<{ playbook: Playbook }>()
  );

  export const Success = createAction(
    '[Growth] Save Playbook Success',
    props<{ savedPlaybook: Playbook }>()
  );

  export const Fail = createAction(
    '[Growth] Save Playbook Fail',
    props<{ error: any }>()
  );
}

export namespace SavePlaybookExercise {
  export const Request = createAction(
    '[Growth] Save Playbook Exercise Request',
    props<{ exercise: PlaybookExercise }>()
  );

  export const Success = createAction(
    '[Growth] Save Playbook Exercise Success',
    props<{ savedExercise: PlaybookExercise }>()
  );

  export const Fail = createAction(
    '[Growth] Save Playbook Exercise Fail',
    props<{ error: any }>()
  );

  export const Edit = createAction(
    '[Growth] Edit Playbook Exercise',
    props<{
      playbookId?: number;
      exerciseId?: number;
    }>()
  );
}

export const SetSelectedHeading = createAction(
  '[Growth] Set Selected Heading',
  props<{ heading: string }>()
);

export namespace AddWorkOnToHistory {
  export const Request = createAction(
    '[Growth] Add Work On To History Request',
    props<{ workOnId: number }>()
  );

  export const Success = createAction(
    '[Growth] Add Work On To History Success',
    props<{ workOnHistoryEntry: WorkOnHistory }>()
  );

  export const Fail = createAction(
    '[Growth] Add Work On To History Fail',
    props<{ error: any }>()
  );
}

export namespace FilterTags {
  export const Set = createAction(
    '[Growth] Set Tags To Filter',
    props<{ tagId: number }>()
  );

  export const SetMultiple = createAction(
    '[Growth] Set Multiple Tags To Filter',
    props<{ tagIds: number[] }>()
  );

  export const Clear = createAction('[Growth] Clear All Tags');

  export const ClearIds = createAction('[Growth] Clear All Tag Ids');
}

export namespace UpdateUpcomingWorkOnsOrder {
  export const Request = createAction(
    '[Growth] Update Upcoming Work Ons Order Request',
    props<{ newOrder: newWorkOnOrder[] }>()
  );

  export const Success = createAction(
    '[Growth] Update Upcoming Work Ons Order Success',
    props<{ upcomingWorkOns: WorkOnHistory[] }>()
  );

  export const Fail = createAction(
    '[Growth] Update Upcoming Work Ons Order Fail',
    props<{ error: any }>()
  );
}
export namespace FilterSortBy {
  export const Set = createAction(
    '[Growth] Set Sort By Method Filter',
    props<{ sortBy: GrowthSortBy }>()
  );

  export const Clear = createAction('[Growth] Clear Sort By Filter');
}

export namespace FilterWorkOnType {
  export const Set = createAction(
    '[Growth] Set Work On Type Filter',
    props<{ workOnType: WorkOnType }>()
  );

  export const Clear = createAction('[Growth] Clear Work On Type Filter ');
}

export const ClearAllFilters = createAction(
  '[Growth] Clear All Growth Filters'
);

export const SetWorkOnCarousels = createAction(
  '[Growth] Set WorkOn Carousels',
  props<{ growthTags: GrowthTag[]; workOns: WorkOn[] }>()
);

export const SetCurrentWorkOnSearchTerm = createAction(
  '[Growth] Set Current Work On Search Term',
  props<{ currentSearchTerm: string }>()
);

export namespace DeleteUpcomingWorkOn {
  export const Request = createAction(
    '[Growth] Delete Upcoming Work on Request',
    props<{ workOnId: number }>()
  );
  export const Success = createAction(
    '[Growth] Delete Upcoming Work on Success',
    props<{ historyId: number }>()
  );
  export const Fail = createAction(
    '[Growth] Delete Upcoming Work on Fail',
    props<{ error: any }>()
  );
}

export namespace GetCurrentNudge {
  export const Request = createAction(
    '[Growth] Get Current Nudge Request'
  );
  export const Success = createAction(
    '[Growth] Get Current Nudge Success',
    props<{ nudge: DashboardNudgeDTO; }>()
  );
  export const Fail = createAction(
    '[Growth] Get Current Nudge Fail',
    props<{ error: any; }>()
  );
}

export namespace GetTopOrganisationNudges {
  export const Request = createAction(
    '[Growth] Get Top Organisation Nudges Request'
  );
  export const Success = createAction(
    '[Growth] Get Top Organisation Nudges Success',
    props<{ nudges: DashboardNudgeDTO[]; }>()
  );
  export const Fail = createAction(
    '[Growth] Get Top Organisation Nudges Fail',
    props<{ error: any; }>()
  );
}

export namespace GetTopWorkOnThemes {
  export const Request = createAction(
    '[Growth] Get Top Work On Themes Request'
  );
  export const Success = createAction(
    '[Growth] Get Top Work On Themes Success',
    props<{ themes: WorkOnTheme[]; }>()
  );
  export const Fail = createAction(
    '[Growth] Get Top Work On Themes Fail',
    props<{ error: any; }>()
  );
}

export namespace ReflectOnNudge {
  export const Request = createAction(
    '[Growth] Reflect on nudge Request', props<{ nudge: DashboardNudgeDTO}>()
  );
  export const Success = createAction(
    '[Growth] Reflect on nudge Success',
    props<{ nudge: DashboardNudgeDTO; }>()
  );
  export const Fail = createAction(
    '[Growth] Reflect on nudge Fail',
    props<{ error: any; }>()
  );
}


export namespace GetPreviousNudge {
  export const Request = createAction(
    '[Growth] Get previous nudge Request'
  );
  export const Success = createAction(
    '[Growth] Get previous nudge Success',
    props<{ nudge: DashboardNudgeDTO; }>()
  );
  export const Fail = createAction(
    '[Growth] Get previous nudge Fail',
    props<{ error: any; }>()
  );
}

export namespace ReflectOnPreviousNudge {
  export const Request = createAction(
    '[Growth] Reflect on previous nudge Request',
    props<{nudge: DashboardNudgeDTO}>()
  );
  export const Success = createAction(
    '[Growth] Reflect on previous nudge Success',
    props<{ nudge: DashboardNudgeDTO; }>()
  );
  export const Fail = createAction(
    '[Growth] Reflect on previous nudge Fail',
    props<{ error: any; }>()
  );
}
