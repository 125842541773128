<div class="modal-header justify-content-end">
  <fa-icon
    class="cursor-pointer fa-lg"
    [icon]="closeIcon"
    (click)="close()"
  ></fa-icon>
</div>
<div class="modal-body">
  <h2 class="mb-4">Edit Unlock Date</h2>
  <h3 class="mb-4">Set the unlock date for {{ userName }}</h3>

  <div class="unlock-date col-12 mb-4">
    <label class="form-label">Unlock Date</label>
    <input
      [(ngModel)]="unlockDate"
      [ngModelOptions]="{ standalone: true }"
      type="text"
      placeholder="Select unlock date"
      class="form-control"
      [class.ng-touched]="showErrors"
      [class.ng-invalid]="showErrors"
      bsDatepicker
      [minDate]="tomorrow"
      [container]="'.unlock-date'"
      [bsConfig]="{ showWeekNumbers: false, dateInputFormat: 'DD/MM/YYYY' }"
    />
    <div *ngIf="showErrors" class="form-error">
      Please enter a date in the future
    </div>
  </div>
  <button class="btn btn-purple w-100" (click)="saveChanges()">
    Save Changes
  </button>
</div>
<div class="modal-bottom-border"></div>
