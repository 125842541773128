import { TeamMechanicType } from './enums/team-mechanic-types.enum';
import { TeamSpotlightType } from './enums/team-spotlight-type.enum';
import { PortalNotification } from './notifications.interface';
import { User } from './user.interface';

export interface Team {
  uniqueTeamNameError: any;
  id: number;
  name: string;
  ownerId: number;
  mindflickAccountId: number;
  ownerName?: string;
  memberIds: number[];
  invitedMemberIds: number[];
  incomingRequestMemberIds: number[];
  //members derived in the selector
  members: User[];
  //invited members derived in the selector
  invitedMembers: User[];
  mechanics: TeamMechanic[];
  teamSpotlights: TeamSpotlight[];
  exercises: TeamExercise[];
  flex: string;
  cope: string;
  flexInSpotlight?: string;
  copeInSpotlight?: string;
  flexDescription?: string;
  copeDescription?: string;
  connectionDensity: number;
  isPrivate: boolean;
  userIsMember: boolean;
  teamHasInvitedUser: boolean;
  userHasSentTeamRequest: boolean;
  teamAdminIds?: number[];
  photoLastModified?: string;
  createdDate: Date;
  teamImageId?: number;
}

export interface TeamMapCoordinates {
  x: number;
  y: number;
}

export interface TeamSpotlight {
  orderBy: number;
  description: string;
  spotlightType: TeamSpotlightType;
  title: string;
}

export interface TeamMechanic {
  id: number;
  name: string;
  description: string;
  type: TeamMechanicType;
}

export interface TeamExercise {
  teamId: number;
  exerciseId: number;
  addedDate: Date;
}

export interface CreateTeamModel {
  teamId: number;
  name: string;
  membersToAdd: number[];
  mechanics: TeamMechanic[];
  isPrivate?: boolean;
  adminMembers: number[];
  photoUrl: string;
  teamImageId: number;
  croppedImage?: string;
  isGhost?: boolean
}

export interface SaveTeamDetailsModel {
  teamId: number;
  name: string;
  isPrivate?: boolean;
  ownerIsGhost? : boolean;
}

export interface PayloadModel {
  teamId: number;
  memberToAdd: number;
}
export interface UpsertTeamExerciseModel {
  teamId: number;
  exerciseId: number;
}

export interface ConnectionDTO {
  id?: number;
  title: string;
  description: string;
  isTopTip?: boolean;
}
