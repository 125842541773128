import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, zip } from 'rxjs';
import {
  MyWorkOns,
  GrowthTag,
  WorkOn,
  WorkOnHistory,
  newWorkOnOrder,
} from '../models';
import { AppState } from '../../+state/app.state';
import { Playbook } from '../models/playbooks.interface';
import { GrowthType } from '../../+state/growth/growth.reducer';
import { DashboardNudgeDTO } from '../models/nudge-dto.interface';
import { WorkOnTheme } from '../models/work-on-theme.interface';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GrowthService {
  growthUrl = '';

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private store: Store<AppState>
  ) {
    this.growthUrl = `${baseUrl}growth`;
  }

  getWorkOns(): Observable<GetWorkOnsResponse> {
    return this.http.get<GetWorkOnsResponse>(`${this.growthUrl}/work-ons`);
  }
  getRecentWorkOns(): Observable<WorkOn[]> {
    return this.http.get<WorkOn[]>(`${this.growthUrl}/work-ons/recent`);
  }
  setWorkOnViewed(workOnId?: number) {
    return this.http.post(`${this.growthUrl}/work-ons/viewed`, workOnId);
  }
  getWorkOnHistory(): Observable<MyWorkOns> {
    return this.http.get<MyWorkOns>(`${this.growthUrl}/work-ons/history`);
  }

  getAllTags() {
    return this.http.get<GrowthTag[]>(`${this.growthUrl}/tags`);
  }

  getPlaybooks() {
    return this.http.get<Playbook[]>(`${this.growthUrl}/playbooks`);
  }

  addWorkOnToHistory(workOnId: number) {
    return this.http.post<WorkOnHistory>(
      `${this.growthUrl}/work-ons/history/add`,
      workOnId
    );
  }

  setSelectedGrowth(
    growthType: GrowthType,
    growthId?: number,
    playbookId?: number,
    engagementReasourceId?: number
  ) {
    return (
      zip(
        of(localStorage.setItem(environment.localStoragePrefix + 'growth-id', JSON.stringify(growthId))),
        of(localStorage.setItem(environment.localStoragePrefix + 'growth-type', JSON.stringify(growthType))),
        of(localStorage.setItem(environment.localStoragePrefix + 'playbook-id', JSON.stringify(playbookId)))
      ),
      of(
        localStorage.setItem(
          environment.localStoragePrefix + 'engagementResource-id',
          JSON.stringify(engagementReasourceId)
        )
      )
    );
  }

  getLocalGrowth() {
    var growthType = parseInt(localStorage.getItem(environment.localStoragePrefix + 'growth-type')!);

    var growthId = parseInt(localStorage.getItem(environment.localStoragePrefix + 'growth-id')!);
    var playbookId =
      growthType == 0
        ? undefined
        : parseInt(localStorage.getItem(environment.localStoragePrefix + 'playbook-id')!);

    return of({ growthId, growthType, playbookId });
  }

  updateUpcomingWorkOnsOrder(newOrder: newWorkOnOrder[]) {
    return this.http.post<WorkOnHistory[]>(
      `${this.growthUrl}/work-ons/history/rearrange`,
      newOrder
    );
  }

  deleteUncompletedWorkOn(workOnId: number) {
    return this.http.delete<number>(
      `${this.growthUrl}/work-ons/history/delete/${workOnId}`
    );
  }


  getCurrentNudge() {
    return this.http.get<DashboardNudgeDTO>(`${this.growthUrl}/nudge/current`);
  }
  getTopOrganisationNudges() {
    return this.http.get<DashboardNudgeDTO[]>(`${this.growthUrl}/nudge/top-three`);
  }
  getTopWorkOnThemes() {
    return this.http.get<WorkOnTheme[]>(`${this.growthUrl}/workon/themes`);
  }

  reflectOnNudge(nudge: DashboardNudgeDTO) {
    return this.http.post<DashboardNudgeDTO>(`${this.growthUrl}/nudge/current`, nudge)
  }


  getPreviousNudge() {
    return this.http.get<DashboardNudgeDTO>(`${this.growthUrl}/nudge/previous`);
  }
  reflectOnPreviousNudge(nudge: DashboardNudgeDTO) {
    return this.http.post<DashboardNudgeDTO>(`${this.growthUrl}/nudge/previous`, nudge);
  }

}

export interface GetWorkOnsResponse {
  workOns: WorkOn[];
  playbooks: Playbook[];
  tags: GrowthTag[];
  workOnHistory: WorkOnHistory[];
  currentWorkOnId: number;
  suggestedWorkOnIds: number[];
}
