import { Component, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import {
  faDownload,
  faInfoCircle,
  faPlus,
  faUpload,
} from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject, combineLatest } from 'rxjs';
import { take, takeUntil, withLatestFrom } from 'rxjs/operators';
import {
  AddUsers,
  SetAddUsersSuccess,
} from 'src/app/+state/account-admin/account-admin.actions';
import {
  selectAddUserResultMessage,
  selectAddUserSuccess,
  selectIsAddingUsers,
  selectSeatsInAccData,
  selectSelectedUserManagementAccountId,
} from 'src/app/+state/account-admin/account-admin.selector';
import { AppState } from 'src/app/+state/app.state';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { faBulkImportSVG, faCloseSVG } from 'src/icons';
import { BulkInviteUsersComponent } from 'src/app/super-admin/bulk-invite-users/bulk-invite-users.component';
import { SeatsInAcc } from 'src/app/shared/models/account-admin-managed-users-DTO';
import { environment } from 'src/environments/environment';
import { DisplaySnackbarAlert } from 'src/app/+state/layout/actions/layout.actions';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { smoothHeight } from 'src/animations';

@Component({
  selector: 'app-add-user-modal',
  templateUrl: './add-user-modal.component.html',
  styleUrls: ['./add-user-modal.component.scss'],
  animations: [
    trigger('addUserShowConfirm', [
      state(
        'addUser',
        style({
          height: 'auto',
          opacity: 1,
          'overflow-y': 'inherit',
        })
      ),
      state(
        'showConfirm',
        style({
          height: '290.8px',
          opacity: 1,
          'overflow-y': 'inherit',
        })
      ),
      transition('addUser <=> showConfirm', animate('300ms 0ms ease-out')),
    ]),
  ],
})
export class AddUserModalComponent implements OnInit {
  destroyed$ = new Subject<boolean>();
  seatsInAccData$ = this.store.select(selectSeatsInAccData);
  addUsersSuccess$ = this.store.select(selectAddUserSuccess);
  addUsersResultMessage$ = this.store.select(selectAddUserResultMessage);
  isAddingUsers$ = this.store.select(selectIsAddingUsers);
  selectedAccountId$ = this.store.select(selectSelectedUserManagementAccountId);

  addPersonForm = new UntypedFormGroup({
    firstName: new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(2),
    ]),
    lastName: new UntypedFormControl('', [Validators.required]),
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    department: new UntypedFormControl(''),
  });

  unlockDate?: Date;

  questionIcon = faCircleQuestion;
  download = faDownload;
  upload = faUpload;
  plus = faPlus;
  close = faCloseSVG;
  bulkImport = faBulkImportSVG;
  info = faInfoCircle;

  seatsInAccData?: SeatsInAcc;
  hasMoreThan10PercentSeatsRemaining: boolean = false;
  isOverSeatsByLessThan10Percent = false;
  isOverSeatsByMoreThan10Percent = false;

  isHidden = false;
  emailBlurred = false;

  tomorrow: Date = new Date();

  showNoLockConfirmation = false;
  userToSave?: AddUserModel;

  public downloadCsvTemplate =
    environment.platformResourceStorageUrl + 'MindflickBulkUserUpload.xlsx';

  constructor(
    private modalRef: BsModalRef,
    private store: Store<AppState>,
    private modalService: BsModalService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.tomorrow.setDate(this.tomorrow.getDate() + 1);

    this.seatsInAccData$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((seatsData) => {
        if (seatsData) {
          this.seatsInAccData = seatsData;

          const usedSeatsPercent =
            (seatsData.seatsUsed + 1) / seatsData.totalSeats;

          this.hasMoreThan10PercentSeatsRemaining = usedSeatsPercent < 0.9;
          this.isOverSeatsByLessThan10Percent =
            !this.hasMoreThan10PercentSeatsRemaining &&
            usedSeatsPercent > 1.0 &&
            usedSeatsPercent < 1.1;
          this.isOverSeatsByMoreThan10Percent =
            !this.hasMoreThan10PercentSeatsRemaining && usedSeatsPercent >= 1.1;
        }
      });

    this.addUsersSuccess$
      .pipe(
        withLatestFrom(this.addUsersResultMessage$),
        takeUntil(this.destroyed$)
      )
      .subscribe(([success, message]) => {
        if (success === false) {
          this.showTooManyUsersModal(
            message ? message : 'Could not add user(s)'
          );
        }

        if (success) {
          this.store.dispatch(
            SetAddUsersSuccess({ success: undefined, message: undefined })
          );
          this.resetForm();
          this.closeModal(false);
        }
      });
  }

  addUser() {
    this.selectedAccountId$
      .pipe(take(1))
      .subscribe(accountId => {
        if (!accountId) {
          this.store.dispatch(DisplaySnackbarAlert.SetAlert({ alert: { alertType: 'danger', message: 'No account selected. Please go back and select an account before trying to add users.' }}));
          return;
        }
        
        if (this.isOverSeatsByLessThan10Percent) return;

        if (this.addPersonForm.invalid) {
          this.addPersonForm.controls.firstName.markAsTouched();
          this.addPersonForm.controls.lastName.markAsTouched();
          this.addPersonForm.controls.email.markAsTouched();
    
          this.emailBlurred = true;
          this.displayCouldNotAddUser();
          return;
        }
    
        const formValue: AddUserModel = {
          ...this.addPersonForm.value,
        };
    
        if (this.unlockDate) {
          this.unlockDate.setHours(0, 0, 0, 0);
    
          formValue.unlockDate = new Date(this.unlockDate);
    
          this.store.dispatch(AddUsers.Request({ users: [formValue], accountId }));
        } else {
          this.showNoLockConfirmation = true;
          this.userToSave = formValue;
        }
      });
    
  }

  confirmNoLock() {
    this.selectedAccountId$
      .pipe(take(1))
      .subscribe(accountId => {
        if (this.userToSave) {
          if (!accountId) {
            this.store.dispatch(DisplaySnackbarAlert.SetAlert({ alert: { alertType: 'danger', message: 'No account selected. Please go back and select an account before trying to add users.' }}));
            return;
          }

          this.store.dispatch(AddUsers.Request({ users: [this.userToSave], accountId }));
        }
        
      });
  }

  displayCouldNotAddUser() {
    this.store.dispatch(
      DisplaySnackbarAlert.SetAlert({
        alert: {
          alertType: 'warning',
          messageHeader: 'Could not add user',
          message: `Please check the form to make sure all details have been entered correctly`,
        },
      })
    );
  }

  closeModal(displayWarning: boolean) {
    if (displayWarning) {
      this.isHidden = true;

      this.dialogService
        .confirm(
          'Are you sure you want to exit without saving changes?',
          'Are you sure?',
          true
        )
        .pipe(take(1))
        .subscribe((result) => {
          if (result) {
            this.confirmClose();
          } else {
            this.isHidden = false;
          }
        });
    } else {
      this.confirmClose();
    }
  }

  confirmClose() {
    this.modalRef.hide();
  }

  resetForm() {
    this.addPersonForm.reset();

    this.unlockDate = undefined;

    this.emailBlurred = false;

    this.userToSave = undefined;

    this.addPersonForm.updateValueAndValidity();
  }

  openBulkInviteModal() {
    this.modalService.show(BulkInviteUsersComponent, {
      initialState: {
        isAccountAdminModalVersion: true,
      },
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered',
    });

    this.confirmClose();
  }

  onEmailBlur() {
    this.emailBlurred = true;
  }

  private showTooManyUsersModal(message: string) {
    this.dialogService
      .confirm(message, 'User(s) not added')
      .pipe(take(1))
      .subscribe((_) => {
        this.store.dispatch(
          SetAddUsersSuccess({ success: undefined, message: undefined })
        );
      });
  }
}

export interface AddUserModel {
  firstName: string;
  lastName: string;
  email: string;
  department: string;
  unlockDate?: Date;
}
