<ng-container
  *ngIf="(!(userIsLocked$ | async) && !(requiresExternalAuthentication$ | async)) || isOnSurvey; else lockedUserHoldingScreen"
>
  <div
    *ngIf="isDashboardLoading$ | async"
    [@outAnimation]
    class="position-absolute bg-dark d-flex justify-content-center align-items-center h-100 w-100 zi-max"
  >
    <img class="loader" src="./assets/images/loaders/Loader_black.gif" />
  </div>
  <div class="app-container">
    <top-menu *ngIf="(showTitleBar$ | async) && (currentUser$ | async)" ></top-menu>
    <div
      #mainPage
      class="main-page flex-fill d-flex flex-column justify-content-between"
    >
      <div class="flex-fill">
        <!--Place popouts first so the menu items appear over it-->
        <app-growth-details-popout
          *ngIf="showGrowthDetails$ | async"
          [carouselHeading]="(growthHeading$ | async)!"
        ></app-growth-details-popout>
        <app-data-protection
          *ngIf="showDataPolicyShowing$ | async"
        ></app-data-protection>
        <app-terms-and-conditions
          *ngIf="showTermsPolicyShowing$ | async"
        ></app-terms-and-conditions>
        <app-privacy-policy
          *ngIf="showPrivacyPolicyShowing$ | async"
        ></app-privacy-policy>
        <app-edit-user-popout *ngIf="adminSelectedUserAk$ | async"></app-edit-user-popout>
        <app-mindflick-organisation-details-popout *ngIf="selecteMindflickOrganisationId$ | async"></app-mindflick-organisation-details-popout>

        <app-tooltip-menu *ngIf="isShowingTooltips$ | async"></app-tooltip-menu>
        <app-notifications-dropdown
          class="position-absolute w-100"
          *ngIf="showNotifications$ | async"
        ></app-notifications-dropdown>

        <app-nudge-reflection-popup
          *ngIf="showNudgeReflection$ | async"
          class="position-absolute w-100"
        ></app-nudge-reflection-popup>

        <app-account-admin-dropdown
          *ngIf="showAccountAdminListToggle$ | async"
        ></app-account-admin-dropdown>
        <app-super-admin-dropdown
          *ngIf="showSuperAdminListToggle$ | async"
        ></app-super-admin-dropdown>
        <app-growth-dropdown *ngIf="showGrowthList"></app-growth-dropdown>
        <app-cookie-banner *ngIf="showCookieBanner"></app-cookie-banner>
        <app-alert-snackbar
          *ngIf="isSnackbarAlertShowing$ | async"
        ></app-alert-snackbar>
        <div *ngIf="showAppBanner" class="app-backdrop"></div>
        <div *ngIf="showAppBanner" class="app-banner px-2 py-4">
          <div class="d-flex flex-column text-white">
            <h2 class="mb-3">Browsing from a mobile device?</h2>
            <h3 class="mb-3">For a better experience try the Mindflick app.</h3>
            <button class="btn btn-purple mb-3" (click)="goToAppStore()">
              {{ isiPhone ? "App Store" : "Play Store" }}
            </button>
            <button class="btn btn-secondary-light" (click)="closeAppBanner()">
              Cancel
            </button>
          </div>
        </div>

        <app-pulse-survey *ngIf="isPulseSurveyDue$ | async"></app-pulse-survey>

        <app-champion-charter-sign
          *ngIf="showChampionCharter$ | async"
        ></app-champion-charter-sign>
        <router-outlet></router-outlet>
      </div>

      <app-app-footer *ngIf="showTitleBar$ | async"></app-app-footer>
    </div>
  </div>
</ng-container>
<ng-template #lockedUserHoldingScreen>
  <div class="h-100 w-100">
    <app-locked-user-holding *ngIf="!(requiresExternalAuthentication$ | async)"></app-locked-user-holding>
    <app-locked-requires-external-auth *ngIf="requiresExternalAuthentication$ | async"></app-locked-requires-external-auth>
  </div>
</ng-template>

<ng-template #uploadImageErrorTemplate>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Failed to upload image</h4>
    <button
      type="button"
      class="btn btn-secondary btn-action pull-right"
      aria-label="Close"
      (click)="uploadImageErrorModalRef?.hide()"
    >
      <fa-icon class="fa-lg" [icon]="close"></fa-icon>
    </button>
  </div>
  <div class="modal-body">
    {{ uploadImageError$ | async }}
  </div>
</ng-template>
