import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BsModalService } from 'ngx-bootstrap/modal';
import { merge, of } from 'rxjs';
import { catchError, map, mergeMap, take, withLatestFrom } from 'rxjs/operators';
import { AddUserSuccessModalComponent } from '../../shared/components/add-user-success-modal/add-user-success-modal.component';
import { AccountAdminService } from '../../shared/services/account-admin.service';
import * as LayoutActions from '../layout/actions/layout.actions';
import * as AccountAdminActions from './account-admin.actions';
import { AddDeactivatedUsersModalComponent } from '../../shared/components/add-deactivated-users-modal/add-deactivated-users-modal.component';
import { AddUserModalComponent } from '../../admin/add-user-modal/add-user-modal.component';
import { OrganisationAdminService } from 'src/app/shared/services/organisation-admin.service';
import { RbacService } from 'src/app/shared/services/rbac.service';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { selectCurrentUser } from '../user/user.selector';

@Injectable()
export class AccountAdminEffects {
  constructor(
    private actions$: Actions,
    private accountAdminService: AccountAdminService,
    private modalService: BsModalService,
    private organisationAdminService: OrganisationAdminService,
    private rbacService: RbacService,
    private store: Store<AppState>
  ) {}

  GetAccountAdminManagedUsersData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountAdminActions.GetManagedUsersForAccountAdmin.Request),
      mergeMap((action) =>
        this.accountAdminService.getAccountAdminUsers(action.accountId).pipe(
          map((resp) =>
            AccountAdminActions.GetManagedUsersForAccountAdmin.Success({
              users: resp,
            })
          ),
          catchError((error) =>
            of(
              AccountAdminActions.GetManagedUsersForAccountAdmin.Fail({ error })
            )
          )
        )
      )
    )
  );

  getMindflickAccountSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountAdminActions.GetMindflickAccountSettings.Request),
      mergeMap((action) =>
        this.accountAdminService.getMindflickAccountSettings(action.accId).pipe(
          map((resp) =>
            AccountAdminActions.GetMindflickAccountSettings.Success({ resp })
          ),
          catchError((error) =>
            of(AccountAdminActions.GetMindflickAccountSettings.Fail({ error }))
          )
        )
      )
    )
  );

  setMindflickAccountSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountAdminActions.SetMindflickAccountSettings.Request),
      mergeMap((action) =>
        this.accountAdminService
          .setMindflickAccountSettings(action.settings)
          .pipe(
            mergeMap((resp) => [
              AccountAdminActions.SetMindflickAccountSettings.Success({
                settings: action.settings,
              }),
              LayoutActions.DisplaySnackbarAlert.SetAlert({
                alert: {
                  alertType: "success",
                  messageHeader: "Success!",
                  message: `Your settings have been saved.'`,
                },
              }),
            ]),
            catchError((error) =>
              of(
                AccountAdminActions.SetMindflickAccountSettings.Fail({ error })
              )
            )
          )
      )
    )
  );

  addUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountAdminActions.AddUsers.Request),
      mergeMap((action) =>
        this.accountAdminService.addUsers(action.users, action.accountId).pipe(
          mergeMap((resp) => {
            if (resp.success && resp.deactivatedUsers.length > 0) {
              var modalRef = this.modalService.show(
                AddDeactivatedUsersModalComponent,
                {
                  initialState: {
                    deactivatedUsers: resp.deactivatedUsers,
                    addedUserCount: resp.addedUsers.length,
                    usersNotAdded: resp.usersNotAdded,
                    unlockDate: action.users[0].unlockDate,
                  },
                  class: "modal-dialog-centered",
                }
              );

              modalRef.onHide?.pipe(take(1)).subscribe(() =>
                this.modalService.show(AddUserModalComponent, {
                  class: "modal-dialog-centered",
                  ignoreBackdropClick: true,
                })
              );
            }

            return resp.success
              ? [
                  AccountAdminActions.AddUsers.Success({ resp }),
                  this.addUserAlert(
                    resp.addedUsers.length,
                    resp.usersNotAdded.length,
                    resp.deactivatedUsers.length
                  ),
                ]
              : [
                  AccountAdminActions.SetAddUsersSuccess({
                    success: false,
                    message: resp.message,
                  }),
                  this.addUserAlert(0, action.users.length, 0),
                ];
          }),
          catchError((error) =>
            of(AccountAdminActions.AddUsers.Fail({ error }))
          )
        )
      )
    )
  );

  ResendAccountAdminSpotlightInvite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountAdminActions.AccountAdminResendSpotlightInvites.Request),
      mergeMap((action) =>
        this.accountAdminService
          .resendAccountAdminSpotlightInvite(action.userAks, action.accountId)
          .pipe(
            mergeMap((resp) => [
              AccountAdminActions.AccountAdminResendSpotlightInvites.Success(),
              LayoutActions.DisplaySnackbarAlert.SetAlert({
                alert: {
                  alertType: "success",
                  messageHeader: "Success!",
                  message: `You resent a spotlight invite to ${action.userAks.length} users.`,
                },
              }),
            ]),
            catchError((error) =>
              of(
                AccountAdminActions.AccountAdminResendSpotlightInvites.Fail({
                  error,
                })
              )
            )
          )
      )
    )
  );

  getSeatsInAcc$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountAdminActions.AccountAdminGetRemainingSeatsForAcc.Request),
      mergeMap((action) =>
        this.accountAdminService.getSeatsInAccData(action.accountId).pipe(
          map((resp) =>
            AccountAdminActions.AccountAdminGetRemainingSeatsForAcc.Success({
              resp,
            })
          )
        )
      )
    )
  );

  getEngagementResources$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountAdminActions.GetEngagementResources.Request),
      mergeMap((action) =>
        this.accountAdminService.getEngagementResources().pipe(
          map((resp) =>
            AccountAdminActions.GetEngagementResources.Success({
              categories: resp.map((x) => ({
                ...x,
                subCategoryIds: [],
                subCategories: x.subCategories.map((y) => ({
                  ...y,
                  engagementResourceIds: [],
                })),
              })),
            })
          ),
          catchError((error) =>
            of(AccountAdminActions.GetEngagementResources.Fail({ error }))
          )
        )
      )
    )
  );

  getPinnedEngagementResources$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountAdminActions.GetPinnedEngagementResources.Request),
      mergeMap((action) =>
        this.accountAdminService.getPinnedEngagementResources().pipe(
          map((resp) =>
            AccountAdminActions.GetPinnedEngagementResources.Success({
              pinnedEngagementResourceIds: resp,
            })
          ),
          catchError((error) =>
            of(AccountAdminActions.GetPinnedEngagementResources.Fail({ error }))
          )
        )
      )
    )
  );

  addPinnedEngagementResource$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountAdminActions.AddPinnedEngagementResource.Request),
      mergeMap((action) =>
        this.accountAdminService
          .addPinnedEngagementResource(action.engagementResourceId)
          .pipe(
            map((resp) =>
              AccountAdminActions.AddPinnedEngagementResource.Success({
                engagementResourceId: action.engagementResourceId,
              })
            ),
            catchError((error) =>
              of(
                AccountAdminActions.AddPinnedEngagementResource.Fail({ error })
              )
            )
          )
      )
    )
  );

  deletePinnedEngagementResource$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountAdminActions.DeletePinnedEngagementResource.Request),
      mergeMap((action) =>
        this.accountAdminService
          .deletePinnedEngagementResource(action.engagementResourceId)
          .pipe(
            map((resp) =>
              AccountAdminActions.DeletePinnedEngagementResource.Success({
                engagementResourceId: action.engagementResourceId,
              })
            ),
            catchError((error) =>
              of(
                AccountAdminActions.DeletePinnedEngagementResource.Fail({
                  error,
                })
              )
            )
          )
      )
    )
  );

  getHelpCenterCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountAdminActions.GetHelpCenterCategories.Request),
      mergeMap(() =>
        this.accountAdminService.getHelpCenterCategories().pipe(
          map((resp) =>
            AccountAdminActions.GetHelpCenterCategories.Success({
              categories: resp,
            })
          ),
          catchError((error) =>
            of(AccountAdminActions.GetHelpCenterCategories.Fail({ error }))
          )
        )
      )
    )
  );

  filterEngagementResourcesBySearchTerm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountAdminActions.FilterEngagementResourcesBySearchTerm.Request),
      mergeMap((action) =>
        this.accountAdminService
          .filterEngagementResourcesBySearchTerm(action.keywords)
          .pipe(
            map((resp) =>
              AccountAdminActions.FilterEngagementResourcesBySearchTerm.Success(
                { filteredIds: resp }
              )
            ),
            catchError((error) =>
              of(
                AccountAdminActions.FilterEngagementResourcesBySearchTerm.Fail({
                  error,
                })
              )
            )
          )
      )
    )
  );

  reactivateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountAdminActions.ReactivateUsers.Request),
      mergeMap((action) =>
        this.accountAdminService.reactivateUsers(action.userAKs, action.accountId).pipe(
          mergeMap((resp) => [
            AccountAdminActions.ReactivateUsers.Success({ users: resp }),
            LayoutActions.DisplaySnackbarAlert.SetAlert({
              alert: {
                alertType: "success",
                messageHeader: "Success!",
                message: `You reactivated ${action.userAKs.length} users.`,
              },
            }),
            AccountAdminActions.GetManagedUsersForAccountAdmin.Request({ accountId: 0})
          ]),
          catchError((error) =>
            of(AccountAdminActions.ReactivateUsers.Fail({ error }))
          )
        )
      )
    )
  );

  recreateUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountAdminActions.RecreateUsers.Request),
      mergeMap((action) =>
        this.accountAdminService.recreateUsers(action.model, action.accountId).pipe(
          mergeMap((resp) => [
            AccountAdminActions.RecreateUsers.Success({ users: resp }),
            LayoutActions.DisplaySnackbarAlert.SetAlert({
              alert: {
                alertType: "success",
                messageHeader: "Success!",
                message: `You added ${action.model.userAKs.length} user(s).`,
              },
            }), AccountAdminActions.GetManagedUsersForAccountAdmin.Request({ accountId: 0})
          ]),
          catchError((error) =>
            of(AccountAdminActions.RecreateUsers.Fail({ error }))
          )
        )
      )
    )
  );

  unlockUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountAdminActions.UnlockUser.Request),
      mergeMap((action) =>
        this.accountAdminService.unlockUser(action.userAk, action.accountId).pipe(
          mergeMap((resp) => [
            AccountAdminActions.UnlockUser.Success({ userAk: action.userAk }),
            LayoutActions.DisplaySnackbarAlert.SetAlert({
              alert: {
                alertType: "success",
                messageHeader: "Success!",
                message: "User was unlocked",
              },
            }),
          ]),
          catchError((error) =>
            of(AccountAdminActions.UnlockUser.Fail({ error }))
          )
        )
      )
    )
  );

  unlockUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountAdminActions.UnlockUsers.Request),
      mergeMap((action) =>
        this.accountAdminService.unlockUsers(action.userAks, action.accountId).pipe(
          mergeMap((resp) => [
            AccountAdminActions.UnlockUsers.Success({
              userAks: action.userAks,
            }),
            LayoutActions.DisplaySnackbarAlert.SetAlert({
              alert: {
                alertType: "success",
                messageHeader: "Success!",
                message: "Users were unlocked",
              },
            }),
          ]),
          catchError((error) =>
            of(AccountAdminActions.UnlockUsers.Fail({ error }))
          )
        )
      )
    )
  );

  updateUserUnlockDate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountAdminActions.UpdateUserUnlockDate.Request),
      mergeMap((action) =>
        this.accountAdminService
          .updateUserUnlockDate(action.userAk, action.unlockDate, action.accountId)
          .pipe(
            mergeMap((resp) => [
              AccountAdminActions.UpdateUserUnlockDate.Success({
                userAk: action.userAk,
                unlockDate: action.unlockDate,
              }),
              LayoutActions.DisplaySnackbarAlert.SetAlert({
                alert: {
                  alertType: "success",
                  messageHeader: "Success!",
                  message: "User unlock date was updated",
                },
              }),
            ]),
            catchError((error) =>
              of(AccountAdminActions.UpdateUserUnlockDate.Fail({ error }))
            )
          )
      )
    )
  );

  updateUsersDelegatedInvites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountAdminActions.UpdateUsersDelegatedInvites.Request),
      mergeMap((action) =>
        this.accountAdminService
          .updateUsersDelegatedInvites(action.payload, action.accountId)
          .pipe(
            mergeMap((resp) => [
              AccountAdminActions.UpdateUsersDelegatedInvites.Success({
                payload: action.payload,
              }),
              AccountAdminActions.AccountAdminGetRemainingSeatsForAcc.Request({ accountId: action.accountId }),
            ]),
            catchError((error) =>
              of(
                AccountAdminActions.UpdateUsersDelegatedInvites.Fail({ error })
              )
            )
          )
      )
    )
  );

  downloadAdminteamMap$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountAdminActions.DownloadAdminTeamMap.Request),
      mergeMap((action) =>
        this.accountAdminService.downloadAdminTeamMap(action.memberIds, action.accountId).pipe(
          map((resp) => AccountAdminActions.DownloadAdminTeamMap.Success()),
          catchError((error) =>
            of(AccountAdminActions.DownloadAdminTeamMap.Fail({ error }))
          )
        )
      )
    )
  );

  getOnboardingLiveAnalytics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountAdminActions.GetOnboardingLiveAnalytics.Request),
      mergeMap((action) =>
        this.accountAdminService.getOnboardingLiveAnalytics(action.accountId).pipe(
          map((resp) => AccountAdminActions.GetOnboardingLiveAnalytics.Success({ data: resp })),
          catchError((error) =>
            of(AccountAdminActions.GetOnboardingLiveAnalytics.Fail({ error }))
          )
        )
      )
    )
  );

  getConnectionsLiveAnalytics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountAdminActions.GetConnectionsLiveAnalytics.Request),
      mergeMap((action) =>
        this.accountAdminService.getConnectionsLiveAnalytics(action.accountId).pipe(
          map((resp) => AccountAdminActions.GetConnectionsLiveAnalytics.Success({ data: resp })),
          catchError((error) =>
            of(AccountAdminActions.GetConnectionsLiveAnalytics.Fail({ error }))
          )
        )
      )
    )
  );

  getTeamsLiveAnalytics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountAdminActions.GetTeamsLiveAnalytics.Request),
      mergeMap((action) =>
        this.accountAdminService.getTeamLiveAnalytics(action.accountId).pipe(
          map((resp) => AccountAdminActions.GetTeamsLiveAnalytics.Success({ data: resp })),
          catchError((error) =>
            of(AccountAdminActions.GetTeamsLiveAnalytics.Fail({ error }))
          )
        )
      )
    )
  );

  getInteractionLiveAnalytics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountAdminActions.GetInteractionLiveAnalytics.Request),
      mergeMap((action) =>
        this.accountAdminService.getInteractionLiveAnalytics(action.accountId, action.dateRange).pipe(
          map((resp) => AccountAdminActions.GetInteractionLiveAnalytics.Success({ data: resp })),
          catchError((error) =>
            of(AccountAdminActions.GetInteractionLiveAnalytics.Fail({ error }))
          )
        )
      )
    )
  );

  getNudgeAndWorkOnLiveAnalytics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountAdminActions.GetNudgeAndWorkOnLiveAnalytics.Request),
      mergeMap((action) =>
        this.accountAdminService.getNudgeAndWorkOnLiveAnalytics(action.accountId, action.dateRange).pipe(
          map((resp) => AccountAdminActions.GetNudgeAndWorkOnLiveAnalytics.Success({ data: resp })),
          catchError((error) =>
            of(AccountAdminActions.GetNudgeAndWorkOnLiveAnalytics.Fail({ error }))
          )
        )
      )
    )
  );

  getUsageLiveAnalytics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountAdminActions.GetUsageLiveAnalytics.Request),
      mergeMap((action) =>
        this.accountAdminService.getUsageLiveAnalytics(action.accountId, action.dateRange).pipe(
          map((resp) => AccountAdminActions.GetUsageLiveAnalytics.Success({ data: resp })),
          catchError((error) =>
            of(AccountAdminActions.GetUsageLiveAnalytics.Fail({ error }))
          )
        )
      )
    )
  );

  addUsersSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AccountAdminActions.AddUsers.Success),
        map((action) => {
          if (action.resp.usersNotAdded.length > 0)
            this.modalService.show(AddUserSuccessModalComponent, {
              initialState: {
                totalInvited: action.resp.addedUsers.length,
                notInvited: action.resp.usersNotAdded,
              },
            });
        })
      ),
    { dispatch: false }
  );

  downloadTeamMapAlertRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountAdminActions.DownloadAdminTeamMap.Request),
      map(() =>
        LayoutActions.DisplaySnackbarAlert.SetAlert({
          alert: {
            alertType: "info",
            messageHeader: "Processing...",
            message: `Your team map is being generated.`,
            timeout: 30 * 1000,
          },
        })
      )
    )
  );

  updateUserDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountAdminActions.UpdateUserDetails.Request),
      mergeMap((action) =>
        this.accountAdminService.updateUserDetails(action.userDetails, action.accountId).pipe(
          mergeMap((resp) => [
            AccountAdminActions.UpdateUserDetails.Success({
              userDetails: action.userDetails,
            }),
            LayoutActions.DisplaySnackbarAlert.SetAlert({
              alert: {
                alertType: "success",
                message: "User details updated successfully",
              },
            }),
          ]),
          catchError((error) =>
            of(AccountAdminActions.UpdateUserDetails.Fail({ error }))
          )
        )
      )
    )
  );

  getMindflickOrganisation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountAdminActions.GetMindflickOrganisation.Request),
      mergeMap((action) =>
        this.accountAdminService.getMindflickOrganisation().pipe(
          map((resp) =>
            AccountAdminActions.GetMindflickOrganisation.Success({
              mindflickOrganisation: resp,
            })
          ),
          catchError((error) =>
            of(AccountAdminActions.GetMindflickOrganisation.Fail({ error }))
          )
        )
      )
    )
  );

  setMindflickOrganisationSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountAdminActions.SetMindflickOrganisationSettings.Request),
      mergeMap((action) =>
        this.accountAdminService
          .setMindflickOrganisationSettings(action.settings)
          .pipe(
            mergeMap((resp) => [
              AccountAdminActions.SetMindflickOrganisationSettings.Success({
                settings: action.settings,
              }),
              LayoutActions.DisplaySnackbarAlert.SetAlert({
                alert: {
                  alertType: "success",
                  messageHeader: "Success!",
                  message: `Your settings have been saved.'`,
                },
              }),
            ]),
            catchError((error) =>
              of(
                AccountAdminActions.SetMindflickOrganisationSettings.Fail({
                  error,
                })
              )
            )
          )
      )
    )
  );

  addNoteToAccountUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountAdminActions.AddNoteToAccountUser.Request),
      mergeMap((action) =>
        this.accountAdminService.addNoteToAccountUser(action.note).pipe(
          mergeMap((resp) => [
            AccountAdminActions.AddNoteToAccountUser.Success({ note: resp }),
            LayoutActions.DisplaySnackbarAlert.SetAlert({
              alert: {
                alertType: "success",
                messageHeader: "Success!",
                message: "Note saved successfully.",
              },
            }),
          ]),
          catchError((error) =>
            of(AccountAdminActions.AddNoteToAccountUser.Fail({ error }))
          )
        )
      )
    )
  );

  getAccountsSummaryWithinOrg$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountAdminActions.GetAccountsSummaryWithinOrg.Request),
      mergeMap((action) =>
        this.accountAdminService.getAccountsSummaryInOrg().pipe(
          mergeMap((resp) => [
            AccountAdminActions.GetAccountsSummaryWithinOrg.Success({
              accountsData: resp,
            }),
          ]),
          catchError((error) =>
            of(AccountAdminActions.GetAccountsSummaryWithinOrg.Fail({ error }))
          )
        )
      )
    )
  );

  getUserAccountsWithUserManagePermission$ = createEffect(() => 
    this.actions$.pipe(
      ofType(AccountAdminActions.GetUserAccountsWithUserManagePermission.Request),
      mergeMap((action) =>
        this.accountAdminService.getUserAccountsWithRoleOrPermission("USER_MANAGE").pipe(
          map((resp) => AccountAdminActions.GetUserAccountsWithUserManagePermission.Success({ accounts: resp })),
          catchError(error => of(AccountAdminActions.GetUserAccountsWithUserManagePermission.Fail({ error })))
        )
      )
    )
  );

  getUserAccountsWithLiveAnalyticsPermission$ = createEffect(() => 
    this.actions$.pipe(
      ofType(AccountAdminActions.GetUserAccountsWithLiveAnalyticsPermission.Request),
      mergeMap((action) =>
        this.accountAdminService.getUserAccountsWithRoleOrPermission("VIEW_ACC_ANALYTICS").pipe(
          map((resp) => AccountAdminActions.GetUserAccountsWithLiveAnalyticsPermission.Success({ accounts: resp })),
          catchError(error => of(AccountAdminActions.GetUserAccountsWithLiveAnalyticsPermission.Fail({ error })))
        )
      )
    )
  );

  setNewAccountAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountAdminActions.SetNewAccountAdmin.Request),
      mergeMap((action) =>
        this.accountAdminService.setNewAccountAdmin(action.payload).pipe(
          mergeMap((resp) => [
            AccountAdminActions.SetNewAccountAdmin.Success({
              userId: action.payload.userId,
              mindflickAccountId: action.payload.mindflickAccountId,
              mindflickRole: resp
            }),
            LayoutActions.DisplaySnackbarAlert.SetAlert({ alert: { alertType: 'success', message: 'Account admin updated successfully!' } })
          ]),
          catchError((error) =>
            of(AccountAdminActions.SetNewAccountAdmin.Fail({ error }))
          )
        )
      )
    )
  );

  setNewAccountAdminSuccess$ = createEffect(() => 
    this.actions$.pipe(
      ofType(AccountAdminActions.SetNewAccountAdmin.Success),
      withLatestFrom(this.store.select(selectCurrentUser)),
      map(([action, user]) => {
        if (user) {

          if (user.id !== action.userId) {
            this.rbacService.removeRoleFromAccount("ACC_ADMIN", action.mindflickAccountId);
          } else if (action.mindflickRole) {
            this.rbacService.addRoleToAccount(action.mindflickRole, action.mindflickAccountId);
          }
        }    
      })
    ),
    { dispatch: false }
  );

  getAccountChampions$ = createEffect(() => 
    this.actions$.pipe(
      ofType(AccountAdminActions.GetAccountChampions.Request),
      mergeMap((action) =>
        this.accountAdminService.getAccountChampions(action.accountId).pipe(
          mergeMap((resp) => [
            AccountAdminActions.GetAccountChampions.Success({
              resp
            })
          ]),
          catchError((error) =>
            of(AccountAdminActions.GetAccountChampions.Fail({ error }))
          )
        )
      )
    )
  );

  getAccountCoordinators$ = createEffect(() => 
    this.actions$.pipe(
      ofType(AccountAdminActions.GetAccountCoordinators.Request),
      mergeMap((action) =>
        this.accountAdminService.getAccountCoordinators(action.accountId).pipe(
          mergeMap((resp) => [
            AccountAdminActions.GetAccountCoordinators.Success({
              resp
            })
          ]),
          catchError((error) =>
            of(AccountAdminActions.GetAccountCoordinators.Fail({ error }))
          )
        )
      )
    )
  );

  getAccountSettingsAccounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountAdminActions.GetAccountSettingsAccounts.Request),
      mergeMap((action) =>
        this.accountAdminService.getAccountsForAccountAdmin().pipe(
          map(resp => AccountAdminActions.GetAccountSettingsAccounts.Success({ resp })),
          catchError(error => of(AccountAdminActions.GetAccountSettingsAccounts.Fail({ error })))
        )
      )
    )
  );

  getAccountSettingsAccountsForOrgAdmin$ = createEffect(() => 
    this.actions$.pipe(
      ofType(AccountAdminActions.GetAccountSettingsAccountsForOrgAdmin.Request),
      mergeMap((action) =>
        this.accountAdminService.getAccountsForOrgAdmin().pipe(
          map(resp => AccountAdminActions.GetAccountSettingsAccountsForOrgAdmin.Success({ resp })),
          catchError(error => of(AccountAdminActions.GetAccountSettingsAccountsForOrgAdmin.Fail({ error })))
        )
      )
    )
  );

  addChampionToAccount$ = createEffect(() => 
    this.actions$.pipe(
      ofType(AccountAdminActions.AddChampionToAccount.Request),
      mergeMap((action) => 
        this.accountAdminService.addChampionToAccount(action.accountId, action.userAk).pipe(
          mergeMap((resp) => [
            AccountAdminActions.AddChampionToAccount.Success({ accountId: action.accountId, userAk: action.userAk, mindflickRole: resp }),
            LayoutActions.DisplaySnackbarAlert.SetAlert({ alert: { alertType: 'success', message: 'Champion added succesfully!' }})
          ]),
          catchError(error => of(AccountAdminActions.AddChampionToAccount.Fail({ error })))
        )
      )
    )
  );

  addChampionToAccountSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountAdminActions.AddChampionToAccount.Success),
      withLatestFrom(this.store.select(selectCurrentUser)),
      map(([action, user]) => {
        if (user && user.id == action.userAk && action.mindflickRole) {
          this.rbacService.addRoleToAccount(action.mindflickRole, action.accountId);
        }
      })
    ),
    { dispatch: false }
  );

  addCoordinatorToAccount$ = createEffect(() => 
    this.actions$.pipe(
      ofType(AccountAdminActions.AddCoordinatorToAccount.Request),
      mergeMap((action) => 
        this.accountAdminService.addCoordinatorToAccount(action.accountId, action.userAk).pipe(
          mergeMap((resp) => [
            AccountAdminActions.AddCoordinatorToAccount.Success({ accountId: action.accountId, userAk: action.userAk, mindflickRole: resp }),
            LayoutActions.DisplaySnackbarAlert.SetAlert({ alert: { alertType: 'success', message: 'Coordinator added succesfully!' }})
          ]),
          catchError(error => of(AccountAdminActions.AddCoordinatorToAccount.Fail({ error })))
        )
      )
    )
  );

  addCoordinatorToAccountSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountAdminActions.AddCoordinatorToAccount.Success),
      withLatestFrom(this.store.select(selectCurrentUser)),
      map(([action, user]) => {
        if (user && user.id == action.userAk && action.mindflickRole) {
          this.rbacService.addRoleToAccount(action.mindflickRole, action.accountId);
        }
      })
    ),
    { dispatch: false }
  );

  removeChampionFromAccount$ = createEffect(() => 
    this.actions$.pipe(
      ofType(AccountAdminActions.RemoveChampionFromAccount.Request),
      mergeMap((action) => 
        this.accountAdminService.removeChampionFromAccount(action.accountId, action.userAk).pipe(
          map(() => AccountAdminActions.RemoveChampionFromAccount.Success({ accountId: action.accountId, userAk: action.userAk })),
          catchError(error => of(AccountAdminActions.RemoveChampionFromAccount.Fail({ error })))
        )
      )
    )
  );

  removeChampionFromAccountSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountAdminActions.RemoveChampionFromAccount.Success),
      withLatestFrom(this.store.select(selectCurrentUser)),
      map(([action, user]) => {
        if (user && user.id == action.userAk) {
          this.rbacService.removeRoleFromAccount("CHAMPION", action.accountId);
        }
      })
    ),
    { dispatch: false }
  );

  removeCoordinatorFromAccount$ = createEffect(() => 
    this.actions$.pipe(
      ofType(AccountAdminActions.RemoveCoordinatorFromAccount.Request),
      mergeMap((action) => 
        this.accountAdminService.removeCoordinatorFromAccount(action.accountId, action.userAk).pipe(
          map(() => AccountAdminActions.RemoveCoordinatorFromAccount.Success({ accountId: action.accountId, userAk: action.userAk })),
          catchError(error => of(AccountAdminActions.RemoveCoordinatorFromAccount.Fail({ error })))
        )
      )
    )
  );

  removeCoordinatorFromAccountSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountAdminActions.RemoveCoordinatorFromAccount.Success),
      withLatestFrom(this.store.select(selectCurrentUser)),
      map(([action, user]) => {
        if (user && user.id == action.userAk) {
          this.rbacService.removeRoleFromAccount("COORDINATOR", action.accountId);
        }
      })
    ),
    { dispatch: false }
  );

  getValidAccountAdminsForOrg$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountAdminActions.GetValidAccountAdminsForOrg.Request),
      mergeMap((action) =>
        this.accountAdminService.getValidAccountAdminsForOrg().pipe(
          map(resp => AccountAdminActions.GetValidAccountAdminsForOrg.Success({ resp })),
          catchError(error => of(AccountAdminActions.GetValidAccountAdminsForOrg.Fail({ error })))
        )
      )
    )
  );

  getValidChampionsForOrg$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountAdminActions.GetValidChampionsForOrg.Request),
      mergeMap((action) => 
        this.accountAdminService.getValidChampionsForOrg().pipe(
          map(resp => AccountAdminActions.GetValidChampionsForOrg.Success({ resp })),
          catchError(error => of(AccountAdminActions.GetValidChampionsForOrg.Fail({ error })))
        )
      )
    )
  );

  getValidCoordinatorsForOrg$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountAdminActions.GetValidCoordinatorsForOrg.Request),
      mergeMap((action) => 
        this.accountAdminService.getValidCoordinatorsForOrg().pipe(
          map(resp => AccountAdminActions.GetValidCoordinatorsForOrg.Success({ resp })),
          catchError(error => of(AccountAdminActions.GetValidCoordinatorsForOrg.Fail({ error })))
        )
      )
    )
  );

  private addUserAlert(
    amountAdded: number,
    amountNotAdded: number,
    amountDeactivated: number
  ) {
    var addedMessage = amountAdded ? `You added ${amountAdded} user(s). ` : "";
    var notAddedMessage = amountNotAdded
      ? `Failed to add ${amountNotAdded} user(s). `
      : "";
    var deactivatedMessage = amountDeactivated
      ? `There were ${amountDeactivated} deactivated user(s). `
      : "";
    var message = addedMessage + deactivatedMessage + notAddedMessage;

    var alertType: "info" | "success" | "danger" =
      notAddedMessage || amountDeactivated
        ? "info"
        : amountAdded
          ? "success"
          : "danger";
    var messageHeader =
      notAddedMessage || amountDeactivated
        ? "Info"
        : amountAdded
          ? "Success!"
          : "Failure";

    return LayoutActions.DisplaySnackbarAlert.SetAlert({
      alert: {
        alertType,
        messageHeader,
        message,
      },
    });
  };


  getAllAccountsForOrgAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountAdminActions.GetAllAccountsForOrgAdmin.Request),
      mergeMap(() =>
        this.organisationAdminService.getAllAccountsForOrgAdmin().pipe(
          map((resp) =>
            AccountAdminActions.GetAllAccountsForOrgAdmin.Success({
              accounts: resp
            })
          ),
          catchError((error) =>
            of(AccountAdminActions.GetAllAccountsForOrgAdmin.Fail({ error }))
          )
        )
      )
    )
  );
}
