<ng-container *ngIf="!isHidden">
  <div class="modal-header justify-content-end">
    <fa-icon class="cursor-pointer fa-lg"
             [icon]="close"
             (click)="closeModal(addPersonForm.dirty)"></fa-icon>
  </div>

  <div [@addUserShowConfirm]="showNoLockConfirmation ? 'showConfirm' : 'addUser'"
       class="modal-body">
    <div *ngIf="!showNoLockConfirmation">
      <div *ngIf="!hasMoreThan10PercentSeatsRemaining && seatsInAccData"
           class="mb-4">
        <div *ngIf="
            !isOverSeatsByLessThan10Percent && !isOverSeatsByMoreThan10Percent
          "
             class="body-copy">
          You have used {{ seatsInAccData.currentActiveUsersInAcc }}/{{
            seatsInAccData.totalSeats
          }}
          of your current seat allocation. If you think you'll be needing more
          seats get in touch with Mindflick at
          <a href="mailto:switch&#64;mindflick.co.uk">switch&#64;mindflick.co.uk</a>.
        </div>
        <div *ngIf="isOverSeatsByLessThan10Percent"
             class="body-copy text-warning">
          You have used {{ seatsInAccData.currentActiveUsersInAcc }}/{{
            seatsInAccData.totalSeats
          }}
          of your current seat allocation. But don't worry we have you covered!
          If you think you'll be needing more seats get in touch with Mindflick
          at
          <a href="mailto:switch&#64;mindflick.co.uk">switch&#64;mindflick.co.uk</a>.
        </div>
        <div *ngIf="isOverSeatsByMoreThan10Percent"
             class="body-copy text-error">
          You have used {{ seatsInAccData.currentActiveUsersInAcc }}/{{
            seatsInAccData.totalSeats
          }}
          of your current seat allocation. You're already over your current seat
          allocation and so can't add anymore users right now. Get in touch with
          Mindflick at switch&#64;mindflick.co.uk to discuss increasing your seat
          allocation today.
        </div>
      </div>

      <ng-container *ngIf="!(isAddingUsers$ | async); else busyIndicator">
        <div *ngIf="!isOverSeatsByMoreThan10Percent"
             class="d-flex justify-content-between mb-4">
          <h2>Add user</h2>
          <div *ngIf="hasMoreThan10PercentSeatsRemaining"
               class="d-flex body-copy text-decoration-underline open-bulk-modal"
               (click)="openBulkInviteModal()">
            Bulk import
            <fa-icon class="ms-1 fa-xl" [icon]="bulkImport"></fa-icon>
          </div>
        </div>

        <div *ngIf="!isOverSeatsByMoreThan10Percent"
             class="row pt-4 gy-3"
             [formGroup]="addPersonForm">
          <div class="col-6 pe-35">
            <div class="form-label">First name*</div>
            <input placeholder="First name"
                   formControlName="firstName"
                   class="form-control" />
          </div>
          <div class="col-6 ps-0">
            <div class="form-label">Last name*</div>

            <input placeholder="Last name"
                   formControlName="lastName"
                   class="form-control" />
          </div>
          <div class="col-12 mt-4">
            <div class="form-label">Email address*</div>
            <input placeholder="Email address"
                   class="form-control"
                   formControlName="email"
                   (blur)="onEmailBlur()" />
            <div *ngIf="
                addPersonForm.controls['email'].invalid &&
                emailBlurred &&
                (addPersonForm.controls['email'].dirty ||
                  addPersonForm.controls['email'].touched)
              "
                 class="form-error">
              *Please ensure your email is a correct email format e.g.
              example&#64;mindflick.com
            </div>
          </div>
          <div class="col-12">
            <div class="form-label">Department</div>
            <input placeholder="Department (optional)"
                   formControlName="department"
                   class="form-control" />
          </div>
          <div class="renewal-date position-relative col-12 mt-4">
            <label for="renewalDate"
                   class="form-label d-flex justify-content-between">
              Lock user until onboarding date<fa-icon class="addUserToolTips ms-1 pt-1"
                                                      [icon]="info"
                                                      tooltip="This date is when the user will be unlocked and have access to the platform. If you want them to be unlocked straight away leave this blank."
                                                      [tooltipAppendToBody]="true"></fa-icon>
            </label>
            <input [(ngModel)]="unlockDate"
                   [ngModelOptions]="{ standalone: true }"
                   type="text"
                   placeholder="Onboarding date (optional)"
                   class="form-control"
                   bsDatepicker
                   [container]="'.renewal-date'"
                   [bsConfig]="{
                showWeekNumbers: false,
                dateInputFormat: 'DD/MM/YYYY'
              }"
                   [minDate]="tomorrow" />
          </div>
        </div>
        <div class="btn-container">

        </div>
        <button *ngIf="!isOverSeatsByMoreThan10Percent"
                class="btn btn-purple w-100 mt-4"
                (click)="addUser()">
          Add user
        </button>
      </ng-container>

      <button *ngIf="isOverSeatsByMoreThan10Percent"
              class="btn btn-purple w-100"
              (click)="closeModal(false)">
        Okay
      </button>
    </div>
    <div *ngIf="showNoLockConfirmation"
         class="d-flex flex-column no-lock-confirm">
      <ng-container *ngIf="!(isAddingUsers$ | async); else busyIndicator">
        <h2 class="mb-4">
          Warning
          <img class="ms-3 warning-sign"
               src="./assets/svg/portal-icons/Warning_Sign.svg" />
        </h2>
        <h3 class="mb-4">
          You're about to add users without locking them until onboarding date.
        </h3>
        <h3 class="mb-4">
          This will give them immediate access to Mindflick. Are you sure you want
          to continue?
        </h3>
        <div class="d-flex justify-content-between">
          <button class="w-100 me-2 btn btn-secondary" (click)="confirmNoLock()">
            Continue anyway
          </button>
          <button class="w-100 ms-2 btn btn-purple"
                  (click)="showNoLockConfirmation = false">
            Back to add user
          </button>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="modal-bottom-border"></div>
</ng-container>

<ng-template #busyIndicator>
  <div>
    <img class="busy-indicator"
         src="./assets/images/loaders/Loader_white.gif" />
  </div>
</ng-template>
