import { createReducer, on } from '@ngrx/store';
import { Profile } from 'src/app/shared/models';
import * as ProfileActions from './profile.actions';
import * as UserActions from '../user/user.actions';
import { Handle } from '../signalr/signalr.actions';
import { NotificationType } from 'src/app/shared/models/enums/notification-types.enum';
import { CommonActions } from '../common-actions';
import { DeactivateUser } from '../account/account.actions';

export interface ProfileState {
  myProfile?: Profile;
  loadedProfiles: Profile[];
  currentProfile?: Profile;
  profileLoading: boolean;
  profileProcessing: boolean;
  isViewingOwnProfile: boolean;
  isNotificationNavigation: boolean;
  strengthIdToView?: string;
}

export const initialProfileState: ProfileState = {
  loadedProfiles: [],
  currentProfile: undefined,
  profileLoading: false,
  profileProcessing: false,
  isViewingOwnProfile: false,
  isNotificationNavigation: false,
};

export const profileReducer = createReducer(
  initialProfileState,

  on(CommonActions.ClearState, (state, props) => ({
    ...initialProfileState
  })),

  on(ProfileActions.GetMyProfile.Request, (state, props) => ({
    ...state,
    profileLoading: true,
  })),

  on(ProfileActions.GetMyProfile.Success, (state, props) => {
    if (props.profile.error) {
      return {
        ...state,
        profileLoading: false,
        profileProcessing: true,
      };
    } else {
      return {
        ...state,
        myProfile: props.profile,
        profileLoading: false,
        profileProcessing: false,
      };
    }
  }),

  on(ProfileActions.GetMyProfile.Fail, (state, props) => ({
    ...state,
    profileLoading: false,
    profileProcessing: false,
  })),

  on(ProfileActions.GetProfileForUser.Request, (state, props) => ({
    ...state,
    currentProfile: undefined,
    profileLoading: true,
  })),

  on(ProfileActions.GetProfileForUser.Success, (state, props) => ({
    ...state,
    loadedProfiles: [
      ...state.loadedProfiles.filter((x) => x.userId != props.profile.userId),
      props.profile,
    ],
    currentProfile: props.profile,
    profileLoading: false,
  })),

  on(ProfileActions.GetProfileForUser.Fail, (state, props) => ({
    ...state,
    profileLoading: false,
  })),

  on(ProfileActions.SaveProfileSectionSelection.Success, (state, props) => {
    if (state.currentProfile == null) return state;

    let updatedProfile = {
      ...state.currentProfile,
      sections: state.currentProfile.sections.map((section) => {
        if (section.id == props.sectionId) {
          return {
            ...section,
            items: section.items.map((item) => {
              if (item.id == props.body.reportSection) {
                return {
                  ...item,
                  isSelected: props.body.isSelected,
                };
              }
              return item;
            }),
          };
        }

        return section;
      }),
    };

    // We don't need to update the current profile since the UI is updated from the click
    // Loaded profiles need updating for if the user navigates away from this profile and back later
    return {
      ...state,
      loadedProfiles: [
        ...state.loadedProfiles.filter(
          (x) => x.userId != updatedProfile.userId
        ),
        updatedProfile,
      ],
    };
  }),
  on(ProfileActions.SaveRecognizedStrengths.Success, (state, props) => {
    if (state.currentProfile == null) return state;
    let updatedProfile = structuredClone({
      ...state.currentProfile,
      sections: state.currentProfile.sections.map((section) => {
        if (section.id == 'strengths') {
          return {
            ...section,
            items: section.items.map((item) => {
              if (item.id == props.newStrengthComment.strengthId) {
                return {
                  ...item,
                  comments: item.comments ? [...item.comments, props.newStrengthComment] : [props.newStrengthComment],
                  timesSpotted: item.timesSpotted + 1
                };
              }
              return item;
            }),
          };
        }

        return section;
      }),
    });
    let newLoadedProfiles = [
      ...state.loadedProfiles.map((profile) => {
        if (profile.userId === updatedProfile.userId) {
          return updatedProfile;
        }
        return profile;
      }),
    ];

    return structuredClone(
      {
        ...state,
        currentProfile: updatedProfile,
        loadedProfiles: newLoadedProfiles,
      });
  }),

  on(ProfileActions.GenerateConnectionDetails.Success, (state, props) => ({
    ...state,
    loadedProfiles: state.loadedProfiles.map((profile) => {
      if (profile.userAK == props.connectedUserAK) {
        return {
          ...profile,
          tipsToConnect: props.response.tipsToConnect,
          emptyChairs: props.response.emptyChairs,
        };
      }

      return profile;
    }),
  })),

  on(ProfileActions.SetViewingOwnProfile, (state, props) => ({
    ...state,
    isViewingOwnProfile: props.isViewingOwnProfile,
  })),

  on(UserActions.PutConnectionDate.Success, (state, props) => ({
    ...state,
    loadedProfiles: state.loadedProfiles.filter(
      (x) => x.userAK != props.userId
    ),
  })),

  on(Handle, (state, props) => {
    if (props.notification.type == NotificationType.ConnectionAccepted)
      return {
        ...state,
        loadedProfiles: state.loadedProfiles.filter(
          (x) => x.userAK != props.notification.sendingUserAK
        ),
      };

    return state;
  }),

  on(ProfileActions.StrengthSpottedNotification.View, (state, prop) => ({
    ...state,
    isNotificationNavigation: true,
    strengthIdToView: prop.strengthId,
  })),

  on(ProfileActions.StrengthSpottedNotification.Hide, (state) => ({
    ...state,
    isNotificationNavigation: false,
    strengthIdToView: undefined,
  })),
  on(DeactivateUser.Success, (state, props) => ({
    ...state,
    loadedProfiles: state.loadedProfiles.filter(
      (x) => x.userAK != props.userAK
    ),
  })),
  on(ProfileActions.GetTopTipToConnect.Success, (state, props) => ({
    ...state,
    currentProfile: state.currentProfile
      ? {
        ...state.currentProfile,
        tipsToConnect: props.topTip
          ? state.currentProfile.tipsToConnect.map((x) => {
            if (x.id === props.topTip.tipsToConnectId) {
              return {
                ...x,
                isTopTip: true,
              };
            } else {
              return {
                ...x,
              };
            }
          })
          : state.currentProfile.tipsToConnect,
      }
      : undefined,

    loadedProfiles: state.loadedProfiles.map((item) => {
      if (
        props.topTip &&
        item.userAK === props.topTip.viewedUserAk &&
        item.tipsToConnect.find((x) => x.id === props.topTip.tipsToConnectId)
      ) {
        return {
          ...item,
          tipsToConnect: item.tipsToConnect.map((x) => {
            return {
              ...x,
              isTopTip: true,
            };
          }),
        };
      } else {
        return {
          ...item,
        };
      }
    }),
  })),
  on(ProfileActions.SetTopTip.Success, (state, props) => ({
    ...state,
    currentProfile: state.currentProfile
      ? {
        ...state.currentProfile,
        tipsToConnect: state.currentProfile.tipsToConnect.map((x) => {
          if (x.id === props.topTip.tipsToConnectId) {
            return {
              ...x,
              isTopTip: true,
            };
          } else {
            return {
              ...x,
              isTopTip: false,
            };
          }
        }),
      }
      : undefined,

    loadedProfiles: state.loadedProfiles.map((item) => {
      if (
        item.userAK === props.topTip.viewedUserAk &&
        item.tipsToConnect.find((x) => x.id === props.topTip.tipsToConnectId)
      ) {
        return {
          ...item,
          tipsToConnect: item.tipsToConnect.map((x) => {
            return {
              ...x,
              isTopTip: true,
            };
          }),
        };
      } else {
        return {
          ...item,
          isTopTip: false,
        };
      }
    }),
  })),
  on(ProfileActions.RemoveTopTip.Success, (state, props) => ({
    ...state,
    loadedProfiles: state.loadedProfiles.map((profile) => {
      if (
        profile.userAK === props.topTip.tipsToConnectId &&
        profile.tipsToConnect.find((x) => x.id === props.topTip.tipsToConnectId)
      ) {
        return {
          ...profile,
          tipsToConnect: profile.tipsToConnect.map((x) => {
            return {
              ...x,
              isTopTip: false,
            };
          }),
        };
      } else {
        return { ...profile };
      }
    }),
    currentProfile: state.currentProfile
      ? {
        ...state.currentProfile,
        tipsToConnect: state.currentProfile.tipsToConnect.map((x) => {
          if (x.id === props.topTip.tipsToConnectId) {
            return {
              ...x,
              isTopTip: false,
            };
          } else {
            return {
              ...x,
            };
          }
        }),
      }
      : undefined,
  }))
);
