import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { UserService } from 'src/app/shared/services/user.service';
import { GetMindflickAccountsForCurrentUser } from '../account/account.actions';
import * as LayoutActions from '../layout/actions/layout.actions';
import * as ProfileActions from '../profile/profile.actions';
import * as SignalRActions from '../signalr/signalr.actions';
import * as UserActions from './user.actions';
import { User } from 'src/app/shared/models';
import { Router } from '@angular/router';
import { PRODUCT_SET_STORAGE_KEY } from '../../account/product-page/product-page.component';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private userService: UserService,
    private router: Router
  ) {}

  getHasBothProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.GetHasBothProducts.Request),
      mergeMap((action) =>
        this.userService.getHasAccessToBothProducts().pipe(
          map((resp) => UserActions.GetHasBothProducts.Success({ resp })),
          catchError((error) =>
            of(UserActions.GetHasBothProducts.Fail({ error }))
          )
        )
      )
    )
  );

  getHasBothProductsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.GetHasBothProducts.Success),
      map((action) => {
        if (action.resp && !sessionStorage.getItem(PRODUCT_SET_STORAGE_KEY)) {
          this.router.navigate(['account', 'product']);
          return LayoutActions.noOpAction();
        }
        if (localStorage.getItem('selected-mindflick-account')) {
          return UserActions.GetUser.Request();
        }

        return GetMindflickAccountsForCurrentUser.Request();
      })
    )
  );

  getUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.GetUser.Request),
      mergeMap((action) =>
        this.userService.getUser().pipe(
          map((resp) => {
            return UserActions.GetUser.Success({ user: resp });
          }),
          catchError((error) => of(UserActions.GetUser.Fail({ error })))
        )
      )
    )
  );

  getUserByAK$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.GetUserByAK.Request),
      mergeMap((action) =>
        this.userService.getUserByAK(action.userAK).pipe(
          map((resp) => UserActions.GetUserByAK.Success({ user: resp })),
          catchError((error) => of(UserActions.GetUserByAK.Fail({ error })))
        )
      )
    )
  );

  getAllUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.GetAllUsers.Request),
      mergeMap((action) =>
        this.userService.getAllUsers().pipe(
          map((resp) => UserActions.GetAllUsers.Success({ allUsers: resp })),
          catchError((error) => of(UserActions.GetAllUsers.Fail({ error })))
        )
      )
    )
  );

  getThisUserSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.GetUser.Success),
      mergeMap((action) => {
        if (!action.user.isSpotlightComplete) {
          this.router.navigate([
            '/survey/sign-up/',
            action.user.spotlightUniqueRef,
            action.user.spotlightId,
          ]);
          return [LayoutActions.noOpAction()];
        }

        if (this.router.url.includes('/survey')) {
          this.router.navigate([this.router.url]);
          return [LayoutActions.noOpAction()];
        }

        if (action.user.requiresExternalAuthentication) {
          //If the user needs to sign in using an external provider then return the user to the select account screen
          return [LayoutActions.ShowRequiresExternalAuthenticationScreen()];
        }

        if (action.user.isLocked)
          return [LayoutActions.ShowLockedUserHoldingScreen()];

        if (action.user.accountId)
          // This action should only ever be called the once so we can use it to then query some more of the initial data
          // and avoid overloading the server by making multiple requests at once
          // Start by getting the current users profile
          if (action.user.isDebriefRequired) {
            this.router.navigate(["debrief/intro"]);
          }
          return [ProfileActions.GetMyProfile.Request()];

        //If the user has no account, clear the selected mindflick account from local storage and request the list of accounts
        localStorage.removeItem('selected-mindflick-account');
        window.location.reload();
        return [GetMindflickAccountsForCurrentUser.Request()];
      })
    )
  );

  getMyConnectedUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.GetMyConnectedUsers.Request),
      mergeMap((action) =>
        this.userService.getMyConnectedUsers().pipe(
          map((resp) =>
            UserActions.GetMyConnectedUsers.Success({ connectedUsers: resp })
          ),
          catchError((error) =>
            of(UserActions.GetMyConnectedUsers.Fail({ error }))
          )
        )
      )
    )
  );

  putConnectionDate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.PutConnectionDate.Request),
      mergeMap((action) =>
        this.userService.putConnectionDate(action.userAK).pipe(
          mergeMap((resp) => [
            SignalRActions.NotifyUserOfConnectionAccepted.Request({
              userAK: action.userAK,
            }),
            UserActions.PutConnectionDate.Success({
              userId: action.userAK,
              fromProfilePage: action.fromProfilePage,
            }),
          ]),
          catchError((error) =>
            of(UserActions.PutConnectionDate.Fail({ error }))
          )
        )
      )
    )
  );

  putConnectionDateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.PutConnectionDate.Success),
      filter((x) => x.fromProfilePage),
      mergeMap((action) => [
        ProfileActions.GetProfileForUser.Request({ userId: action.userId }),
      ])
    )
  );

  deletePendingConnection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.DeletePendingConnection.Request),
      mergeMap((action) =>
        this.userService.deletePendingConnection(action.userAK).pipe(
          map((resp) =>
            UserActions.DeletePendingConnection.Success({
              userId: action.userAK,
            })
          ),
          catchError((error) =>
            of(UserActions.DeletePendingConnection.Fail({ error }))
          )
        )
      )
    )
  );

  requestProfileAccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.RequestProfileAccess.Request),
      mergeMap((action) =>
        this.userService.requestProfileAccess(action.userAK).pipe(
          mergeMap((resp) => [
            SignalRActions.NotifyUserOfConnectionRequest.Request({
              userAK: action.userAK,
            }),
            UserActions.RequestProfileAccess.Success({
              resp,
              userAK: action.userAK,
            }),
          ]),
          catchError((error) =>
            of(UserActions.RequestProfileAccess.Fail({ error }))
          )
        )
      )
    )
  );

  deleteUserConnection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.DeleteUserConnection.Request),
      mergeMap((action) =>
        this.userService.deleteUserConnection(action.userAK).pipe(
          map((resp) =>
            UserActions.DeleteUserConnection.Success({ userAK: action.userAK })
          ),
          catchError((error) =>
            of(UserActions.DeleteUserConnection.Fail({ error }))
          )
        )
      )
    )
  );

  updateUserDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.UpdateUserDetails.Request),
      mergeMap((action) =>
        this.userService.updateUserDetails(action.payload).pipe(
          map((resp) => UserActions.UpdateUserDetails.Success({ resp })),
          catchError((error) =>
            of(UserActions.UpdateUserDetails.Fail({ error }))
          )
        )
      )
    )
  );

  changeProfilePicture$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.ChangeUserPicture.Request),
      mergeMap((action) =>
        this.userService.updateUserPicture(action.formData).pipe(
          map((resp) =>
            UserActions.ChangeUserPicture.Success({ photoLastModified: resp })
          ),
          catchError((error) =>
            of(UserActions.ChangeUserPicture.Fail({ error }))
          )
        )
      )
    )
  );

  clearProfilePicture$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.ClearUserPicture.Request),
      mergeMap((action) =>
        this.userService.deleteUserPicture().pipe(
          map((resp) => UserActions.ClearUserPicture.Success()),
          catchError((error) =>
            of(UserActions.ClearUserPicture.Fail({ error }))
          )
        )
      )
    )
  );

  generate2FaRegistrationKeys$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.Generate2FARegistrationKeys.Request),
      mergeMap((action) =>
        this.userService.generate2FARegistrationKeys().pipe(
          map((resp) =>
            UserActions.Generate2FARegistrationKeys.Success({ keys: resp })
          ),
          catchError((error) =>
            of(UserActions.Generate2FARegistrationKeys.Fail({ error }))
          )
        )
      )
    )
  );

  verify2FAUserInputCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.Verify2FAUserInputCode.Request),
      mergeMap((action) =>
        this.userService.verify2FAUserInputCode(action.code).pipe(
          map((resp) => UserActions.Verify2FAUserInputCode.Success()),
          catchError((error) =>
            of(UserActions.Verify2FAUserInputCode.Fail({ error }))
          )
        )
      )
    )
  );

  setPlatformSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.SetPlatformSetting.Request),
      mergeMap((action) =>
        this.userService.setPlatformSettings(action.key, action.value).pipe(
          map((resp) =>
            UserActions.SetPlatformSetting.Success({
              key: action.key,
              value: action.value,
            })
          ),
          catchError((error) =>
            of(UserActions.SetPlatformSetting.Fail({ error }))
          )
        )
      )
    )
  );

  setBulkPlatformSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.SetBulkPlatformSettings.Request),
      mergeMap((action) =>
        this.userService.setPlatformBulkSettings(action.settings).pipe(
          map((resp) =>
            UserActions.SetBulkPlatformSettings.Success({
              settings: action.settings,
            })
          ),
          catchError((error) =>
            of(UserActions.SetBulkPlatformSettings.Fail({ error }))
          )
        )
      )
    )
  );

  setPlatformIntroViewedFlag$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.SetUserPlatformIntroViewedFlag.Request),
      mergeMap((action) =>
        this.userService.setPlatformIntroViewedFlag().pipe(
          map((resp) =>
            UserActions.SetUserPlatformIntroViewedFlag.Success({
              dateViewed: resp,
            })
          ),
          catchError((error) =>
            of(UserActions.SetUserPlatformIntroViewedFlag.Fail({ error }))
          )
        )
      )
    )
  );

  getUserCookieSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.GetUserCookieSettings.Request),
      mergeMap((action) =>
        this.userService.getUserCookieSettings().pipe(
          map((resp) =>
            UserActions.GetUserCookieSettings.Success({ settings: resp })
          ),
          catchError((error) =>
            of(UserActions.GetUserCookieSettings.Fail({ error }))
          )
        )
      )
    )
  );

  setUserCookieSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.SetUserCookieSettings.Request),
      mergeMap((action) =>
        this.userService.setUserCookieSettings(action.settings).pipe(
          map((resp) =>
            UserActions.SetUserCookieSettings.Success({
              settings: action.settings,
            })
          ),
          catchError((error) =>
            of(UserActions.SetUserCookieSettings.Fail({ error }))
          )
        )
      )
    )
  );

  disable2FARequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.Disable2FA.Request),
      mergeMap((action) =>
        this.userService.disable2FA().pipe(
          map((resp) => UserActions.Disable2FA.Success()),
          catchError((error) => of(UserActions.Disable2FA.Fail({ error })))
        )
      )
    )
  );

  createPulseSurvey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.CreatePulseSurvey.Request),
      mergeMap((action) =>
        this.userService.createPulseSurvey(action.survey).pipe(
          map((resp) => UserActions.CreatePulseSurvey.Success()),
          catchError((error) =>
            of(UserActions.CreatePulseSurvey.Fail({ error }))
          )
        )
      )
    )
  );

  setPlatformFirstLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.SetPlatformFirstLogin.Request),
      mergeMap(() =>
        this.userService.setPlatformFirstLogin().pipe(
          map((resp) =>
            UserActions.SetPlatformFirstLogin.Success({ user: resp })
          ),
          catchError((error) =>
            of(UserActions.SetPlatformFirstLogin.Fail({ error }))
          )
        )
      )
    )
  );

  inviteDirectReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.InviteDirectReport.Request),
      mergeMap((action) =>
        this.userService.inviteDirectReport(action.payload).pipe(
          mergeMap((resp) => {
            if (resp.success && resp.delegatedAddedUser)
              return [
                UserActions.InviteDirectReport.Success({
                  resp: resp.delegatedAddedUser,
                }),
                LayoutActions.DisplaySnackbarAlert.SetAlert({
                  alert: { alertType: 'success', message: 'Invite successful' },
                }),
              ];

            return [
              LayoutActions.DisplaySnackbarAlert.SetAlert({
                alert: { alertType: 'danger', message: resp.message },
              }),
            ];
          }),
          catchError((error) =>
            of(UserActions.InviteDirectReport.Fail({ error }))
          )
        )
      )
    )
  );

  acceptChampionCharter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.AcceptChampionCharter.Request),
      mergeMap((action) =>
        this.userService.acceptChampionCharter().pipe(
          map((resp) => UserActions.AcceptChampionCharter.Success()),
          catchError((error) =>
            of(UserActions.AcceptChampionCharter.Fail({ error }))
          )
        )
      )
    )
  );

  saveDigitalDebriefProgress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.SaveDebriefProgress.Request),
      mergeMap((action) =>
        this.userService.saveDebriefProgress(action.debriefStage).pipe(
          map((resp) => UserActions.SaveDebriefProgress.Success({ debriefStage: action.debriefStage })),
          catchError((error) =>
            of(UserActions.SaveDebriefProgress.Fail({ error }))
          )
        )
      )
    )
  );

  getDigitalDebriefProgress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.GetDebriefProgress.Request),
      mergeMap((action) =>
        this.userService.getDebriefProgress().pipe(
          map((resp) => UserActions.GetDebriefProgress.Success({ debriefStage: resp })),
          catchError((error) =>
            of(UserActions.GetDebriefProgress.Fail({ error }))
          )
        )
      )
    )
  );
}
