import { createAction, props } from '@ngrx/store';
import { CoachingClientsDTO } from '../../shared/models/coaching-clients-dto.interface';
import { CoachingFeedbackDTO } from '../../shared/models/coaching-feedback-dto.interface';
import { GroupedCoachingFeedbackDTO } from '../../shared/models/practitioner-coaching-feedback-dto.interface';
import { UserPractitionerDTO } from '../../shared/models/user-practitioner.interface';
import { SendEngagementPushNotificationModel } from 'src/app/shared/models/engagement-push-notification.interface';
import { QuestionnaireDTO } from '../../shared/models/questionnaire-option.interface';
import { AddQuestionnaireInvitesArgs } from '../../shared/models/add-questionnaire-invite.interface';
import { QuestionnaireResponseManagementDTO } from '../../shared/models/questionnaire-response-management-dto.interface';
import { DateRange } from '../../shared/models/date-range.interface';
import { MindflickAccount, MindflickOrganisation, SpotlightResponse, StandardResponse, UserBasicDetails } from 'src/app/shared/models';
import { AddUserModel } from 'src/app/super-admin/invite-user/invite-user.component';
import { AddedUsersResponseModel } from 'src/app/shared/models/account-admin-managed-users-DTO';
import { GetMindflickOrganisation } from '../account-admin/account-admin.actions';

export namespace GetMindflickPractitioners {
  export const Request = createAction(
    '[Super Admin] Get Mindflick Practitioners Request',
    props<{ clientName?: string; }>()
  );

  export const Success = createAction(
    '[Super Admin] Get Mindflick Practitioners Success',
    props<{ practitioners: UserPractitionerDTO[]; }>()
  );

  export const Fail = createAction(
    '[Super Admin] Get Mindflick Practitioners Failure',
    props<{ error: any; }>()
  );
}

export namespace GetCoachingClientInfo {
  export const Request = createAction(
    '[Super Admin] Get Coaching Client Names Request',
    props<{ coachAK?: number; dateRange: DateRange }>()
  );

  export const Success = createAction(
    '[Super Admin] Get Coaching Client Names Success',
    props<{ clientInfo: CoachingClientsDTO; }>()
  );

  export const Fail = createAction(
    '[Super Admin] Get Coaching Client Names Failure',
    props<{ error: any; }>()
  );
}

export namespace GetCoachingFeedback {
  export const Request = createAction(
    '[Super Admin] Get Coaching Feedback Request',
    props<{ coachAk?: number; clientName?: string; dateRange: DateRange; }>()
  );

  export const Success = createAction(
    '[Super Admin] Get Coaching Feedback Success',
    props<{ coachingFeedback: CoachingFeedbackDTO; }>()
  );

  export const Fail = createAction(
    '[Super Admin] Get Coaching Feedback Failure',
    props<{ error: any; }>()
  );
}

// An individual is a client or an practitioner
export namespace GetGroupedCoachingFeedback {
  export const Request = createAction(
    '[Super Admin] Get Coaching Feedback Per Indiviudal Request',
    props<{ isPractitioner?: boolean; dateRange: DateRange }>()
  );

  export const Success = createAction(
    '[Super Admin] Get Coaching Feedback Per Indiviudal Success',
    props<{ feedback: GroupedCoachingFeedbackDTO[]; }>()
  );

  export const Fail = createAction(
    '[Super Admin] Get Coaching Feedback  Per Indiviudal Failure',
    props<{ error: any; }>()
  );
}

export namespace SendEngagementPushNotification {
  export const Request = createAction(
    '[Super Admin] Send Engagement Push Notification Request',
    props<{ payload: SendEngagementPushNotificationModel; }>()
  );

  export const Success = createAction(
    '[Super Admin] Send Engagement Push Notification Success'
  );

  export const Fail = createAction(
    '[Super Admin] Send Engagement Push Notification Fail',
    props<{ error: any; }>()
  );
}

export namespace GetQuestionnaires {
  export const Request = createAction(
    '[Super Admin] Get Spotlight Response Questionnaires Request'
  );

  export const Success = createAction(
    '[Super Admin] Get Spotlight Response Questionnaires Success',
    props<{ questionnaires: QuestionnaireDTO[]; }>()
  );

  export const Fail = createAction(
    '[Super Admin] Get Spotlight Response Questionnaires Fail',
    props<{ error: any; }>()
  );
}

export namespace AddQuestionnaires {
  export const Request = createAction(
    '[Super Admin] Add Spotlight Response Questionnaires Request',
    props<{ questionnaireInvites: AddQuestionnaireInvitesArgs[]; }>()
  );

  export const Success = createAction(
    '[Super Admin] Add Spotlight Response Questionnaires Success'
  );

  export const Fail = createAction(
    '[Super Admin] Add Spotlight Response Questionnaires Fail',
    props<{ error: any; }>()
  );
}

export namespace GetQuestionnaireResponseManagementDetails {
  export const Request = createAction(
    '[Super Admin] Get Questionnaire Response Management Details Request',
    props<{ spotlightResponseId: number; }>()
  );

  export const Success = createAction(
    '[Super Admin] Get Questionnaire Response Management Details Success',
    props<{ questionnaireResponseManagementDTO: QuestionnaireResponseManagementDTO[]; }>()
  );

  export const Fail = createAction(
    '[Super Admin] Get Questionnaire Response Management Details Fail',
    props<{ error: any; }>()
  );
}

export namespace GetUserSpotlightResponses {
  export const Request = createAction(
    '[Super Admin] Get Users Spotlight Responses Request',
    props<{ userAK: number; }>()
  );

  export const Success = createAction(
    '[Super Admin] Get Users Spotlight Responses Success',
    props<{ spotlightResponses: SpotlightResponse[]; }>()
  );

  export const Fail = createAction(
    '[Super Admin] Get Users Spotlight Responses Fail',
    props<{ error: any; }>()
  );
}

export namespace LinkUserWithSpotlight {
  export const Request = createAction(
    '[Super Admin] Link User With Spotlight Request',
    props<{ userAK: number; spotlightResponseId: number; }>()
  );

  export const Success = createAction(
    '[Super Admin] Link User With Spotlight Success',
    // props<{ }>()
  );

  export const Fail = createAction(
    '[Super Admin] Link User With Spotlight Fail',
    props<{ error: any; }>()
  );
}

export namespace InviteUser {
  export const Request = createAction(
    '[Super Admin] Invite User Request',
    props<{ users: AddUserModel; }>()
  );

  export const Success = createAction(
    '[Super Admin] Invite User Success',
     props<{ resp: AddedUsersResponseModel; }>()
  );

  export const Fail = createAction(
    '[Super Admin] Invite User Fail',
    props<{ error: any; }>()
  );
}

export const SetAddUserSuccess = createAction(
  '[Super Admin] Set Invite User Success',
  props<{ success?: boolean; message?: string; }>()
);

export namespace RemoveUserFromAccount {
  export const Request = createAction(
    '[Super Admin] Remove User From Account Request',
    props<{ accountId: number, userAk: number }>()
  );

  export const Success = createAction(
    '[Super Admin] Remove User From Account Success',
    props<{ accountId: number, userAk: number }>()
  );

  export const Fail = createAction(
    '[Super Admin] Remove User From Account Fail',
    props<{ error: any }>()
  );
}

export namespace AddMindflickCoachToAccount {
  export const Request = createAction(
    '[Super Admin] Add Mindflick Coach To Account Request',
    props<{ accountId: number, userAk: number }>()
  );

  export const Success = createAction(
    '[Super Admin] Add Mindflick Coach To Account Success',
    props<{ resp: StandardResponse<UserBasicDetails> }>()
  );

  export const Fail = createAction(
    '[Super Admin] Add Mindflick Coach To Account Fail',
    props<{ error: any }>()
  );
}

export const ResetAddingMindflickCoachStatus = createAction(
  '[Super Admin] Reset Adding Mindflick Coach Status'
);

export namespace GetMindflickOrganisations {
  export const Request = createAction(
    '[Super Admin] Get Mindflick Organisations Request'
  );

  export const Success = createAction(
    '[Super Admin] Get Mindflick Organisations Success',
    props<{ resp: MindflickOrganisation[] }>()
  );

  export const Fail = createAction(
    '[Super Admin] Get Mindflick Organisation Fail',
    props<{ error: any }>()
  )
}

export const SetSelectedMindflickOrganisation = createAction(
  '[Super Admin] Set Selected Mindflick Organisation',
  props<{ organisationId: number }>()
);

export const ClearSelectedMindflickOrganisation = createAction(
  '[Super Admin] Clear Selected Mindflick Organisation',
);

export const UpdateMindflickOrganisationAccount = createAction(
  '[Super Admin] Update Mindflick Organisation Account',
  props<{ organisationId: number, mindflickAccount: MindflickAccount }>()
);

export namespace SetMindflickOrganisationAdmin {
  export const Request = createAction(
    '[Super Admin] Set Mindflick Organisation Admin Request',
    props<{ organisationId: number, userAk: number }>()
  );

  export const Success = createAction(
    '[Super Admin] Set Mindflick Organisation Admin Success',
    props<{ organisationId: number, userAk: number, adminName: string }>()
  );

  export const Fail = createAction(
    '[Super Admin] Set Mindflick Organisation Admin Fail',
    props<{ error: any }>()
  );
}

export namespace SetMindflickOrganisationLead {
  export const Request = createAction(
    '[Super Admin] Set Mindflick Organisation Lead Request',
    props<{ organisationId: number, userAk: number }>()
  );

  export const Success = createAction(
    '[Super Admin] Set Mindflick Organisation Lead Success',
    props<{ organisationId: number, userAk: number, leadName: string }>()
  );

  export const Fail = createAction(
    '[Super Admin] Set Mindflick Organisation Lead Fail',
    props<{ error: any }>()
  );
}

export namespace GetMindflickOrganisationUsers {
  export const Request = createAction(
    '[Super Admin] Get Mindflick Organisation Users Request',
    props<{ organisationId: number }>()
  );
  
  export const Success = createAction(
    '[Super Admin] Get Mindflick Organisation Users Success',
    props<{ resp: UserBasicDetails[] }>()
  );

  export const Fail = createAction(
    '[Super Admin] Get Mindflick Organisation Users Fail',
    props<{ error: any }>()
  );
}