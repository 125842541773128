export interface ListItem<T> {
  id: number;
  name: string;
  value: T;
}

export interface SelectValue {
  value: any;
  description: string;
}
