import { createAction, props } from '@ngrx/store';
import {
  CreditPot,
  MindflickAccount,
  MindflickAccountFilter,
  SaveCreditPotModel,
  User,
} from 'src/app/shared/models';

export namespace GetAllMindflickAccounts {
  export const Request = createAction(
    '[Accounts] Get All Mindflick Accounts Request'
  );

  export const Success = createAction(
    '[Accounts] Get All Mindflick Accounts Success',
    props<{ accounts: MindflickAccount[] }>()
  );

  export const Fail = createAction(
    '[Accounts] Get All Mindflick Accounts Fail',
    props<{ error: any }>()
  );
}

export namespace GetMindflickAccountsForCurrentUser {
  export const Request = createAction(
    '[Accounts] Get Mindflick Accounts For Current User Request'
  );

  export const Success = createAction(
    '[Accounts] Get Mindflick Accounts For Current User Success',
    props<{ accounts: MindflickAccount[] }>()
  );

  export const Fail = createAction(
    '[Accounts] Get Mindflick Accounts For Current User Fail',
    props<{ error: any }>()
  );
}

export namespace GetUserMindflickAccount {
  export const Request = createAction(
    '[Accounts] Get Mindflick Account For Current User Request'
  );

  export const Success = createAction(
    '[Accounts] Get Mindflick Account For Current User Success',
    props<{ account: MindflickAccount }>()
  );

  export const Fail = createAction(
    '[Accounts] Get Mindflick Account For Current User Fail',
    props<{ error: any }>()
  );
}

export const ClearSaveMindflickOutcomes = createAction(
  '[Accounts] Clear Save Mindflick Outcomes'
);

export const SetSelectedAccount = createAction(
  '[Accounts] Set Selected Account',
  props<{ accountId: number }>()
);

export const ClearSelectedAccount = createAction(
  '[Accounts] Clear Selected Account'
);

export const ResetInviteState = createAction('[User Admin] Reset Invite State');

export namespace DeactivateUser {
  export const Request = createAction(
    '[User Admin] Deactivate User Request',
    props<{ userAK: number, accountId: number }>()
  );

  export const Success = createAction(
    '[User Admin] Deactivate User Success',
    props<{ userAK: number }>()
  );

  export const Fail = createAction(
    '[User Admin] Deactivate User Fail',
    props<{ error: any }>()
  );
}

export const SetAccountManagementPage = createAction(
  '[Admin] Set Account Management Page',
  props<{ page: number }>()
);

export const SetAccountManagementItemsPerPage = createAction(
  '[Admin] Set Account Management Items Per Page',
  props<{ itemsPerPage: number }>()
);

export namespace AddMindflickAccount {
  export const Request = createAction(
    '[Admin] Add Mindflick Account Request',
    props<{ account: MindflickAccount }>()
  );

  export const Success = createAction(
    '[Admin] Add Mindflick Account Success',
    props<{ account: MindflickAccount }>()
  );

  export const Fail = createAction(
    '[Admin] Add Mindflick Account Fail',
    props<{ error: any }>()
  );
}

export namespace UpdateMindflickAccount {
  export const Request = createAction(
    '[Admin] Update Mindflick Account Request',
    props<{ account: MindflickAccount }>()
  );

  export const Success = createAction(
    '[Admin] Update Mindflick Account Success',
    props<{ account: MindflickAccount }>()
  );

  export const Fail = createAction(
    '[Admin] Update Mindflick Account Fail',
    props<{ error: any }>()
  );
}

export const SetSelectedCreditPot = createAction(
  '[Admin] Set Selected Credit Pot',
  props<{ creditPotId: number }>()
);

export const ClearSelectedCreditPot = createAction(
  '[Admin] Clear Selected Credit Pot'
);

export namespace AddCreditPot {
  export const Request = createAction(
    '[Admin] Add Credit Pot Request',
    props<{ creditPot: SaveCreditPotModel }>()
  );

  export const Success = createAction(
    '[Admin] Add Credit Pot Success',
    props<{ creditPot: CreditPot }>()
  );

  export const Fail = createAction(
    '[Admin] Add Credit Pot Fail',
    props<{ error: any }>()
  );
}

export namespace UpdateCreditPot {
  export const Request = createAction(
    '[Admin] Update Credit Pot Request',
    props<{ creditPot: SaveCreditPotModel }>()
  );

  export const Success = createAction(
    '[Admin] Update Credit Pot Success',
    props<{ creditPot: CreditPot }>()
  );

  export const Fail = createAction(
    '[Admin] Update Credit Pot Fail',
    props<{ error: any }>()
  );
}

export const SetMindflickAccountSort = createAction(
  '[Admin] Set Mindflick Account Sort',
  props<{
    sort:
      | 'name-asc'
      | 'name-desc'
      | 'renewal-asc'
      | 'renewal-desc';
  }>()
);

export namespace GetUserInvitesEnabled {
  export const Request = createAction(
    '[Account] Get User Invites Enabled Request'
  );

  export const Success = createAction(
    '[Account] Get User Invites Enabled Success',
    props<{ isUserInvitesEnabled: boolean }>()
  );

  export const Fail = createAction(
    '[Account] Get User Invites Enabled Fail',
    props<{ error: any }>()
  );
}

export const SetMindflickAccountFilter = createAction(
  '[Account] Set Mindflick Account Filter',
  props<{ filter: MindflickAccountFilter }>()
);

export const ClearMindflickAccountFilter = createAction(
  '[Account] Clear Mindflick Account Filter'
);

export namespace GetOrgTwoFactorRequired {
  export const Request = createAction(
    '[Account] Get Org Two Factor Required Request'
  );

  export const Success = createAction(
    '[Account] Get Org Two Factor Required Success',
    props<{ orgTwoFactorRequired: boolean }>()
  );

  export const Fail = createAction(
    '[Account] Get Org Two Factor Required Fail',
    props<{ error: any }>()
  );
}
