import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import {
  EngagementResource,
  EngagementResourceCategory,
  EngagementResourceSubCategory,
} from 'src/app/shared/models/engagement-resource.interface';
import { PlatformInviteStatusEnum } from 'src/app/shared/models/enums/platform-invite-status.enum';
import {
  AccountAdminManagedUsersDTO,
  AccountAdminManagedUsersFilter,
  AccountSettings,
  AccountSummaryData,
  SeatsInAcc,
} from '../../shared/models/account-admin-managed-users-DTO';
import { HelpCenterCategory } from '../../shared/models/help-center.interface';
import { DeactivateUser } from '../account/account.actions';
import {
  SaveEngagementResource,
  UpsertEngagementResouceSubCategory,
  UpsertEngagementResourceCategory,
} from '../admin/admin.actions';
import * as AccountAdminActions from './account-admin.actions';
import {
  ReactivateUsers,
  RecreateUsers,
} from './account-admin.actions';
import { LiveAnalyticsGraph } from '../../shared/models/live-analytics/live-analytics-graph.interface';
import { CommonActions } from '../common-actions';
import { DownloadTeamMap } from '../team/team.actions';
import { MindflickAccountBasicDetails, User, UserBasicDetails } from 'src/app/shared/models';
import { MindflickOrganisation } from 'src/app/shared/models';
import AccountsForOrgAdmin from 'src/app/shared/models/org-admin-accounts';

export interface EngagementResourceState
  extends EntityState<EngagementResource> {}

export const engagementResourceAdapter =
  createEntityAdapter<EngagementResource>({
    sortComparer: sortByOrderBy,
  });

export interface EngagementResourceCategoryState
  extends EntityState<EngagementResourceCategory> {}

export const engagementResourceCategoryAdapter =
  createEntityAdapter<EngagementResourceCategory>({
    sortComparer: sortByOrderBy,
  });

export interface EngagementResourceSubCategoryState
  extends EntityState<EngagementResourceSubCategory> {}

export const engagementResourceSubCategoryAdapter =
  createEntityAdapter<EngagementResourceSubCategory>({
    sortComparer: sortByOrderBy,
  });

export function sortByOrderBy(
  a:
    | EngagementResource
    | EngagementResourceCategory
    | EngagementResourceSubCategory,
  b:
    | EngagementResource
    | EngagementResourceCategory
    | EngagementResourceSubCategory
) {
  return a.orderById - b.orderById;
}

export interface AccountAdminState {
  accountAdminManagedUsers: AccountAdminManagedUsersDTO[];
  accountAdminManagedUsersLoading: boolean;
  managedUsersFilter: AccountAdminManagedUsersFilter;
  hasManagedUsersRequestFailed: boolean;
  liveAnalyticsData: LiveAnalyticsGraph[];
  accountSettings?: AccountSettings;
  addingUsers: boolean;
  addUsersSuccess?: boolean;
  addUsersResultMessage?: string;
  seatsInOrg?: SeatsInAcc;
  engagementResourceCategories: EngagementResourceCategoryState;
  engagementResourceSubCategories: EngagementResourceSubCategoryState;
  engagementResources: EngagementResourceState;
  pinnedEngagementResources: number[];
  helpCenterCategories: HelpCenterCategory[];
  currentSearchTerm: string;
  filteredEngagementResourceIds: number[];
  engagementResourceSaveResult?: string;
  userManagementOrderBy:
  | 'name-asc'
  | 'name-desc'
  | 'date-asc'
  | 'date-desc'
  | 'dept-asc'
  | 'dept-desc'
  | 'web-status-asc'
  | 'web-status-desc'
  | 'email-desc'
  | 'email-asc'
  | 'mobile-status-asc'
  | 'mobile-status-desc'
  | 'allocated-seats-asc'
  | 'allocated-seats-desc';
  selectedUserAk?: number;
  isDownloadingTeamMap: boolean;
  mindflickOrganisation?: MindflickOrganisation;
  mindflickOrganisationLoading: boolean;
  mindflickOrganisationError?: string;
  coordinatorSearchFilter?: string;
  accountsSummaryData : AccountSummaryData[];
  currentAccountSummaryId? : number;
  accountSettingsChampions: UserBasicDetails[];
  accountSettingsCoordinators: UserBasicDetails[];
  accountSettingsAccounts: MindflickAccountBasicDetails[];
  selectedAccountSettingsAccountId?: number;
  validAccountAdminsForOrg: UserBasicDetails[];
  validChampionsForOrg: UserBasicDetails[];
  validCoordinatorsForOrg: UserBasicDetails[];
  userManagementMindflickAccounts: MindflickAccountBasicDetails[];
  userManagementSelectedAccountId?: number;
  liveAnalyticsMindflickAccounts: MindflickAccountBasicDetails[];
  liveAnalyticsSelectedAccountId?: number;
  orgAdminAccounts: AccountsForOrgAdmin[];
}

export const initialAccountAdminState: AccountAdminState = {
  accountAdminManagedUsers: [],
  accountAdminManagedUsersLoading: false,
  hasManagedUsersRequestFailed: false,
  liveAnalyticsData: [],
  addingUsers: false,
  engagementResourceCategories:
    engagementResourceCategoryAdapter.getInitialState(),
  engagementResourceSubCategories:
    engagementResourceSubCategoryAdapter.getInitialState(),
  engagementResources: engagementResourceAdapter.getInitialState(),
  pinnedEngagementResources: [],
  helpCenterCategories: [],
  filteredEngagementResourceIds: [],
  currentSearchTerm: '',
  userManagementOrderBy: 'name-desc',
  managedUsersFilter: {
    nameFilter: '',
    hasUnusedSeats: false,
  },
  isDownloadingTeamMap: false,
  mindflickOrganisationLoading: false,
  accountsSummaryData : [],
  accountSettingsChampions: [],
  accountSettingsCoordinators: [],
  accountSettingsAccounts: [],
  validAccountAdminsForOrg: [],
  validChampionsForOrg: [],
  validCoordinatorsForOrg: [],
  userManagementMindflickAccounts: [],
  liveAnalyticsMindflickAccounts: [],
  orgAdminAccounts: []
};

export const accountAdminReducer = createReducer(
  initialAccountAdminState,

  on(CommonActions.ClearState, (state, props) => ({
    ...initialAccountAdminState,
  })),

  on(AccountAdminActions.GetManagedUsersForAccountAdmin.Request, (state) => ({
    ...state,
    accountAdminManagedUsers: [],
    accountAdminManagedUsersLoading: true,
  })),
  on(
    AccountAdminActions.GetManagedUsersForAccountAdmin.Success,
    (state, props) => ({
      ...state,
      accountAdminManagedUsers: props.users,
      accountAdminManagedUsersLoading: false,
      hasManagedUsersRequestFailed: false,
    })
  ),
  on(AccountAdminActions.GetManagedUsersForAccountAdmin.Fail, (state) => ({
    ...state,
    accountAdminManagedUsersLoading: false,
    hasManagedUsersRequestFailed: true,
  })),
  on(AccountAdminActions.AddUsers.Request, (state, props) => ({
    ...state,
    addingUsers: true,
  })),
  on(AccountAdminActions.AddUsers.Success, (state, props) => ({
    ...state,
    addingUsers: false,
    accountAdminManagedUsers: [
      ...state.accountAdminManagedUsers,
      ...props.resp.addedUsers,
    ],
    usersToBeAdded: [],
    addUsersSuccess: true,
    seatsInOrg: state.seatsInOrg
      ? {
          ...state.seatsInOrg,
          currentActiveUsersInAcc:
            state.seatsInOrg.currentActiveUsersInAcc +
            props.resp.addedUsers.length,
          seatsUsed: state.seatsInOrg.seatsUsed + props.resp.addedUsers.length,
        }
      : undefined,
  })),
  on(AccountAdminActions.AddUsers.Fail, (state, props) => ({
    ...state,
    addingUsers: false,
  })),
  on(AccountAdminActions.SetAddUsersSuccess, (state, props) => ({
    ...state,
    addUsersSuccess: props.success,
    addUsersResultMessage: props.message,
  })),
  on(
    AccountAdminActions.GetMindflickAccountSettings.Success,
    (state, props) => ({
      ...state,
      accountSettings: props.resp ?? initialAccountAdminState.accountSettings,
    })
  ),
  on(
    AccountAdminActions.SetMindflickAccountSettings.Success,
    (state, props) => ({
      ...state,
      accountSettings: props.settings,
    })
  ),
  on(
    AccountAdminActions.SetMindflickOrganisationSettings.Success,
    (state, props) => ({
      ...state,
      mindflickOrganisation: state.mindflickOrganisation
        ? {
            ...state.mindflickOrganisation,
            dataRetentionDuration: props.settings.dataRetentionDurationDays,
            sessionDuration: props.settings.sessionDurationMins,
            mfaRequired: props.settings.mfaRequired,
            ssoRequired: props.settings.requiresExternalAuthentication,
          }
        : undefined,
    })
  ),
  on(DeactivateUser.Request, (state, props) => ({
    ...state,
    deactivatingUser: true,
  })),
  on(DeactivateUser.Success, (state, props) => ({
    ...state,
    deactivatingUser: false,
    accountAdminManagedUsers: state.accountAdminManagedUsers.filter(
      (x) => props.userAK != x.ak
    ),
    seatsInOrg: state.seatsInOrg
      ? {
          ...state.seatsInOrg,
          currentActiveUsersInAcc: state.seatsInOrg.currentActiveUsersInAcc - 1,
          seatsUsed: state.seatsInOrg.seatsUsed - 1,
        }
      : undefined,
  })),
  on(ReactivateUsers.Success, (state, props) => {
    var userAKs = props.users.map((x) => x.ak);

    return {
      ...state,
      accountAdminManagedUsers: [
        ...state.accountAdminManagedUsers.filter(
          (x) => !userAKs.includes(x.ak)
        ),
        ...props.users,
      ],
    };
  }),

  on(RecreateUsers.Success, (state, props) => {
    var userAKs = props.users.map((x) => x.ak);

    return {
      ...state,
      accountAdminManagedUsers: [
        ...state.accountAdminManagedUsers.filter(
          (x) => !userAKs.includes(x.ak)
        ),
        ...props.users,
      ],
    };
  }),
  on(
    AccountAdminActions.AccountAdminGetRemainingSeatsForAcc.Request,
    (state) => ({
      ...state,
      requestingRemainingSeatsInOrgData: true,
    })
  ),
  on(
    AccountAdminActions.AccountAdminGetRemainingSeatsForAcc.Success,
    (state, props) => ({
      ...state,
      seatsInOrg: props.resp,
      requestingRemainingSeatsInOrgData: false,
    })
  ),
  on(AccountAdminActions.AccountAdminGetRemainingSeatsForAcc.Fail, (state) => ({
    ...state,
    requestingRemainingSeatsInOrgData: false,
  })),
  on(AccountAdminActions.UpdateManagedUsersFilter, (state, props) => ({
    ...state,
    managedUsersFilter: props.filter,
  })),
  on(AccountAdminActions.ClearManagedUsersFilter, (state) => ({
    ...state,
    managedUsersFilter: initialAccountAdminState.managedUsersFilter,
  })),
  on(AccountAdminActions.SetManagedUsersNameFilter, (state, props) => ({
    ...state,
    managedUsersFilter: {
      ...state.managedUsersFilter,
      nameFilter: props.searchTerm,
    },
  })),
  on(AccountAdminActions.ClearManagedUsersNameFilter, (state) => ({
    ...state,
    managedUsersNameFilter: undefined,
  })),
  on(AccountAdminActions.GetEngagementResources.Success, (state, props) => {
    let engagementResources: EngagementResource[] = [];
    let subCategories: EngagementResourceSubCategory[] = [];

    const categories = props.categories.map((c) => {
      const mappedSubCats = c.subCategories.map((s) => {
        engagementResources = [
          ...engagementResources,
          ...s.engagementResources,
        ];

        return {
          ...s,
          engagementResourceIds: s.engagementResources.map((x) => x.id),
          engagementResources: [],
        };
      });

      subCategories = [...subCategories, ...mappedSubCats];

      return {
        ...c,
        subCategoryIds: c.subCategories.map((x) => x.id),
        subCategories: [],
      };
    });

    return {
      ...state,
      engagementResourceCategories: engagementResourceCategoryAdapter.addMany(
        categories,
        state.engagementResourceCategories
      ),
      engagementResourceSubCategories:
        engagementResourceSubCategoryAdapter.addMany(
          subCategories,
          state.engagementResourceSubCategories
        ),
      engagementResources: engagementResourceAdapter.addMany(
        engagementResources,
        state.engagementResources
      ),
      filteredEngagementResourceIds: engagementResources.map((x) => x.id),
    };
  }),
  on(AccountAdminActions.SetSelectedPopoutTitles, (state, props) => ({
    ...state,
    selectedCategoryTitle: props.categoryTitle,
    selectedSubCategoryTitle: props.subCategoryTitle,
  })),
  on(UpsertEngagementResourceCategory.Success, (state, props) => ({
    ...state,
    engagementResourceCategories: engagementResourceCategoryAdapter.upsertOne(
      props.category,
      state.engagementResourceCategories
    ),
  })),
  on(UpsertEngagementResouceSubCategory.Success, (state, props) => ({
    ...state,
    engagementResourceSubCategories:
      engagementResourceSubCategoryAdapter.upsertOne(
        props.subCategory,
        state.engagementResourceSubCategories
      ),
  })),
  on(SaveEngagementResource.Success, (state, props) => ({
    ...state,
    engagementResources: engagementResourceAdapter.upsertOne(
      props.engagementResource,
      state.engagementResources
    ),
  })),
  on(
    AccountAdminActions.GetPinnedEngagementResources.Success,
    (state, props) => ({
      ...state,
      pinnedEngagementResources: props.pinnedEngagementResourceIds,
    })
  ),
  on(
    AccountAdminActions.AddPinnedEngagementResource.Success,
    (state, props) => ({
      ...state,
      pinnedEngagementResources: [
        ...state.pinnedEngagementResources,
        props.engagementResourceId,
      ],
    })
  ),
  on(
    AccountAdminActions.DeletePinnedEngagementResource.Success,
    (state, props) => ({
      ...state,
      pinnedEngagementResources: state.pinnedEngagementResources.filter(
        (x) => x !== props.engagementResourceId
      ),
    })
  ),
  on(AccountAdminActions.GetHelpCenterCategories.Success, (state, props) => ({
    ...state,
    helpCenterCategories: props.categories,
  })),
  on(AccountAdminActions.SetAccountAdminSearchTerm, (state, props) => ({
    ...state,
    currentSearchTerm: props.searchTerm,
  })),
  on(
    AccountAdminActions.FilterEngagementResourcesBySearchTerm.Request,
    (state, props) => ({
      ...state,
      filteringEngagementResources: true,
    })
  ),
  on(
    AccountAdminActions.FilterEngagementResourcesBySearchTerm.Success,
    (state, props) => ({
      ...state,
      filteredEngagementResourceIds: props.filteredIds,
      filteringEngagementResources: false,
    })
  ),
  on(AccountAdminActions.ResetEngagamentResourceSaveResult, (state) => ({
    ...state,
    engagementResourceSaving: false,
    engagementResourceSaveResult: undefined,
  })),
  on(AccountAdminActions.UpdateUserManagementOrderBy, (state, props) => ({
    ...state,
    userManagementOrderBy: props.orderBy,
  })),
  on(AccountAdminActions.UnlockUser.Success, (state, props) => ({
    ...state,
    accountAdminManagedUsers: state.accountAdminManagedUsers.map((user) => {
      if (user.ak === props.userAk) {
        return {
          ...user,
          isLocked: false,
          portalStatus:
            user.portalStatus == PlatformInviteStatusEnum.Locked
              ? PlatformInviteStatusEnum.Completed
              : user.portalStatus,
        };
      }

      return {
        ...user,
      };
    }),
  })),
  on(AccountAdminActions.UnlockUsers.Success, (state, props) => ({
    ...state,
    accountAdminManagedUsers: state.accountAdminManagedUsers.map((user) => {
      if (props.userAks.includes(user.ak)) {
        return {
          ...user,
          isLocked: false,
          portalStatus:
            user.portalStatus == PlatformInviteStatusEnum.Locked
              ? PlatformInviteStatusEnum.Completed
              : user.portalStatus,
        };
      }

      return {
        ...user,
      };
    }),
  })),
  on(AccountAdminActions.AdminSetSelectedUser, (state, props) => ({
    ...state,
    selectedUserAk: props.userAk,
  })),
  on(AccountAdminActions.AdminClearSelectedUser, (state, props) => ({
    ...state,
    selectedUserAk: undefined,
  })),
  on(
    AccountAdminActions.UpdateUsersDelegatedInvites.Success,
    (state, props) => ({
      ...state,
      accountAdminManagedUsers: state.accountAdminManagedUsers.map((user) => {
        if (user.ak === props.payload.userAk)
          return {
            ...user,
            delegatedInvites: props.payload.delegatedInvites,
            delegatedInvitesExpiry: props.payload.delegatedInvitesExpiry,
          };

        return {
          ...user,
        };
      }),
    })
  ),
  on(AccountAdminActions.UpdateUserUnlockDate.Success, (state, props) => ({
    ...state,
    accountAdminManagedUsers: state.accountAdminManagedUsers.map((user) => {
      if (user.ak == props.userAk)
        return { ...user, unlockDate: props.unlockDate };

      return { ...user };
    }),
  })),
  on(
    AccountAdminActions.GetOnboardingLiveAnalytics.Success,
    AccountAdminActions.GetConnectionsLiveAnalytics.Success,
    AccountAdminActions.GetTeamsLiveAnalytics.Success,
    AccountAdminActions.GetInteractionLiveAnalytics.Success,
    AccountAdminActions.GetNudgeAndWorkOnLiveAnalytics.Success,
    AccountAdminActions.GetUsageLiveAnalytics.Success,
    (state, props) => ({
      ...state,
      liveAnalyticsData: props.data,
    })
  ),
  on(
    AccountAdminActions.DownloadAdminTeamMap.Request,
    DownloadTeamMap.MapProcessingUpdate,
    (state) => ({
      ...state,
      isDownloadingTeamMap: true,
    })
  ),
  on(
    AccountAdminActions.DownloadAdminTeamMap.Fail,
    DownloadTeamMap.Success,
    (state) => ({
      ...state,
      isDownloadingTeamMap: false,
    })
  ),
  on(AccountAdminActions.GetMindflickOrganisation.Request, (state) => ({
    ...state,
    mindflickOrganisationLoading: true,
  })),
  on(AccountAdminActions.GetMindflickOrganisation.Success, (state, props) => ({
    ...state,
    mindflickOrganisationLoading: false,
    mindflickOrganisation: props.mindflickOrganisation,
  })),
  on(AccountAdminActions.GetMindflickOrganisation.Fail, (state, props) => ({
    ...state,
    mindflickOrganisationLoading: false,
    mindflickOrganisationError: props.error,
  })),
  on(AccountAdminActions.SetCoordinatorSearchFilter, (state, props) => ({
    ...state,
    coordinatorSearchFilter: props.searchFilter,
  })),
  on(AccountAdminActions.ClearCoordinatorSearchFilter, (state) => ({
    ...state,
    coordinatorSearchFilter: undefined,
  })),
  on(AccountAdminActions.UpdateUserDetails.Success, (state, props) => ({
    ...state,
    accountAdminManagedUsers: state.accountAdminManagedUsers.map((user) => {
      if (user.ak === props.userDetails.userAK)
        return {
          ...user,
          email: props.userDetails.email,
          jobTitle: props.userDetails.role,
          department: props.userDetails.department,
          unlockDate: props.userDetails.unlockDate,
        };

      return {
        ...user,
      };
    }),
  })),
  on(AccountAdminActions.AddNoteToAccountUser.Success, (state, props) => ({
    ...state,
    accountAdminManagedUsers: state.accountAdminManagedUsers.map((user) => {
      if (user.ak === props.note.userAK) {
        return {
          ...user,
          notes: props.note.note,
        };
      }

      return {
        ...user,
      };
    }),
  })),
  on(
    AccountAdminActions.GetAccountsSummaryWithinOrg.Success,
    (state, props) => ({
      ...state,
      accountsSummaryData: props.accountsData,
    })
  ),
  on(AccountAdminActions.GetUserAccountsWithUserManagePermission.Success, (state, props) => ({
    ...state,
    userManagementMindflickAccounts: props.accounts,
    userManagementSelectedAccountId: state.userManagementSelectedAccountId ? state.userManagementSelectedAccountId : props.accounts.length > 0 ? props.accounts[0].accountId : undefined
  })),
  on(AccountAdminActions.SetUserManagementSelectedAccountId, (state, props) => ({
    ...state,
    userManagementSelectedAccountId: props.accountId
  })),
  on(AccountAdminActions.ClearUserManagementSelectedAccountId, (state) => ({
    ...state,
    userManagementSelectedAccountId: undefined
  })),
  on(AccountAdminActions.GetUserAccountsWithLiveAnalyticsPermission.Success, (state, props) => ({
    ...state,
    liveAnalyticsMindflickAccounts: props.accounts,
    liveAnalyticsSelectedAccountId: state.liveAnalyticsSelectedAccountId ? state.liveAnalyticsSelectedAccountId : props.accounts.length > 0 ? props.accounts[0].accountId : undefined
  })),
  on(AccountAdminActions.SetLiveAnalyticsSelectedAccountId, (state, props) => ({
    ...state,
    liveAnalyticsSelectedAccountId: props.accountId
  })),
  on(AccountAdminActions.ClearLiveAnalyticsSelectedAccountId, (state) => ({
    ...state,
    liveAnalyticsSelectedAccountId: undefined
  })),
  on(
    AccountAdminActions.SetSelectedAdminSummaryAccountId, (state, props) => ({
      ...state,
      currentAccountSummaryId: props.accountId
    })
  ),
  on(AccountAdminActions.SetNewAccountAdmin.Success, (state, props) => ({ 
    ...state,
    accountsSummaryData: state.accountsSummaryData.map(x => {
      if (x.mindflickAccountId != state.currentAccountSummaryId)
        return { ...x };

      const newAdmin = {
        ...state.validCoordinatorsForOrg.find(c => c.ak === props.userId)
      }

      return {
        ...x,
        accountAdminId: props.userId,
        accountAdminName: newAdmin.firstName + ' ' + newAdmin.lastName,
        accountAdminEmail: newAdmin.email
      };
    }),
  })),
  on(AccountAdminActions.GetAccountChampions.Success, (state, props) => ({
    ...state,
    accountSettingsChampions: props.resp
  })),
  on(AccountAdminActions.GetAccountCoordinators.Success, (state, props) => ({
    ...state,
    accountSettingsCoordinators: props.resp
  })),
  on(AccountAdminActions.GetAccountSettingsAccounts.Success, (state, props) => ({
    ...state,
    accountSettingsAccounts: props.resp,
    selectedAccountSettingsAccountId: state.selectedAccountSettingsAccountId ? state.selectedAccountSettingsAccountId : props.resp.length > 0 ? props.resp[0].accountId : undefined
  })),
  on(AccountAdminActions.GetAccountSettingsAccountsForOrgAdmin.Success, (state, props) => ({
    ...state,
    accountSettingsAccounts: props.resp,
    selectedAccountSettingsAccountId: state.selectedAccountSettingsAccountId ? state.selectedAccountSettingsAccountId : props.resp.length > 0 ? props.resp[0].accountId : undefined
  })),
  on(AccountAdminActions.SetSelectedAccountSettingsAccountId, (state, props) => ({ 
    ...state,
    selectedAccountSettingsAccountId: props.accountId
  })),
  on(AccountAdminActions.AddChampionToAccount.Success, (state, props) => ({
    ...state,
    accountSettingsChampions: [ ...state.accountSettingsChampions, state.validChampionsForOrg.find(x => x.ak == props.userAk)! ] 
  })),
  on(AccountAdminActions.AddCoordinatorToAccount.Success, (state, props) => ({
    ...state,
    accountSettingsCoordinators: [ ...state.accountSettingsCoordinators, state.validCoordinatorsForOrg.find(x => x.ak == props.userAk)! ] 
  })),
  on(AccountAdminActions.RemoveChampionFromAccount.Success, (state, props) => ({
    ...state,
    accountSettingsChampions: state.accountSettingsChampions.filter(x => x.ak !== props.userAk)
  })),
  on(AccountAdminActions.RemoveCoordinatorFromAccount.Success, (state, props) => ({
    ...state,
    accountSettingsCoordinators: state.accountSettingsCoordinators.filter(x => x.ak !== props.userAk)
  })),
  on(AccountAdminActions.GetValidAccountAdminsForOrg.Success, (state, props) => ({
    ...state,
    validAccountAdminsForOrg: props.resp
  })),
  on(AccountAdminActions.GetValidChampionsForOrg.Success, (state, props) => ({
    ...state,
    validChampionsForOrg: props.resp
  })),
  on(AccountAdminActions.GetValidCoordinatorsForOrg.Success, (state, props) => ({
    ...state,
    validCoordinatorsForOrg: props.resp
  }))
);
