import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { filter, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { AppState } from 'src/app/+state/app.state';
import {
    StrengthSpottedNotification
} from 'src/app/+state/profile/profile.actions';
import { selectSelectedUser } from 'src/app/+state/user/user.selector';
import { Profile, ProfileItem, ProfileSection } from 'src/app/shared/models';
import {
    selectStrengthNotification
} from '../../../+state/profile/profile.selector';
import {
    faNextSVG,
    faThumbEmptySVG,
    faThumbFilledSVG,
} from '../../../../icons';

import { ActivatedRoute } from '@angular/router';
import { selectNotificationSendingUserAk } from '../../../+state/signalr/signalr.selector';
import { AddStrengthsCommentComponent } from '../../../individuals/profile-strengths/add-strengths-comment/add-strengths-comment.component';
import { StrengthsModalComponent } from '../../../individuals/profile-strengths/strengths-modal/strengths-modal.component';

@Component({
  selector: 'app-strengths-list',
  templateUrl: './strengths-list.component.html',
  styleUrls: ['./strengths-list.component.scss'],
})
export class StrengthsListComponent implements OnInit, OnDestroy {
  destroyed$ = new Subject<boolean>();
  @Input() isOwnProfile: boolean = true;
  @Input() playingStrengths: ProfileItem[] = [];
  @Input() profile?: Profile;
  @Input() iconPath: string = './assets/svg/profile/strengths.svg';
  @Input() isStrength: boolean = false;
  @Input() listHeight = 0;
  @Input() showNumbers = false;

  selectedUser$ = this.store.select(selectSelectedUser);
  notificationNavigation$ = this.store.select(selectStrengthNotification);
  notificationSendingUserAk$ = this.store.select(selectNotificationSendingUserAk);
  profileAk: number | undefined = 0;
  recognizingUsers: number[] | undefined = [];
  strengthDescription: string = '';
  strengthMessageMapping: { [k: string]: string; } = {
    '=1': '# time',
    other: '# times',
  };

  next = faNextSVG;
  thumbEmpty = faThumbEmptySVG;
  thumbFilled = faThumbFilledSVG;

  constructor(
    private store: Store<AppState>,
    private modalService: BsModalService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.selectedUser$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((x) => (this.profileAk = x?.id));

    this.notificationNavigation$
      .pipe(
        withLatestFrom(this.notificationSendingUserAk$.pipe(filter(ak => !!ak), take(1))),
        filter(([{ isNotification }, userAk]) => isNotification && !!userAk),
      )
      .subscribe(([{ isNotification, strengthId }, userAK]) => {
        if (isNotification && this.isStrength) {
          this.openStrengthModal(strengthId!, userAK);
          this.store.dispatch(StrengthSpottedNotification.Hide());
        }
      });

    if (this.isStrength) {
      this.route.queryParamMap.pipe(take(1)).subscribe((params) => {
        const strengthId = params.get('strengthId');
        const spottedBy = Number(params.get('spottedBy'));

        if (strengthId) {
          this.openStrengthModal(strengthId, spottedBy);
        }
      });
    }
  }

  openStrengthModal(strengthId: string, spottedBy?: number) {
    var strength = this.profile?.sections
      .find((section: ProfileSection) => section.id == 'strengths')
      ?.items.find(
        (item: ProfileItem) => item.id == strengthId
      );
    this.recognizingUsers = strength?.comments.map(x => x.commentorAK);

    this.strengthDescription = strength?.description!;

    this.modalService.show(StrengthsModalComponent, {
      initialState: {
        recognizingUsers: this.recognizingUsers,
        strengthDescription: this.strengthDescription,
        highlightedUser: spottedBy,
        comments: strength?.comments
      },
      class: 'modal-dialog-centered custom-modal',
    });
  }

  selectStrength(strength: ProfileItem, isSpotted: boolean) {
    this.modalService.show(AddStrengthsCommentComponent,
      {
        initialState: {
          profile: this.profile,
          strengthDescription: strength.description,
          strengthId: strength.id
        },
        class: 'modal-dialog-centered',
      }
    );
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }
}
