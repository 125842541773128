import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import {
    FaIconLibrary,
    FontAwesomeModule,
} from '@fortawesome/angular-fontawesome';
import {
    faArrowRightFromBracket,
    faArrowRightToBracket,
    faArrowsUpDownLeftRight,
    faBell,
    faBook,
    faCircle,
    faClipboard,
    faClock,
    faDownLong,
    faDownload,
    faEllipsisV,
    faEnvelope,
    faExchange,
    faFileArrowUp,
    faFlask,
    faGears,
    faHeader,
    faHistory,
    faLock,
    faMessage,
    faMinus,
    faMobileScreen,
    faPaperPlane,
    faPersonDigging,
    faPuzzlePiece,
    faQuestion,
    faQuestionCircle,
    faRing,
    faSatelliteDish,
    faScrewdriverWrench,
    faSquare,
    faStar,
    faThumbTack,
    faToolbox,
    faUpLong,
    faUsers
} from '@fortawesome/free-solid-svg-icons';
import { AuthInterceptor, PublicEventsService } from 'angular-auth-oidc-client';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { routes } from './app.routing';
import { AuthConfigModule } from './auth/auth-config.module';
import { AppFooterComponent } from './common/app-footer/app-footer.component';
import { SearchInputComponent } from './common/search-input/search-input.component';
import { TopMenuComponent } from './common/top-menu/top-menu.component';
import { HomeComponent } from './home/home.component';
import { SidebarRoutesStructure } from './models/sidebar-routes.model';
import { ADMIN_ROUTES, DOCS_TOKENS } from './tokens/docs-routes-token';
import { SIDEBAR_ROUTES } from './tokens/docs-sidebar-routes-token';

import {
    faCalendarCheck,
    faCircleQuestion,
    faFile,
    faFileLines,
    faUser,
} from '@fortawesome/free-regular-svg-icons';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { DragulaModule } from 'ng2-dragula';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AccountEffects } from './+state/account/account.effects';
import { reducers } from './+state/app.state';
import { ProfileEffects } from './+state/profile/profile.effects';
import { SurveyEffects } from './+state/survey/effects/survey.effects';
import { TeamEffects } from './+state/team/team.effects';
import { TooltipEffects } from './+state/tooltip/tooltip.effects';
import { UserEffects } from './+state/user/user.effects';
import { adminRoutes } from './admin/admin.routing';
import { SharedModule } from './shared/shared.module';

import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgxSpinnerModule } from 'ngx-spinner';
import { GrowthEffects } from './+state/growth/growth.effects';
import { SignalrEffects } from './+state/signalr/signalr.effects';
import { GrowthDropdownComponent } from './growth/growth-dropdown/growth-dropdown.component';
import { CanDeactivateGuard } from './shared/services/can-deactivate-guard.service';
import { SuperAdminDropdownComponent } from './super-admin/super-admin-dropdown/super-admin-dropdown.component';

import { ImageCropperComponent } from 'ngx-image-cropper';
import { DataProtectionComponent } from './account/legal-documents/data-protection/data-protection.component';
import { PrivacyPolicyComponent } from './account/legal-documents/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './account/legal-documents/terms-and-conditions/terms-and-conditions.component';
import { AccountAdminDropdownComponent } from './admin/account-admin-dropdown/account-admin-dropdown.component';

import { config } from '@fortawesome/fontawesome-svg-core';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
    BsDatepickerConfig,
    BsDatepickerModule,
} from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CookieService } from 'ngx-cookie-service';
import { catchError } from 'rxjs';
import { AccountAdminEffects } from './+state/account-admin/account-admin.effects';
import { AdminEffects } from './+state/admin/admin.effects';
import { AuditEffects } from './+state/audit/audit.effects';
import { LayoutEffects } from './+state/layout/effects/layout.effects';
import { SuperAdminEffects } from './+state/super-admin/super-admin.effects';
import { CookieBannerComponent } from './cookie-banner/cookie-banner.component';
import { AngularDateHttpInterceptor } from './shared/helpers/http-date-interceptor';
import { MindflickAccountInterceptor } from './shared/helpers/mindflick-account-interceptor';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    TopMenuComponent,
    SearchInputComponent,
    AppFooterComponent,
    GrowthDropdownComponent,
    SuperAdminDropdownComponent,
    AccountAdminDropdownComponent,
    TermsAndConditionsComponent,
    PrivacyPolicyComponent,
    DataProtectionComponent,
    CookieBannerComponent,
  ],
  imports: [
    FontAwesomeModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    RouterModule.forRoot(routes, {
      useHash: environment.useHash,
      anchorScrolling: 'enabled',
    }),
    AuthConfigModule,
    NgxSpinnerModule,
    SharedModule,
    TabsModule.forRoot(),
    BsDatepickerModule.forRoot(),
    DragulaModule.forRoot(),
    // NGRX
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot([
      AccountEffects,
      UserEffects,
      ProfileEffects,
      TeamEffects,
      SurveyEffects,
      SignalrEffects,
      GrowthEffects,
      TooltipEffects,
      LayoutEffects,
      AuditEffects,
      AdminEffects,
      AccountAdminEffects,
      SuperAdminEffects,
    ]),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: 'en'
    }),
    ImageCropperComponent,
    !environment.production
      ? StoreDevtoolsModule.instrument({ maxAge: 50 , connectInZone: true})
      : [],
    ModalModule.forRoot(),

  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MindflickAccountInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AngularDateHttpInterceptor,
      multi: true,
    },
    { provide: DOCS_TOKENS, useValue: routes },
    { provide: ADMIN_ROUTES, useValue: adminRoutes },
    { provide: SIDEBAR_ROUTES, useValue: SidebarRoutesStructure },
    CanDeactivateGuard,
    {
      provide: ErrorHandler,
      useClass: ApplicationinsightsAngularpluginErrorService,
    },
    CookieService,
    BsDatepickerConfig, 
    provideCharts(withDefaultRegisterables())
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    //Solid Icons
    library.addIcons(
      faCircle,
      faSquare,
      faPuzzlePiece,
      faToolbox,
      faQuestionCircle,
      faUsers,
      faPaperPlane,
      faArrowRightFromBracket,
      faArrowRightToBracket,
      faLock,
      faMinus,
      faFileLines,
      faExchange,
      faHistory,
      faQuestion,
      faPersonDigging,
      faDownload,
      faBook,
      faClock,
      faEnvelope,
      faEllipsisV,
      faArrowsUpDownLeftRight,
      faScrewdriverWrench,
      faRing,
      faThumbTack,
      faStar,
      faUpLong,
      faDownLong,
      faClipboard,
      faFlask,
      faMobileScreen
    );
    // Regular Icons
    library.addIcons(
      faUser,
      faLock,
      faCalendarCheck,
      faGears,
      faHeader,
      faPaperPlane,
      faSatelliteDish,
      faCircle,
      faMessage,
      faFileArrowUp,
      faCircleQuestion
    );

    // Regular Icons
    library.addIcons(faUser, faLock, faFile, faBell);
    config.autoAddCss = false;
  }
}
