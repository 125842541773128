<div class="bg-dark d-flex flex-column">
  <div class="d-flex page-container text-white py-3">
    <h2 class="flex-fill">Individual work ons you might like...</h2>
    <app-btn-tertiary
      [icon]="next"
      [light]="true"
      (click)="viewGrowth()"
      class="me-3"
      >View growth</app-btn-tertiary
    >
    <app-btn-action
      [icon]="back"
      [iconDimensions]="30"
      [secondary]="true"
      [light]="true"
      [disabled]="disableWorkOnsLeft"
      (actionClick)="scrollWorkOnsLeft()"
    ></app-btn-action>
    <app-btn-action
      class="ms-1"
      [icon]="next"
      [iconDimensions]="30"
      [secondary]="true"
      [light]="true"
      [disabled]="disableWorkOnsRight"
      (actionClick)="scrollWorkOnsRight()"
    ></app-btn-action>
  </div>
  <div
    #workOnCards
    *ngIf="suggestedWorkOns$ | async as workOns"
    class="growth-carousel d-flex pb-3 mb-3"
    (scroll)="checkWorkOnsScrollPosition()"
  >
    <div class="carousel-left-padding"></div>
    <ng-container *ngFor="let workOn of workOns">
      <app-work-on-card-small
        *ngIf="workOn"
        [id]="workOn.id"
        [title]="workOn.title"
        [summary]="workOn.summary"
        [imageUrl]="workOn.portalUpcomingImageUrl"
        [growthType]="GrowthType.WorkOn"
        [workOnType]="workOn.type"
      ></app-work-on-card-small>
    </ng-container>
  </div>
</div>
