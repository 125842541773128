import { createSelector } from '@ngrx/store';
import {
  MindflickAccount,
  MindflickAccountFilter,
  MindflickAccountRenewalDateStatus,
  User,
} from 'src/app/shared/models';
import { accountStateKey, AppState } from '../app.state';
import { selectCurrentUser } from '../user/user.selector';
import { AccountState } from './account.reducer';
import {
  AlphabeticalSort,
  DateSortDesc,
} from 'src/app/shared/helpers/sorting-helpers';

export const selectAccountState = (state: AppState) => state[accountStateKey];

export const selectAllAccounts = (state: AppState) =>
  state[accountStateKey].allAccounts;
export const selectAllAccountsLoading = (state: AppState) =>
  state[accountStateKey].allAccountsLoading;

export const selectAllAccountsTake = (state: AppState) =>
  state[accountStateKey].allAccountsTake;
export const selectAllAccountsPage = (state: AppState) =>
  state[accountStateKey].allAccountsPage;
export const selectAllAccountsSort = (state: AppState) =>
  state[accountStateKey].allAccountsSort;

export const selectMindflickAccountFilters = (state: AppState) =>
  state[accountStateKey].mindflickAccountFilter;

export const selectFilteredAccounts = createSelector(
  selectAllAccounts,
  selectAllAccountsSort,
  selectMindflickAccountFilters,
  (
    accounts: MindflickAccount[],
    sort:
      | 'name-asc'
      | 'name-desc'
      | 'renewal-asc'
      | 'renewal-desc',
    filter: MindflickAccountFilter
  ) => {
    let accountsToDisplay: MindflickAccount[] = [];

    switch (sort) {
      case 'name-asc':
        accountsToDisplay = accounts
          .map((x) => ({ ...x }))
          .sort((a, b) => AlphabeticalSort(a.name, b.name));
        break;
      case 'name-desc':
        accountsToDisplay = accounts
          .map((x) => ({ ...x }))
          .sort((a, b) => -AlphabeticalSort(a.name, b.name));
        break;
      case 'renewal-asc':
        accountsToDisplay = accounts
          .map((x) => ({ ...x }))
          .sort((a, b) => DateSortDesc(a.renewalDate, b.renewalDate));
        break;
      case 'renewal-desc':
        accountsToDisplay = accounts
          .map((x) => ({ ...x }))
          .sort((a, b) => -DateSortDesc(a.renewalDate, b.renewalDate));
        break;
    }

    if (filter.status !== null) {
      accountsToDisplay = accountsToDisplay.filter(
        (x) => x.status == filter.status
      );
    }

    if (filter.renewalDateStatus !== null) {
      const now = new Date();

      switch (filter.renewalDateStatus) {
        case MindflickAccountRenewalDateStatus.InThreeMonths:
          accountsToDisplay = accountsToDisplay.filter((x) => {
            const renewalDate = new Date(x.renewalDate);
            renewalDate.setMonth(renewalDate.getMonth() - 3);

            return now > renewalDate;
          });
          break;
        case MindflickAccountRenewalDateStatus.InOneMonth:
          accountsToDisplay = accountsToDisplay.filter((x) => {
            const renewalDate = new Date(x.renewalDate);
            renewalDate.setMonth(renewalDate.getMonth() - 1);

            return now > renewalDate;
          });
          break;
        case MindflickAccountRenewalDateStatus.Expired:
          accountsToDisplay = accountsToDisplay.filter(
            (x) => now > x.renewalDate
          );
          break;
      }
    }

    if (filter.isMindflickLed !== null) {
      accountsToDisplay = accountsToDisplay.filter(
        (x) => x.isMindflickLed == filter.isMindflickLed
      );
    }

    if (filter.isDemo !== null) {
      accountsToDisplay = accountsToDisplay.filter(
        (x) => x.isDemo == filter.isDemo
      );
    }

    return accountsToDisplay;
  }
);

export const selectFilteredAccountsCount = createSelector(
  selectFilteredAccounts,
  (accounts: MindflickAccount[]) => {
    return accounts.length;
  }
);

export const selectAccountsToDisplay = createSelector(
  selectFilteredAccounts,
  selectAllAccountsTake,
  selectAllAccountsPage,
  (accounts: MindflickAccount[], take: number, page: number) => {
    const skip = page * take;
    const end = skip + take;

    return accounts.slice(skip, end);
  }
);

export const selectSelectedAccountId = (state: AppState) =>
  state[accountStateKey].selectedAccountId;

export const selectSelectedAccount = createSelector(
  selectAllAccounts,
  selectSelectedAccountId,
  (accounts: MindflickAccount[], selectedAccountId?: number) => {
    if (!selectedAccountId) return undefined;

    return accounts.find((x) => x.id === selectedAccountId);
  }
);

export const selectSelectedCreditPotId = (state: AppState) =>
  state[accountStateKey].selectedCreditPotId;

export const selectSelectedCreditPot = createSelector(
  selectAllAccounts,
  selectSelectedCreditPotId,
  (accounts: MindflickAccount[], selectedCreditPotId?: number) => {
    if (!selectedCreditPotId) return undefined;

    return accounts
      .find((x) => x.creditPots.some((x) => x.id === selectedCreditPotId))
      ?.creditPots.find((x) => x.id === selectedCreditPotId);
  }
);

export const selectUserAccounts = (state: AppState) =>
  state[accountStateKey].userAccounts;
export const selectUserAccount = (state: AppState) =>
  state[accountStateKey].currentUserAccount;
export const selectUserAccountsList = (state: AppState) =>
  state[accountStateKey].userAccountsList;
export const selectUserAccountsLoading = (state: AppState) =>
  state[accountStateKey].userAccountsLoading;

export const selectMindflickAccountIsSaving = (state: AppState) =>
  state[accountStateKey].mindflickAccountIsSaving;
export const selectMindflickAccountSaveSuccessful = (state: AppState) =>
  state[accountStateKey].mindflickAccountSaveSuccess;
export const selectMindflickAccountSaveError = (state: AppState) =>
  state[accountStateKey].mindflickAccountSaveError;

export const selectSendingInvites = (state: AppState) =>
  state[accountStateKey].sendingInvites;
export const selectSendingInvitesSuccess = (state: AppState) =>
  state[accountStateKey].sendingInvitesSuccess;
export const selectSendingInvitesError = (state: AppState) =>
  state[accountStateKey].sendingInvitesError;
export const selectChampionCanViewAnalytics = (state: AppState) =>
  state[accountStateKey].currentUserAccount?.championCanViewAnalyticsStatus;

const selectCurrentUserAccount = (state: AppState) =>
  state[accountStateKey].currentUserAccount;

export const selectCurrentAccountName = createSelector(
  selectCurrentUserAccount,
  (account: MindflickAccount | undefined) => {
    return account?.name;
  }
);

export const selectCurrentAccountId = createSelector(
  selectCurrentUserAccount,
  (account?: MindflickAccount) => {
    return account?.id;
  }
);

export const selectCurrentAccountIsMindflickLed = createSelector(
  selectCurrentUserAccount,
  (account?: MindflickAccount) => {
    return account?.isMindflickLed;
  }
);

export const selectIsUserInvitesEnabled = (state: AppState) =>
  state[accountStateKey].isUserInvitesEnabled;

export const selectOrgTwoFactorRequired = (state: AppState) =>
  state[accountStateKey].orgTwoFactorRequired;