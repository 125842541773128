import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ColumnMode, SortType, SelectionType } from '@swimlane/ngx-datatable';
import { AppState } from 'src/app/+state/app.state';
import { ClearSelectedMindflickOrganisation, GetMindflickOrganisationUsers } from 'src/app/+state/super-admin/super-admin.actions';
import { selectSelectedMindflickOrganisation } from 'src/app/+state/super-admin/super-admin.selector';
import { faCloseSVG, faEditSVG } from 'src/icons';
import { MindflickAccount } from '../../models';
import { Subject, take, takeUntil } from 'rxjs';
import { DialogService } from '../../services/dialog.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { EditMindflickAccountModalComponent } from 'src/app/super-admin/edit-mindflick-account-modal/edit-mindflick-account-modal.component';
import { SetSelectedAccount } from 'src/app/+state/account/account.actions';
import { EditOrganisationAdminModalComponent } from 'src/app/super-admin/edit-organisation-admin-modal/edit-organisation-admin-modal.component';
import { EditOrganisationLeadModalComponent } from 'src/app/super-admin/edit-organisation-lead-modal/edit-organisation-lead-modal.component';

@Component({
  selector: 'app-mindflick-organisation-details-popout',
  templateUrl: './mindflick-organisation-details-popout.component.html',
  styleUrls: ['./mindflick-organisation-details-popout.component.scss']
})
export class MindflickOrganisationDetailsPopoutComponent implements OnInit, AfterViewInit, OnDestroy {
  mindflickOrganisation$ = this.store.select(selectSelectedMindflickOrganisation);

  selectedAccount?: MindflickAccount;

  close = faCloseSVG;
  editIcon = faEditSVG;

  ColumnMode = ColumnMode;
  SortType = SortType;
  SelectionType = SelectionType;
  selected: any[] = [];

  loading = true;

  destroyed$ = new Subject<boolean>();

  constructor(private store: Store<AppState>, private dialogService: DialogService, private modalService: BsModalService) {}

  ngOnInit(): void {
    this.mindflickOrganisation$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(org => {
        if (org) {
          this.store.dispatch(GetMindflickOrganisationUsers.Request({ organisationId: org.id }));

          if (this.selected.length != 0)
            this.selectedAccount = org.mindflickAccounts.find(x => x.id === this.selected[0].id);
        }
        
      });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.loading = false;
    }, 200);
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }

  accountSelected() {
    if (this.selected.length != 0) {
      this.mindflickOrganisation$.pipe(take(1)).subscribe(org => {
        if (org) {
          this.selectedAccount = org.mindflickAccounts.find(x => x.id === this.selected[0].id);
        }
          
      });
    }
  }

  closeAccount(accountId: number) {
    this.dialogService
      .confirm(
        'This will close the account, making the Mindflick platform no longer accessible to it\'s users. Are you sure you wish to continue?', 
        'Warning',
        false
      )
      .pipe(take(1))
      .subscribe(result => {
        if (result) {

        }
      });
  }

  closePopout() {
    this.store.dispatch(ClearSelectedMindflickOrganisation());
  }

  openEditAccountModal(accountId: number) {
    this.store.dispatch(SetSelectedAccount({ accountId }));

    this.modalService.show(EditMindflickAccountModalComponent);
  }

  openEditOrganisationAdminModal() {
    this.mindflickOrganisation$
      .pipe(take(1))
      .subscribe(org => {
        if (org) {
          this.modalService.show(EditOrganisationAdminModalComponent, {
            initialState: {
              selectedUserAk: org.adminUserAk,
              mindflickOrganisationId: org.id
            }
          });
        }
          
      });
  }

  openEditOrganisationLeadModal() {
    this.mindflickOrganisation$
      .pipe(take(1))
      .subscribe(org => {
        if (org) {
          this.modalService.show(EditOrganisationLeadModalComponent, {
            initialState: {
              selectedUserAk: org.organisationLeadAk,
              mindflickOrgId: org.id
            }
          });
        }
      });
  }
}
