import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject, take, takeUntil, withLatestFrom } from 'rxjs';
import { AdminClearSelectedUser, UpdateUserDetails } from 'src/app/+state/account-admin/account-admin.actions';
import { selectPossibleDepartments, selectPossibleRoles, selectSelectedUserAdmin, selectSelectedUserManagementAccountId } from 'src/app/+state/account-admin/account-admin.selector';
import { AppState } from 'src/app/+state/app.state';
import { selectIsSuperAdmin } from 'src/app/+state/user/user.selector';
import { faCloseSVG, faTickSVG } from 'src/icons';
import { EditRoleInfoInterface } from '../../models/edit-role-info-model.interface';

@Component({
  selector: 'app-edit-user-popout',
  templateUrl: './edit-user-popout.component.html',
  styleUrls: ['./edit-user-popout.component.scss']
})
export class EditUserPopoutComponent implements OnInit, OnDestroy {
  userToEdit$ = this.store.select(selectSelectedUserAdmin);
  roleOptions$ = this.store.select(selectPossibleRoles);
  departmentOptions$ = this.store.select(selectPossibleDepartments);
  selectedAccountId$ = this.store.select(selectSelectedUserManagementAccountId);
  
  isSuperAdmin$ = this.store.select(selectIsSuperAdmin);

  editDetailsForm = new FormGroup({
    firstName: new FormControl<string>('', Validators.required),
    lastName: new FormControl<string>('', Validators.required),
    role: new FormControl<string>(''),
    department: new FormControl<string>(''),
    email: new FormControl<string>('', [Validators.required, Validators.email]),
  });

  unlockDate?: Date;
  tomorrow: Date = new Date();

  close = faCloseSVG;
  tick = faTickSVG;

  destroyed$ = new Subject<boolean>();

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.userToEdit$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(user => {
        if (!user)
          return;

        this.editDetailsForm.setValue({
          firstName: user.firstName,
          lastName: user.lastName,
          role: user.jobTitle,
          department: user.department,
          email: user.email,
        });
        this.editDetailsForm.updateValueAndValidity();
    
        if (user.isLocked)
          this.unlockDate = user.unlockDate;
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }

  closePopout() {
    this.store.dispatch(AdminClearSelectedUser());
  }

  saveChanges() {
    if (this.editDetailsForm.invalid) return;

    this.userToEdit$.pipe(take(1), withLatestFrom(this.selectedAccountId$)).subscribe(([user, accountId]) => {
      if (!accountId) return;

      const formValue: EditRoleInfoInterface = {
        userAK: user?.ak!,
        role: this.editDetailsForm.controls.role.value
          ? this.editDetailsForm.controls.role.value
          : '',
        department: this.editDetailsForm.controls.department.value
          ? this.editDetailsForm.controls.department.value
          : '',
        email: this.editDetailsForm.controls.email.value
          ? this.editDetailsForm.controls.email.value
          : '',
      };
  
      if (this.unlockDate) this.unlockDate.setHours(0, 0, 0, 0);
  
      formValue.unlockDate = this.unlockDate;
  
      this.store.dispatch(
        UpdateUserDetails.Request({ userDetails: formValue, accountId })
      );
    });

  }

  setDepartment(event: any) {
    this.editDetailsForm.controls.department.setValue(event);
  }

  setRole(event: any) {
    this.editDetailsForm.controls.role.setValue(event);
  }
}
